import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import { Error } from "./Error";
import { Checkbox } from "./Checkbox";

export function CheckboxGroup({
  name,
  label,
  items,
  onChangeCalback,
  withErrorFeedback = true,
  customClass,
  customStyle = {},
  itemColSize = 2,
  disabled = false,
  singleChoice = false,
  onInit = () => {},
}) {
  const { initialValues, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);
  const { value } = field;

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const handleChange = event => {
    const target = event.currentTarget;
    let valueArray;
    if (singleChoice) {
      valueArray = [];
      
    } else {
      valueArray = [...value] || [];
    }
  
    if (target.checked) {
      valueArray.push(target.name);
    } else {
      valueArray.splice(valueArray.indexOf(target.name), 1);
    }
    setFieldValue(name, valueArray);
    if (onChangeCalback) {
      onChangeCalback(valueArray);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const renderItems = () =>
    items.map(item => {
      const itemsField = {
        name: item.value,
        value: value.includes(item.value),
        onChange: handleChange,
        onBlur: handleBlur,
      };
      return (
        <div className={`col-lg-${itemColSize}`} key={item.value}>
          <Checkbox
            key={item.value}
            label={item.label}
            {...itemsField}
            customStyle={{ marginRight: "15px" }}
            disabled={disabled || (isDisabled && isDisabled(name))}
          />
        </div>
      );
    });

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <div className={customClass} style={customStyle}>
          <div>
            {label && <legend>{label}</legend>}
            <div className="row">{renderItems()}</div>
            {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
          </div>
        </div>
      )}
    </>
  );
}
