import * as columnFormatters from "../../../_metronic/_partials/table/column-formatters/";
import * as fields from "./fields";

export const PATHS = {
    ROOT: 'websales',
    SHOP: 'general',
}

export const ENDPOINTS = {
    ROOT: "/api/websales",
    ORDERS: '/taskOrderList',
    ORDER_ITEMS: '/orderItems',
    COPY_LAST: '/lastOrderItems',
    FILTERS: '/api/activity/ordertypes'
}

export const COLUMNS = [
    {
        dataField: fields.ID,
        text: "ID",
        sort: true,
        style: { width: "12%", maxWidth: '12%', height: '52px' },
    },
    {
        dataField: fields.CREATED_ON,
        text: "Created on",
        sort: true,
        style: { width: "12%", maxWidth: '12%', height: '52px' },
        formatter: columnFormatters.DateColumnsFormatter,
    },
    {
        dataField: fields.DELIVERY_DATE,
        text: "Delivery Date",
        sort: true,
        style: { width: "12%", maxWidth: '12%', height: '52px' },
        formatter: columnFormatters.DateColumnsFormatter,
    },
    {
        dataField: fields.CUSTOMER,
        text: "Customer",
        sort: true,
        style: { width: "20%", maxWidth: "20%", height: '52px' },
    },
    {
        dataField: 'orderType',
        text: "Type",
        sort: true,
        style: { width: "30%", maxWidth: '30%', height: '52px' },
    },
    {
        dataField: fields.TOTAL,
        text: "Total",
        sort: true,
        style: { width: "10%", maxWidth: '10%', textAlign: 'right', paddingRight: 'calc(16px + 1rem)' },
        headerStyle: { textAlign: 'right', height: '52px' },
        formatter: columnFormatters.PriceColumnFormatter,
    },
    {
        dataField: fields.STAUS,
        text: "",
        sort: true,
        formatter: columnFormatters.StatusColumnFormatter,
        style: { width: "100%", display: 'flex', justifyContent: 'flex-end', height: '52px', alignItems: 'center'},
    },
];

export const CURRENCY = 'BGN ';

export const TABS = [fields.GENERAL, fields.ITEMS];