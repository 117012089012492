import { authProvider } from "../auth/authProvider";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const ORG_UNIT_KEY = "userOrganizationUnit";

export const getCurrentUserOrganizationUnit = () => {
  return localStorage.getItem(ORG_UNIT_KEY);
};

export const setCurrentUserOrganizationUnit = orgUnitID => {
  localStorage.setItem(ORG_UNIT_KEY, orgUnitID);
};

export const getCurrentAccessToken = async () => {
  let accounts;
  let silentRequest;
  let token;

  if (process.env.NODE_ENV === "test") {
    // For Tests
    accounts = [
      {
        environment: "atossfa.testing.com",
        homeAccountId: "testHomeAccountId",
        idTokenClaims: {},
        localAccountId: "testLocalAccountId",
        name: "John Doe",
        tenantId: "testTenantId",
        username: "john_doe@test.com",
      },
    ];
    token = {
      accessToken: "testAccessToken",
      account: {
        environment: "atossfa.testing.com",
        homeAccountId: "testHomeAccountId",
        idTokenClaims: {},
        localAccountId: "testLocalAccountId",
        name: "John Doe",
        tenantId: "testTenantId",
        username: "john_doe@test.com",
      },
      authority: "https://atossfa.testing.com",
      correlationId: "testCorrelationId",
      idToken: "testIdToken",
      idTokenClaims: {},
      scopes: ["https://atossfa.testing.com"],
      state: "testingState",
      tenantId: "testTenantId",
      tokenType: "Bearer",
      uniqueId: "testuUniqueId",
    };
  } else {
    // For Dev and Prod

    await authProvider.initialize();
    accounts = authProvider.getAllAccounts();

    silentRequest = {
      scopes: [process.env.REACT_APP_AUTH_SCOPES],
      account: accounts[0],
    };

    token = await authProvider
      .acquireTokenSilent(silentRequest)

      // Handling for silent call failing
      .catch(error => {
        console.log("Silent token acquisition fails!", error?.erroMessage);
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          authProvider.acquireTokenRedirect(silentRequest).then(response => {
            console.log("Token is taken! ", response);
            if (response.accessToken) {
              return response;
            }
          });
        } else {
          console.error("error refreshing token", error);
        }
      });

    if (!token) {
      return await authProvider.acquireTokenRedirect(silentRequest);
    }
  }

  return token;
};
