import { RootState } from "../../../../../redux/store";

export const eventsLoading = (state: RootState) => state.events.itemsLoading;

export const events = (state: RootState) => state.events.items;
export const eventsCount = (state: RootState) => state.events.itemsCount;

export const error = (state: RootState) => state.events.error;
export const errorAction = (state: RootState) => state.events.errorAction;
export const validationErrors = (state: RootState) => state.events.validationErrors;
