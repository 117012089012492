import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../app/common/_customHooks/redux";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";

export function TableLoading({ isItemsLoadingSelector }) {

    const isLoading = useSelector(
      state => (isItemsLoadingSelector(state)),
        shallowEqual
    );
    useEffect(() => { }, [isLoading]);
    return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}
