import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { ISubState } from "../../_interfaces/reduxState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ISubState = {
  ...defaultInitialState,
  subDropDownItems: [],
  subDropDownLoading: false,
  subDropDownError: null,

  visualizationTypesDropDownItems: [],
  visualizationTypesDropDownLoading: false,
  visualizationTypesDropDownError: null,
};

const reducers = {
  ...defaultReducers,
  startSubDropDownItemsCall: (state: any) => {
    state.subDropDownLoading = true;
    state.subDropDownItems = [];

    state.error = null;
    state.errorAction = null;
    state.subDropDownError = null;
  },
  setSubDropDownItems: (state: any, action: PayloadAction<any>) => {
    state.subDropDownItems = action.payload;
    state.subDropDownLoading = false;
    state.subDropDownError = null;
  },
  
  startVisualizationTypesDropDownItemsCall: (state: any) => {
    state.visualizationTypesDropDownLoading = true;
    state.visualizationTypesDropDownItems = [];

    state.error = null;
    state.errorAction = null;
    state.visualizationTypesDropDownError = null;
  },
  setVisualizationTypesDropDownItems: (state: any, action: PayloadAction<any>) => {
    state.visualizationTypesDropDownItems = action.payload;
    state.visualizationTypesDropDownLoading = false;
    state.visualizationTypesDropDownError = null;
  },
};

export const assetModelTypesSlice = createSlice({
  name: "assetModelTypes",
  initialState,
  reducers,
});
