import { RootState } from "../../../../../redux/store";

export const assetsLoading = (state: RootState) => state.assetModels.itemsLoading;
export const assetsActionLoading = (state: RootState) => state.assetModels.actionsLoading;
export const dropDownLoading = (state: RootState) => state.assetModels.dropDownLoading;

export const assets = (state: RootState) => state.assetModels.items;
export const assetsCount = (state: RootState) => state.assetModels.itemsCount;
export const assetForEdit = (state: RootState) => state.assetModels.itemForEdit;
export const getItemsSelect = (state: RootState) => state.assetModels.dropDownItems;

export const error = (state: RootState) => state.assetModels.error;
export const errorAction = (state: RootState) => state.assetModels.errorAction;
export const dropDownError = (state: RootState) => state.assetModels.dropDownError;

export const getBaseArticlesSelect = (state: RootState) => state.assetModels.baseArticles;
export const getBaseArticlesLoading = (state: RootState) => state.assetModels.baseArticlesLoading;

export const validationErrors = (state: RootState) => state.assetModels.validationErrors;

export const filters = (state: RootState) => state.assetModels.filters;
