/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import get from "lodash/get";
import merge from "lodash/merge";
import { Form } from "react-bootstrap";
import clsx from "clsx";
// https://github.com/conorhastings/react-syntax-highlighter#prism
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// See https://github.com/PrismJS/prism-themes
import { coy as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from "../../layout";
import { Card, CardBody, CardHeader, Notice } from "../controls";

const localStorageActiveTabKey = "builderActiveTab";

export function Builder() {
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  const [isLoading, setIsLoading] = useState(false);
  const htmlClassService = useHtmlClassService();
  const initialValues = useMemo(
    () =>
      merge(
        // Fulfill changeable fields.
        getInitLayoutConfig(),
        htmlClassService.config,
      ),
    [htmlClassService.config],
  );

  const saveCurrentTab = _tab => {
    localStorage.setItem(localStorageActiveTabKey, _tab);
  };

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <p>
          The layout builder is to assist your set and configure your preferred project layout specifications and
          preview it in real time. The configured layout options will be saved until you change or reset them. To use
          the layout builder, choose the layout options and click the <code>Preview</code> button to preview the changes
          and click the <code>Export</code> button to download the HTML template with its includable partials of this
          demo. In the downloaded folder the partials(header, footer, aside, topbar, etc) will be placed seperated from
          the base layout to allow you to integrate base layout into your application
        </p>
        <p>
          <span className="label label-inline label-pill label-danger label-rounded mr-2">NOTE:</span>
          The downloaded version does not include the assets folder since the layout builder's main purpose is to help
          to generate the final HTML code without hassle.
        </p>
      </Notice>
      {/*Formic off site: https://jaredpalmer.com/formik/docs/overview*/}
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          setIsLoading(true);
          setLayoutConfig(values);
        }}
        onReset={() => {
          setIsLoading(true);
          setLayoutConfig(getInitLayoutConfig());
        }}
      >
        {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
          <>
            <div className="card card-custom">
              {/*Header*/}
              <div className="card-header card-header-tabs-line">
                <ul className="nav nav-dark nav-bold nav-tabs nav-tabs-line" data-remember-tab="tab_id" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 0 ? "active" : ""}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(0);
                        saveCurrentTab(0);
                      }}
                    >
                      Header
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 1 ? "active" : ""}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(1);
                        saveCurrentTab(1);
                      }}
                    >
                      Subheader
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 2 ? "active" : ""}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(2);
                        saveCurrentTab(2);
                      }}
                    >
                      Content
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 3 ? "active" : ""}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(3);
                        saveCurrentTab(3);
                      }}
                    >
                      Aside
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 4 ? "active" : ""}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(4);
                        saveCurrentTab(4);
                      }}
                    >
                      Footer
                    </a>
                  </li>
                </ul>
              </div>

              <div className="form">
                <div className="card-body">
                  <div className="tab-content pt-3">
                    <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Desktop Fixed Header:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="header.self.fixed.desktop"
                                checked={!!get(values, "header.self.fixed.desktop")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Enable fixed header for mobile mode</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Mobile Fixed Header:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="header.self.fixed.mobile"
                                checked={!!get(values, "header.self.fixed.mobile")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Enable fixed header for mobile mode</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Header Width:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-solid"
                            name="header.self.width"
                            onBlur={handleBlur}
                            value={get(values, "header.self.width")}
                            onChange={handleChange}
                          >
                            <option value="fluid">Fluid</option>
                            <option value="fixed">Fixed</option>
                          </select>
                          <Form.Text>Select header width type</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Display Header Menu:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="header.menu.self.display"
                                checked={!!get(values, "header.menu.self.display")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Display header menu</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Header Menu Layout:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="header.menu.self.layout"
                            onBlur={handleBlur}
                            value={get(values, "header.menu.self.layout")}
                            onChange={handleChange}
                          >
                            <option value="default">Default</option>
                            <option value="tab">Tab</option>
                          </select>
                          <Form.Text>Select header menu layout style</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Header Menu Arrows:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="header.menu.self.root-arrow"
                                checked={!!get(values, "header.menu.self.root-arrow")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Enable header menu root link arrows</Form.Text>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Display Subheader:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="subheader.display"
                                checked={!!get(values, "subheader.display")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Display subheader</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Fixed Subheader:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="subheader.fixed"
                                checked={!!get(values, "subheader.fixed")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>
                            Enable fixed(sticky) subheader. Requires <code>Solid</code> subheader style.
                          </Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Width:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="subheader.width"
                            onBlur={handleBlur}
                            value={get(values, "subheader.width")}
                            onChange={handleChange}
                          >
                            <option value="fluid">Fluid</option>
                            <option value="fixed">Fixed</option>
                          </select>
                          <Form.Text>Select layout width type</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Subheader Style:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="subheader.style"
                            onBlur={handleBlur}
                            value={get(values, "subheader.style")}
                            onChange={handleChange}
                          >
                            <option value="transparent">Transparent</option>
                            <option value="solid">Solid</option>
                          </select>
                          <Form.Text>Select subheader style</Form.Text>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Width:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="content.width"
                            onBlur={handleBlur}
                            value={get(values, "content.width")}
                            onChange={handleChange}
                          >
                            <option value="fluid">Fluid</option>
                            <option value="fixed">Fixed</option>
                          </select>
                          <Form.Text>Select layout width type</Form.Text>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 3 ? "active" : ""}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Display:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.self.display"
                                checked={!!get(values, "aside.self.display")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Display aside</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Fixed:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.self.fixed"
                                checked={!!get(values, "aside.self.fixed")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Set fixed aside layout</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Minimize:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.self.minimize.toggle"
                                checked={!!get(values, "aside.self.minimize.toggle")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Allow aside minimizing</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Hoverable:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.self.minimize.hoverable"
                                checked={!!get(values, "aside.self.minimize.hoverable")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Expand Minimized Aside on hover</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Default Minimize:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.self.minimize.default"
                                checked={!!get(values, "aside.self.minimize.default")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Set aside minimized by default</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label pt-4 text-lg-right">Submenu Toggle:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="aside.menu.dropdown"
                                checked={!!get(values, "aside.menu.dropdown")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>
                            Select submenu toggle mode (works only when <code>Fixed Mode</code> is disabled)
                          </Form.Text>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 4 ? "active" : ""}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Fixed Footer:</label>
                        <div className="col-lg-9 col-xl-4">
                          <span className="switch switch-sm switch-primary">
                            <label>
                              <input
                                type="checkbox"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="footer.fixed"
                                checked={!!get(values, "footer.fixed")}
                              />
                              <span></span>
                            </label>
                          </span>
                          <Form.Text>Set fixed footer</Form.Text>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">Width:</label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-solid"
                            name="footer.self.width"
                            onBlur={handleBlur}
                            value={get(values, "footer.self.width")}
                            onChange={handleChange}
                          >
                            <option value="fluid">Fluid</option>
                            <option value="fixed">Fixed</option>
                          </select>
                          <Form.Text>Select layout width type</Form.Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button type="button" onClick={handleSubmit} className={`btn btn-primary font-weight-bold mr-2`}>
                        <i className="la la-eye" /> Preview
                      </button>
                      <button type="button" onClick={handleReset} className={`btn btn-clean font-weight-bold mr-2`}>
                        <i className="la la-recycle" /> Reset
                      </button>
                      <span
                        className={`ml-3 ${clsx({
                          spinner: isLoading,
                        })}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Config*/}
            <Card className="mt-4">
              <CardHeader
                title={
                  <>
                    Generated Config
                    <small>
                      can be used for layout config in <code>/src/_metronic/layout/LayoutConfig.js</code>
                    </small>
                  </>
                }
              />

              <CardBody>
                <SyntaxHighlighter
                  language="json"
                  style={highlightStyle}
                  customStyle={{
                    background: `none transparent !important`,
                  }}
                >
                  {JSON.stringify(values, null, 2)}
                </SyntaxHighlighter>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
