import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { IProducts, ISection } from "../../_interfaces/builder";
import { IAssignment, IPlanogramState } from "../../_interfaces/state";

const initialState: IPlanogramState = {
    ...defaultInitialState,
    assignmentMessage: [],
    assignment: null,
    loadingPlanogramBuilder: false,
    planogramSaveLoading: false,
    deletedSections: [],
    deletedShelves: [],
    deleteProducts: [],
    warning: null,
    products: [],
    productsLoading: false,
    copyLoading: false,
    deleteLoading: false,
    planogramUpdated: false,
}


const reducers = {
    ...defaultReducers,
    setDeleteLoading: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.deleteLoading = action.payload
    },
    setCopyLoading: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.copyLoading = action.payload
    },
    
    clearWarning: (state:IPlanogramState, action:PayloadAction) => {
        state.warning = initialState.warning;
    },
    setWarning: (state:IPlanogramState, action:PayloadAction<string>) => {
        state.warning = action.payload
    },
    setLoadingPlanogramBuilder: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.loadingPlanogramBuilder = action.payload;
    },
    setPlanogramSaveLoading: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.planogramSaveLoading = action.payload;
    },
    setProducts: (state:IPlanogramState, action:PayloadAction<IProducts[]>) => {
        state.products = action.payload;
    },
    setProductsLoading: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.productsLoading = action.payload;
    },
    setPlanogramTitle: (state:IPlanogramState, action:PayloadAction<string>) => {
        state.planogramUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            title: action.payload,
        }
    },
    setSections: (state:IPlanogramState, action:PayloadAction<ISection[]>) => {
        state.planogramUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            dimensions: {
                width: action.payload.reduce((a, { innerDimensions }) => a + innerDimensions.width, 0),
                depth: Math.max(...action.payload.map(({innerDimensions}) => innerDimensions.depth)),
                height: Math.max(...action.payload.map(({innerDimensions}) => innerDimensions.height))
            },
            sections: action.payload
        }
    },
    setDeletedSections: (state:IPlanogramState, action:PayloadAction<{id:string}>) => {
        if (!state.deletedSections.some(({id}) => id === action.payload.id)) {
            state.deletedSections = [...state.deletedSections, action.payload];
            state.planogramUpdated = true;
        }
    },
    setDeletedShelves: (state:IPlanogramState, action:PayloadAction<{id:string, sectionId:string}>) => {
        if (!state.deletedShelves.some(({id}) => id === action.payload.id)) {
            state.deletedShelves = [...state.deletedShelves, action.payload];
            state.planogramUpdated = true;
        }
    },
    setDeleteProducts: (state:IPlanogramState, action:PayloadAction<{id:string, sectionId:string, shelfId:string}[]>) => {
        state.deleteProducts = [...state.deleteProducts, ...action.payload];
        state.planogramUpdated = true;
    },
    resetAssignment: (state:IPlanogramState) => {
        state.assignment = initialState.assignment;
        state.assignmentMessage = initialState.assignmentMessage;
    },
    resetDeleteSections: (state:IPlanogramState, action:PayloadAction) => {
        state.deletedSections = initialState.deletedSections;
        state.deletedShelves = initialState.deletedShelves;
        state.deleteProducts = initialState.deleteProducts;
    },
    setAssignment: (state:IPlanogramState, action:PayloadAction<IAssignment>) => {
        state.assignment = action.payload;
    },
    setAssignmentMessage: (state:IPlanogramState, action:PayloadAction<string[]>) => {
        state.assignmentMessage = action.payload;
    },
    setIs3d: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.planogramUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            is3D: action.payload,
        }
    },
    setPlanogramUpdated: (state:IPlanogramState, action:PayloadAction<boolean>) => {
        state.planogramUpdated = action.payload
    }
}

export const planogramSlice = createSlice({
    name: "planogram",
    initialState,
    reducers,
});
