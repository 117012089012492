import { getDefaultSlice } from "../../../../../redux/_helpers";

const customReducers = {
  setActiveMeasures: (state, action) => {
    state.entitiesSelect = action.payload;
  },
};

const initialState = {};

export const articleUnitsOfMeasureSlice = getDefaultSlice("articleUnitsOfMeasure", customReducers, initialState);
