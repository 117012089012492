/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import SvgIcon from "../../../../../../app/components/common/SvgIcon";

export function DeleteItemDialog({ modelName, id, show, onHide, onDeleteHandler }) {
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal show={show} onHide={onHide} centered aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{`${modelName} Delete`}</Modal.Title>
        <div
          data-testid="close-modal"
          className="btn btn-icon btn-sm btn-hover-primary ml-4"
          onClick={onHide}
        >
          <SvgIcon
            iconLocation="/media/svg/icons/Navigation/Close-2.svg"
            size="4"
            bootstrapColor="grey"
            title="close"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <span>{`Are you sure to delete this ${modelName}?`}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={() => { onDeleteHandler(); onHide(); }} className="btn btn-delete btn-elevate">
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
