// General Info TAB
export const ARTICLE = 'articleTab'
export const ARTICLE_CODE = 'code'
export const ARTICLE_NAME = 'name'
export const ARTICLE_BARCODE = 'barcode'
export const ARTICLE_EAN = 'ean'
export const ARTICLE_CATEGORY_ID = 'articleCategoryId'
export const ARTICLE_HIERARCHY = 'articleHierarchy'
export const ARTICLE_BRAND_ID = 'articleBrandId'
export const ARTICLE_FLAVOR_ID = 'articleFlavorId'
export const ARTICLE_PACK_SIZE_ID = 'articlePackSizeId'
export const ARTICLE_PACK_TYPE_ID = 'articlePackTypeId'
export const ARTICLE_INACTIVE = 'inactive'
export const INCLUDE_IN_PLANOGRAM = 'isInPlanogram';

export const DEPTH = 'depth'
export const HEIGHT = 'height'
export const WIDTH = 'width'

// Unit Conversion TAB
export const CONVERSATIONS ='articleUnitConversions'
export const CONVERSATIONS_ARTICLE_ID ='articleId'
export const CONVERSATIONS_IS_BASE_UNIT ='isBaseUnit'
export const CONVERSATIONS_MEASURE_ID ='articleUnitOfMeasureId'
export const CONVERSATIONS_NUMERATOR ='numerator'
export const CONVERSATIONS_DOMINATOR ='denominator'
export const CONVERSATIONS_PRICE ='price'
export const CONVERSATIONS_WEIGHT ='weight'

// Images TAB
export const IMAGES ='articleImages'
export const IMAGES_ID ='id'
export const IMAGES_IS_DEFAULT ='isDefault'
export const IMAGES_URL ='imageUrl'
export const IMAGES_THUMBNAIL ='imageThumbnail'
export const PLANOGRAM_IMAGE = 'planogramImage';

// ---------------------------------
export const IS_FOREIGN = 'isForeign';
export const TABS = [ARTICLE, CONVERSATIONS];

