import { RootState } from "../../../../../redux/store";

export const assortmentLoading = (state: RootState) => state.assortment.itemsLoading;
export const assortmentActionLoading = (state: RootState) => state.assortment.actionsLoading;
export const dropDownLoading = (state: RootState) => state.assortment.dropDownLoading;

export const assortment = (state: RootState) => state.assortment.items;
export const assortmentCount = (state: RootState) => state.assortment.itemsCount;
export const assortmentForEdit = (state: RootState) => state.assortment.itemForEdit;
export const getItemsSelect = (state: RootState) => state.assortment.dropDownItems;


/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const itemForEditId = (state: RootState) => state.assortment.itemForEdit.id;

export const tableItemsForEdit = (state: RootState) => state.assortment.itemForEdit?.articlesTab?.assortmentArticles || [];
export const totalTableItemsCount = (state: RootState) => state.assortment.itemForEdit?.articlesTab?.totalCount || 0;

export const tableItemsLoading = (state: RootState) => state.assortment.articlesLoading;
export const tableItemsError = (state: RootState) => state.assortment.assortmentArticlesError;

export const addedTableItems = (state: RootState) => state.assortment.itemForEdit?.createAssortmentArticles || [];
export const removedTableItems = (state: RootState) => state.assortment.itemForEdit?.deleteAssortmentArticles || [];

export const searchTablesItems = (state: RootState) => state.assortment.searchArticles;
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */




export const error = (state: RootState) => state.assortment.error;
export const errorAction = (state: RootState) => state.assortment.errorAction;
export const dropDownError = (state: RootState) => state.assortment.dropDownError;

export const validationErrors = (state: RootState) => state.assortment.validationErrors;
