import { customerSegmentsSlice } from "./customerSegmentsSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import { Dispatch } from "redux";
import { get } from "../../../../../api/requester";

const { actions } = customerSegmentsSlice;

export const QPARAMS_CHANGED = "customerSegments/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "CustomerSegments_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerSegments_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "CustomerSegments_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerSegments_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerSegments_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "CustomerSegments_Post",
  actions,
  defaultActions.convertCreateItemParams(["name"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerSegments_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
