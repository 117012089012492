import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState extends IDefaultState<any, any, any> {
  selectedFile: any;
  fileDownloading:boolean;
  selectedFileLoading:boolean;
}

const initialState: IState = {
  ...defaultInitialState,
  selectedFile: {},
  fileDownloading:false,
  selectedFileLoading: false,
};

const reducers = {
  ...defaultReducers,
  setSelectedFile: (state:IState, action: PayloadAction<any>) => {
    state.selectedFile = action.payload;
  },
  setLoadingSelectedFile: (state:IState, action: PayloadAction<boolean>) => {
    state.selectedFileLoading = action.payload;
  },
  setFileDownloading: (state:IState, action: PayloadAction<boolean>) => {
    state.fileDownloading = action.payload;
  }
};

export const customerAttachmentsSlice = createSlice({
  name: "CustomerAttachments",
  initialState,
  reducers,
});
