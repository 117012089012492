import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.articleCategories.listLoading;
export const isItemActionLoading = (state:RootState) => state.articleCategories.actionsLoading;
export const getItems = (state:RootState) => state.articleCategories.entities;
export const getItemsSelect = (state:RootState) => state.articleCategories.entitiesSelect;
export const getItemsCount = (state:RootState) => state.articleCategories.totalCount;
export const getItemForEdit = (state:RootState) => state.articleCategories.itemForEdit;
export const getError = (state:RootState) => state.articleCategories.error;
export const validationErrors = (state: RootState) => state.articleCategories.validationErrors;
