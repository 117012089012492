// General Info TAB

export const GENERAL_INFO = 'generalInfo';
export const GENERAL_INFO_CUSTOMER_NUMBER = 'customerNumber';
export const GENERAL_INFO_CUSTOMER_NAME = 'name';
export const GENERAL_INFO_CUSTOMER_LONG_NAME = 'longName';
export const GENERAL_INFO_CUSTOMER_TELEPHONE = 'telephone';
export const GENERAL_INFO_CUSTOMER_EMAIL = 'email';
export const GENERAL_INFO_DELIVERY_LEAD_TIME = 'deliveryLeadTime';
export const GENERAL_INFO_LANGUAGE_ID = 'languageId';

// Shipping Address TAB
export const SHIP_TO_ADDRESS ='shipToAddress';
export const SHIP_TO_ADDRESS_CITY_ID ='cityId';
export const SHIP_TO_ADDRESS_POSTAL_CODE ='postalCode';
export const SHIP_TO_ADDRESS_STREET ='street';
export const SHIP_TO_ADDRESS_HOUSE_NUMBER ='houseNumber';
export const SHIP_TO_ADDRESS_LONGITUDE ='longitude';
export const SHIP_TO_ADDRESS_LATITUDE ='latitude';

// Billing Address TAB
export const BILLING_ADDRESS = 'billToAddress';
export const BILLING_ADDRESS_CITY_ID = 'cityId';
export const BILLING_ADDRESS_POSTAL_CODE = 'postalCode';
export const BILLING_ADDRESS_STREET = 'street';
export const BILLING_ADDRESS_HOUSE_NUMBER = 'houseNumber';
export const BILLING_ADDRESS_LONGITUDE = 'longitude';
export const BILLING_ADDRESS_LATITUDE = 'latitude';

// Classification TAB
export const CLASSIFICATION = 'classification';
export const CLASSIFICATION_TYPE_ID = 'typeId';
export const CLASSIFICATION_SEGMENT_ID = 'segmentId';
export const CLASSIFICATION_PAYER_NUMBER = 'payerNumber';
export const CLASSIFICATION_REBATE_NUMBER = 'rebateNumber';
export const CLASSIFICATION_DISTRIBUTION_TYPE_ID = 'distributionTypeId';
export const DIVISION_ID = 'divisionId';
export const CLASSIFICATION_IS_WHOLESALER = 'isWholesaler';
export const EXTERNAL_REFERENCE = 'externalReferences';
export const SOURCE_SYSTEM = 'sourceSystem';
export const SOURCE_SYSTEM_ID = 'sourceSystemId';

// Block TAB
export const BLOCK = 'block';
export const BLOCK_ORDER = 'orderBlock';
export const BLOCK_DATE_UTC = 'blockDateUTC';

// Credit TAB
export const CREDIT_DATA = 'creditData';
export const CREDIT_DATA_BANK_ACCOUNT = 'bankAccount';
export const CREDIT_DATA_VAT_NUMBER = 'vatNumber';
export const CREDIT_DATA_CREDIT_LIMIT = 'creditLimit';
export const CREDIT_DATA_CREDIT_EXPOSURE = 'creditExposure';
export const CREDIT_DATA_OPEN_BALANCE = 'openBalance';

// Hierarchy TAB - no validations required
export const HIERARCHY = 'hierarchies';

// Territories TAB - no validations required
export const TERRITORIES = 'customerTerritories';
export const CUSTOMER_TERRITORIES = 'customerTerritories';
export const TERRITORY = 'territory';
export const TERRITORY_ASSIGNMENT = 'territoryAssignment';

// Equipment TAB
export const EQUIPMENT ='equipmentTab';
export const CUSTOMER_EQUIPMENTS = 'customerEquipments';

// Assortment TAB
export const ASSORTMENT ='assortmentTab';
export const CUSTOMER_ASSORTMENT ='customerAssortments';

// Comments Tab
export const COMMENTS = 'comments';

// Wholesalers TAB - no validations required
export const WHOLESALERS = 'wholesalers';

// Contact person SECTION
export const UPDATE_CONTACT_PERSONS = 'updateContactPersons';
export const CONTACT_PERSON_ID = 'customerPersonId';
export const CONTACT_PERSON = 'contactPersons';
export const CONTACT_PERSON_NAME = 'name';
export const CONTACT_PERSON_ROLE = 'role';
export const CONTACT_PERSON_TELEPHONE = 'telephone';
export const CONTACT_PERSON_TELEPHONE_TWO = 'telephone2';
export const CONTACT_PERSON_EMAIL = 'email';
export const CONTANCT_PERSON_DEFAULT = 'isDefault';
export const CONTACT_PERSON_ACTIVE = 'isActive';

// Attachments
export const CUSTOMER_FILES = 'customerDocuments';
export const CREATE_CUSTOMER_FILES = 'createCustomerDocuments';
export const UPDATE_CUSTOMER_FILES = 'updateCustomerDocuments';
export const DELETE_CUSTOMER_FILES = 'deleteCustomerDocuments';

// REPORTS
export const TASK_DESCRIPTION = 'description';
export const TASK_LAST_CHANGE_DATE = 'lastChangeDate';
export const TASK_ATTACHMENTS = 'attachments';
export const TASK_NOTES = 'notes';
export const TASK_PARTICIPANTS_NAME = 'participantNames';
export const TASK_SURVEYS = 'surveys';
export const TASK_CUSTOMER_ID = 'taskCustomerId';
export const TASK_ID = 'taskId';
export const TASK_LOG_ID = 'taskLogId';

// ----------------------------------
export const COUNTRY_ID = 'countryId'; // Do not exists in Swagger
export const REGION_ID = 'regionId'; // Do not exists in Swagger

export const PARTNER_FUNCTION_ID = 'partnerFunctionId'; // Do not exists in forms
// ---------------------------------

export const TABS = [GENERAL_INFO, SHIP_TO_ADDRESS, BILLING_ADDRESS, COMMENTS, CLASSIFICATION, HIERARCHY, CREDIT_DATA, BLOCK, TERRITORIES, WHOLESALERS];
