import React, { MouseEvent } from 'react';
import SvgIcon from "../../../../app/components/common/SvgIcon"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const LinkFormatter = (cellValue:string, row:any) => {

    const fileDownload = (e:MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        fetch(cellValue, {
            method: 'GET',
        })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.title);
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <OverlayTrigger placement='right' overlay={<Tooltip id={cellValue}>Download</Tooltip>}>
            <a className="btn btn-icon btn-light btn-hover-primary btn-sm" onClick={fileDownload}>
                <SvgIcon
                    iconLocation="/media/svg/icons/Files/Download.svg"
                    size="4"
                    bootstrapColor="primary"
                    customClasses="m-0 p-0"
                />
            </a>
        </OverlayTrigger>
    );
}