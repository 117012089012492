import React, { useEffect } from "react";
import { Error } from "./Error";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import RequiredFieldMarker from "./RequiredFieldMarker";

export function RadioButton({
  name,
  id = name,
  label,
  disabled,
  withErrorFeedback = true,
  onChangeCalback,
  customClass,
  customStyle,
  onInit,
  value,
  isRequired = false,
  ...props
}) {
  const { initialValues, errors, touched, setFieldValue } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const onChange = e => {
    setFieldValue(field.name, e.target.name);

    if (onChangeCalback) {
      onChangeCalback(e.target.name);
    }
  };

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          <div className={`radio-inline ${customClass ? customClass : ''}`} style={customStyle}>
            <label className="radio radio-lg radio-single mr-6">
              <input
                name={field.name}
                id={id}
                type="radio"
                value={field.name}
                checked={id === field.value || id === value}
                {...{ ...field, onChange, ...props }}
                disabled={disabled || (isDisabled && isDisabled(name))}
              />
              <span />
            </label>
            {label && (
              <label htmlFor={field.name} className="mt-1">
                {label}
                <RequiredFieldMarker name={name} isRequired={isRequired} />
              </label>
            )}
          </div>
          {withErrorFeedback && <Error name={field.name} errors={errors} touched={touched} />}
        </>
      )}
    </>
  );
}
