export const isItemsLoading = state => state.promotions.listLoading;
export const isItemActionLoading = state => state.promotions.actionsLoading;
export const getItems = state => state.promotions.entities;
export const getItemsCount = state => state.promotions.totalCount;
export const getItemForEdit = state => state.promotions.itemForEdit;
export const getError = state => state.promotions.error;

export const getPromotionTypes = state => state.promotions.promotionTypes;
export const getPromotionTypesLoading = state => state.promotions.promotionTypesLoading;

export const getArticles = state => state.promotions.articles;
export const getArticlesLoading = state => state.promotions.articlesLoading;

export const getCustomers = state => state.promotions.customers;
export const getCustomersLoading = state => state.promotions.customersLoading;
