/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { actions, selectors } from "../../../../../app/user-access/_redux";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";

const getMenuItemActive = (location, url) => {
  return checkIsActive(location, url) ? " menu-item-active menu-item-open " : "";
};

export function AsideMenuList({ layoutProps }) {
  const items = useSelector(state => {
    return selectors.getMenu(state);
  }, shallowEqual);

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {items.length ? (
          items.map(item => <NavItem key={item.id} item={item} />)
        ) : (
          <div className="d-flex">
            <Spinner className="mx-auto mt-8 mb-1" animation="border" variant="secondary" />
          </div>
        )}
      </ul>
    </>
  );
}

function NavItem({ item }) {
  return item.items ? <NodeWithChildren item={item} /> : <SingleNode item={item} />;
}

function SingleNode({ item }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const isActive = getMenuItemActive(location, item.url);

  useEffect(() => {
    //In case the user goes manually to a page;
    //Do not reset tab items here as it causes a loop
    if (isActive && item.tabbedItems) {
      dispatch(actions.setTabMenuItems(item.tabbedItems));
    }
  }, []);

  const setTabNav = e => {
    if (!isActive && item.tabbedItems) {
      dispatch(actions.setTabMenuItems(item.tabbedItems));
    } else {
      dispatch(actions.setTabMenuItems([]));
    }
  }

  return (
    <li key={item.id} className={`menu-item ${isActive}`}>
      <NavLink className="menu-link" to={item.url} onClick={setTabNav}>
        <ItemIcon item={item} />
        <span className="menu-text">{item.label}</span>
      </NavLink>
    </li>
  );
}

function NodeWithChildren({ item }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const menuLoading = useSelector(state => {
    return selectors.isMenuLoading(state);
  }, shallowEqual);

  function LoadItems(item) {
    if (item.items.length === 0 && !menuLoading) dispatch(actions.fetchSubMenuItemsSwagger(item.id));
  }
  const isActive = getMenuItemActive(location, item.url);

  if (isActive) LoadItems(item);
  return (
    <li
      key={item.id}
      className={`menu-item menu-item-submenu ${isActive}`}
      data-menu-toggle="hover"
    >
      <NavLink className="menu-link menu-toggle" to={item.url} onClick={() => LoadItems(item)}>
        <ItemIcon item={item} />
        <span className="menu-text">{item.label}</span>
        <i className="menu-arrow" />
      </NavLink>
      {
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent">
              <span className="menu-link">
                <span className="menu-text">{item.label}</span>
              </span>
            </li>
            {item.items.length ? (
              item.items.map(item => <NavItem key={item.id} item={item} />)
            ) : (
              <Spinner className="mx-auto mt-5 mb-1" animation="border" variant="secondary" size="sm" />
            )}
          </ul>
        </div>
      }
    </li>
  );
}

function ItemIcon({ item }) {
  return item.svgIconUrl ? (
    <span className="svg-icon menu-icon">
      <SVG src={toAbsoluteUrl(item.svgIconUrl)} />
    </span>
  ) : null;
}
