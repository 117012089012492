// General Info TAB
export const GENERAL = 'general';
export const GENERAL_CODE = "code";
export const GENERAL_DESCRIPTION = "description";
export const GENERAL_MODEL = "model"
export const GENERAL_TYPE_ID = "assetModelTypeId"
export const GENERAL_SUB_TYPE_ID = "assetModelSubTypeId"
export const GENERAL_BRAND_ID = "assetModelBrandId"
export const GENERAL_EXT_WIDTH = "exteriorWidth"
export const GENERAL_EXT_DEPTH = "exteriorDepth"
export const GENERAL_EXT_HEIGHT = "exteriorHeight"
export const GENERAL_INT_WIDTH = "interiorWidth"
export const GENERAL_INT_DEPTH = "interiorDepth"
export const GENERAL_INT_HEIGHT = "interiorHeight"
export const GENERAL_SHELF_WIDTH = "shelfWidth"
export const GENERAL_SHELF_DEPTH = "shelfDepth"
export const GENERAL_DOORS = "numberOfDoors"
export const GENERAL_SHELVES = "numberOfShelves"
export const GENERAL_POWER = "power"
export const GENERAL_PRICE = "price"
export const INCLUDE_IN_DIAGRAM = "isInDiagram"
export const IS_FOR_DIAGRAM_IMAGE = "isDiagramImage";
export const VISUALIZATION_TYPE_CODE = "visualizationTypeCode";

// Images TAB
export const IMAGES ='assetModelImages'
export const IMAGES_ID ='id'
export const IMAGES_IS_DEFAULT ='isDefault'
export const IMAGES_URL ='imageUrl'
export const IMAGES_THUMBNAIL ='imageThumbnail'


// ---------------------------------
export const TABS = [GENERAL, IMAGES];

export const ASSET_MODEL_NONE = "NONE";
export const ASSET_MODEL_PLANOGRAM = "PLNGR";
export const ASSET_MODEL_DIAGRAM = "DGR";
