import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.customerDivisions.listLoading;
export const isItemActionLoading = (state:RootState) => state.customerDivisions.actionsLoading;
export const getItems = (state:RootState) => state.customerDivisions.entities;
export const getItemsSelect = (state:RootState) => state.customerDivisions.entitiesSelect;
export const getItemsCount = (state:RootState) => state.customerDivisions.totalCount;
export const getItemForEdit = (state:RootState) => state.customerDivisions.itemForEdit;
export const getError = (state:RootState) => state.customerDivisions.error;
export const validationErrors = (state: RootState) => state.customerDivisions.validationErrors;
