import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {persistStore} from "redux-persist";
import { rootReducer, rootSaga } from "./rootReducer";
import { queryApi } from "./api";

const sagaMiddleware = createSagaMiddleware(); 

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true
    })
    .concat(sagaMiddleware)
    .concat(queryApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
