import { IQueryParams } from "../../../../app/common/interfaces/API";

export const StatusCssClasses = ["danger", "success", "planned", "done", ""];
export const StatusTitles = ["Inactive", "Active", "Planned", "Done"];

export const tableDefaultKeyColumn = "id";

export const tableDefaultSizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialParams:IQueryParams = {
  Page: 1,
  Size: 10,
  Filter: "",
  Sort: "",
  ComplexSearch: "",
};
