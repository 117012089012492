import { IPlanogramTemplate, ISection, IShelf } from "./_interfaces/builder";
import { v4 as uuidv4 } from "uuid";
import { IAssetTemplate } from "./_interfaces/state";

export const PATHS = {
    ROOT: 'planogrambuilder',

    PLANOGRAM_LIST: 'planograms',
    PLANOGRAM_CREATOR: 'planogram', //":itemId"

    PLANOGRAM_TEMPLATES: 'templates',
    TEMPLATE_CREATOR: 'template', //":itemId"
    
    DIAGRAM_CREATOR: 'diagram', //":itemId"
}

export const BUILDER_TYPE = {
    TEMPLATE: 'TEMPLATE',
    PLANOGRAM: 'PLANOGRAM',
    DIAGRAM: 'DIAGRAM',
}

export const MARGIN_RESIZER_STYLES = {
    top: 'top-resizer-section',
    bottom: 'bottom-resizer-section',
    left: 'margin-resizer',
    right: 'margin-resizer',
    topRight: 'disabled-resizers',
    bottomRight: 'disabled-resizers',
    bottomLeft: 'disabled-resizers',
    topLeft: 'disabled-resizers',
}

export const SHELF_RESIZER_STYLES = {
    top: 'disabled-resizers',
    bottom: 'bottom-resizer',
    left: 'disabled-resizer',
    right: 'disabled-resizer',
    topRight: 'disabled-resizers',
    bottomRight: 'disabled-resizers',
    bottomLeft: 'disabled-resizers',
    topLeft: 'disabled-resizers',
}

export const SECTION_RESIZER_STYLES = {
    top: 'top-resizer-section',
    bottom: 'bottom-resizer-section',
    left: 'left-resizer-section',
    right: 'right-resizer-section',
    topRight: 'disabled-resizers',
    bottomRight: 'disabled-resizers',
    bottomLeft: 'disabled-resizers',
    topLeft: 'disabled-resizers',
}


export const planogramAssignmentId = 'planogramId';

export const assetModelId = 'planogramAssetModelAssignments';
export const equipmentId = 'planogramEquipmentAssignments';
export const customerId = 'planogramCustomerAssignments';
export const customerSegmentId = 'planogramCustomerSegmentAssignments';

export const SHELF_DEFAULT_ALIGNMENTS = [
    'left',
    'center',
    'right',
];

export const SECTION_DEFAULT_COLOR = '#464E5F';
export const SHELF_DEFAULT_COLOR = '#D7DAE7';
export const PIXEL_PER_CM = 37.7952755906;
export const MIN_DIMENSION:number = 1;
export const IMAGE_SCALE_FACTOR:number = .5;
export const SCALE_FACTOR = 10;
export const BE_SCALE_FACTOR = SCALE_FACTOR * IMAGE_SCALE_FACTOR;
export const IMAGE_MAX_WIDTH = 1000;

export const SHELF_THICNESS:number = 4;
export const WALL_THICKNES = 6;
export const DEPTH = 60;
export const HEIGHT = 240;
export const WIDTH = 80;

const customTemplateID = uuidv4();
const customSectionId = uuidv4();

export const emptyItem:any = {
    brand: "Empty",
    category: "Empty",
    code: "Empty",
    depth: 0.1,
    height: 15,
    width: 8,
    id: '',
    name: "Empty",
    totalSize: "W8.0 D0.10 H15.00",
    url: "",
    dimensions: {
        width: 8 * SCALE_FACTOR,
        height: 15 * SCALE_FACTOR,
        depth: 0.1 * SCALE_FACTOR,
    },
    articleCode: 'Empty',
    planogramSectionShelfId: -1,
    articleId: '',  
    articleName: 'Empty',
    order: -1,
    top: 0,
    down: 0,
    right: 0,
    left: 0,
}

const shelvesFromBackend: IShelf[] = [
    {
        id: uuidv4(),
        sectionId: customSectionId,
        order: 1,
        dimensions: { height: 45, width: WIDTH, depth: DEPTH },
        products: [],
        original: false,
        thickness: SHELF_THICNESS,
        color: SHELF_DEFAULT_COLOR,
        alignment: SHELF_DEFAULT_ALIGNMENTS[0],
    },
];

export const entityFromBackend:ISection[] = [
    {
        id: customSectionId,
        templateId: customTemplateID,
        order: 1,
        thickness: WALL_THICKNES,
        original: false,
        color: SECTION_DEFAULT_COLOR,
        dimensions: {
            height: HEIGHT + WALL_THICKNES * 2,
            width: WIDTH + WALL_THICKNES * 2,
            depth: DEPTH + WALL_THICKNES * 2
        },
        innerDimensions: { height: HEIGHT, width: WIDTH, depth: DEPTH },
        shelves: shelvesFromBackend
    },
];

export const defaultCustomTemplate:IPlanogramTemplate = {
    id: customTemplateID,
    isActive: true,
    title: 'Untitled',
    dimensions: {
      width: WIDTH,
      height: HEIGHT,
      depth: DEPTH
    },
    sections: entityFromBackend,
}

export const defaultAssetTemplate:IAssetTemplate = {
    description: '',
    id: uuidv4(),
    imageThumbnail: '',
    interiorDepth: DEPTH,
    interiorHeight: HEIGHT,
    interiorWidth: WIDTH,
    model: 'Custom model',
    numberOfDoors: 1,
    numberOfShelves: 1,
    shelfDepth: DEPTH,
    shelfWidth: WIDTH,
}

export const ItemTypes = {
    SECTION: 'section',
    SHELF: 'shelf',
    ARTICLE: 'article',
}