import { useState, SyntheticEvent  } from "react";
import { Spinner } from "react-bootstrap";
import SvgIcon from "../SvgIcon";

const ImageMagnifier = ({
  src = "/media/svg/icons/Design/Image.svg",
  width = "5rem",
  height = "5rem",
  // magnifierHeight = 250,
  // magnifierWidth = 250,
  // zoomLevel = 7,
}) => {
  // const [[x, y], setXY] = useState([0, 0]);
  // const [showMagnifier, setShowMagnifier] = useState(false);
  const [isImageAvailable, setIsImageAvailable] = useState(true);
  const [isImageLandscape, setIsImageLandscape] = useState(false);
  const [loading, setLoading] = useState(true);

  const onImageLoaded = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.currentTarget.width > e.currentTarget.height) {
      setIsImageLandscape(true);
    }
    setLoading(false);
  }

  const onImageError = () => {
    setIsImageAvailable(false);
    setLoading(false);
  }

  return (
    <div
      style={{
        position: "relative",
        height: height,
        width: width,
      }}
    >
      <div
        style={{
          display: "block",
          position: "relative",
          overflow: "hidden",
          border: "1px solid #E5EAEE",
          borderRadius: "0.5rem",
          height: "5rem",
          width: "5rem",
        }}
      >
        {isImageAvailable ? (
          <>
            <Spinner
              style={{ display: loading ? "block" : "none", margin: "2rem auto" }}
              animation="border"
              variant="secondary"
              size="sm"
            />
            <div style={{ display: loading ? "none" : "block" }}>
              <img
                src={src}
                alt={"stitched_image"}
                style={{ objectFit: "contain", height: height, width: width, cursor: "pointer" }}
                onLoad={e => onImageLoaded(e)}
                onError={onImageError}
                // onMouseEnter={() => setShowMagnifier(true)}
                // onMouseMove={e => {
                //   // update cursor position
                //   const elem = e.currentTarget;
                //   const { top, left } = elem.getBoundingClientRect();

                //   // calculate cursor position on the image
                //   const x = e.pageX - left - window.pageXOffset;
                //   const y = e.pageY - top - window.pageYOffset;
                //   setXY([x, y]);
                // }}
                // onMouseLeave={() => setShowMagnifier(false)}
              />
            </div>
          </>
        ) : (
          <SvgIcon
            iconLocation="/media/svg/icons/Design/Image.svg"
            size="4"
            bootstrapColor="muted"
            customClasses="d-flex justify-content-center align-items-center h-100 opacity-60"
            title="Image Unavailable"
          />
        )}
      </div>
      {/* <div
        style={{
          display: showMagnifier ? "" : "none",
          // display: '',
          position: "absolute",
          zIndex: 200,
          borderRadius: "0.5rem",

          // prevent magnifier blocks the mousemove event of img
          pointerEvents: "none",

          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,

          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2 + 175}px`,
          opacity: "1", // reduce opacity so you can verify position
          border: "1px solid lightgray",
          backgroundColor: "#EDEFF7",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",
          padding: "0 400px 0 0",

          //calculate zoomed image size
          backgroundSize: `${isImageLandscape ? "500px auto" : "auto 500px"}`,

          //calculate position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      /> */}
    </div>
  );
};

export default ImageMagnifier;
