import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";

export const IconImageFormatter = (
    cell: string,
    row: any,
    setImageData:(data:any[]) => void,
    setShowModal:(val:boolean) => void
) => {
    return (
        <span>
            <OverlayTrigger placement="left" overlay={<Tooltip id="add-button">Stiched Image</Tooltip>}>
                <button
                    className={`btn btn-icon btn-white btn-hover-primary pointer`}
                    data-testid="image-preview"
                    onClick={() => {
                        setImageData([row])
                        setShowModal(true);
                    }}
                >
                    <span className={`svg-icon svg-icon-size-5 svg-icon-primary`}>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Image.svg")} title=" " />
                    </span>
                </button>
            </OverlayTrigger>
        </span>
    );
};
