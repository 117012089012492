import { slice } from "./slice";
import { swaggerClient } from "../../../api/swaggerApiConnect";

const { actions } = slice;

export const fetchSwaggerSchemas = dispatch => {
  swaggerClient
    .then(client => {
      // Construct "swaggerValidationSchemas" object for Redux (with data necessary for generating FE validations)
      const swaggerValidationSchemas = {};

      Object.entries(client.spec.paths).forEach(([path, methods]) => {
        if (Object.keys(methods).includes("post") || Object.keys(methods).includes("put")) { // Get only endpoints with POST and PUT methods
          const filteredMethods = {};
          
          Object.entries(methods).forEach(([method, validations]) => {
            if (method === "post" || method === "put") { // Get only POST and PUT schemas
              if(validations.requestBody.content["application/json"]) {
                filteredMethods[method] = validations.requestBody.content["application/json"]
                swaggerValidationSchemas[path] = filteredMethods; // Construct the object
              }
            }
          });
        }
      });

      dispatch(actions.setSwaggerValidations(swaggerValidationSchemas)); // Attach the constructed object to Redux
    })
    .catch(errors => {
      const error = errors?.response?.body?.errors || "Error fetching swagger.json";
      dispatch(actions.setSwaggerValidations({ error }));
    });
};
