import { getDefaultSlice } from "../../../../../redux/_helpers";
import {
  SURVEY_ANSWER_TYPES_ENTITY_TYPE,
  SURVEY_ANSWER_TYPES_LOADING_TYPE,
  SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE,
  SURVEY_ANSWER_SUB_TYPES_LOADING_TYPE,
  SURVEY_SECTION_TYPES_ENTITY_TYPE,
  SURVEY_ARTICLES,
  SURVEY_ARTICLES_LOADING,
  SURVEY_SECTION_TYPES_LOADING_TYPE,
} from "./constants";

const customReducers = {
  taskTypesFetched: (state, action) => {
    state.error = null;
    state[action.payload.callType] = false;
    state.taskTypes = action.payload;
  },
  surveyAnswerTypesFetched: (state, action) => {
    state.error = null;
    state[action.payload.callType] = false;
    state.surveyAnswerTypes = action.payload.data;
  },
  surveyAnswerSubTypesFetched: (state, action) => {
    state.error = null;
    state[action.payload.callType] = false;
    state[SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE] = action.payload.data;
  },

  surveyArticlesLoading: (state, action) => {
    state[action.payload.callType][action.payload.data.qIndex] = true;
  },

  surveyArticlesFetched: (state, action) => {
    state.error = null;
    state[action.payload.callType][action.payload.data.qIndex] = false;
    state[SURVEY_ARTICLES][action.payload.data.qIndex] = action.payload.data.list; //TODO: 
  },

  surveyAnswerTypeCode: (state, action) => {
    state.error = null;
    state.editingSurveyAnswerTypeCode = action.payload.data;
  },
  surveyDynamicOperators: (state, action) => {
    state.error = null;
    state[action.payload.callType] = false;
    state.surveyDynamicOperators = action.payload.data;
  },
  surveysVersions: (state, action) => {
    state.error = null;
    state.surveysVersions = action.payload.data;
  },
};

const initiaState = {
  [SURVEY_ANSWER_TYPES_ENTITY_TYPE]: [],
  [SURVEY_ANSWER_TYPES_LOADING_TYPE]: false,
  [SURVEY_SECTION_TYPES_ENTITY_TYPE]: [],
  [SURVEY_SECTION_TYPES_LOADING_TYPE]: false,

  [SURVEY_ANSWER_SUB_TYPES_LOADING_TYPE]: false,
  [SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE]: [],

  [SURVEY_ARTICLES]: [],
  [SURVEY_ARTICLES_LOADING]: []
};

export const surveysSlice = getDefaultSlice("surveys", customReducers, initiaState);
