import { customerPartnerFunctionsSlice } from "./customerPartnerFunctionsSlice";
import * as defaultActions from "../../../../../redux/_helpers";

const { actions } = customerPartnerFunctionsSlice;

export const QPARAMS_CHANGED = "customerPartnerFunctions/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "CustomerPartnerFunctions_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerPartnerFunctions_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "CustomerPartnerFunctions_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerPartnerFunctions_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerPartnerFunctions_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "CustomerPartnerFunctions_Post",
  actions,
  defaultActions.convertCreateItemParams(["name"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerPartnerFunctions_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
