import { equipmentSlice } from "./equipmentSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get, put } from "../../../../../api/requester";
import { Dispatch } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import * as fields from "../../pages/equipment/edit/constants";
import { IDiagram, IDiagramEqpmentListItem, IDiagramEquipment, IDiagramSection, IEditItem, IEquipmentPlanogram, IValues } from "./_interfaces";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { MIN_DIMENSION, SCALE_FACTOR, SHELF_DEFAULT_ALIGNMENTS } from "../../../Planogram/constants";
import { getScaleFactor, isItemUpdated, scaleValueForBE } from "../../../Planogram/_redux/planogram/actionUtils";

const { actions } = equipmentSlice;
export const moduleUrl = "/api/assetmanagement/equipments";

export const setValidationErrors = actions.setValidationErrors;
export const setplanogramsForEquipment = actions.setplanogramsForEquipment

export const getAssets = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const saveAsset = (asset: {}, id: string | null) =>
  defaultActions.saveItem(
    moduleUrl,
    actions,
    asset,
    id,
    refineDataForSaving,
  );

export const getAsset = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);

export const deleteAsset = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const getEquipmentForTaskCreation = (params: {}) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setEquipmentForTasksLoading(true));
    const res = await get(`${moduleUrl}/equipmentsForTaskCreation`, params);
    dispatch(actions.setEquipmentForTasks(res));
  } catch (error) {

  } finally {
    dispatch(actions.setEquipmentForTasksLoading(false))
  }
}

export const clearAll = () => defaultActions.clearAll(actions);
export const clearArticle = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const clearWarning = () => actions.clearWarning();
export const catchError = (error:{errors:string, callType:string, errorProp:string}) => actions.catchError(error);
export const catchWarning = (error:string) => actions.setWarning(error);

/////////////////////////////
const refineDataForTabs = (data: IEditItem) => {
  return {
    id: data.id,
    [fields.GENERAL]: {
      [fields.GENERAL_ID]: data[fields.GENERAL_ID],
      [fields.GENERAL_EQUIPMENT_ID]: data[fields.GENERAL_EQUIPMENT_ID],
      [fields.GENERAL_MODEL_ID]: data[fields.GENERAL_MODEL_ID],
      [fields.GENERAL_SERIAL_NUMBER]: data[fields.GENERAL_SERIAL_NUMBER],
      [fields.GENERAL_BARCODE]: data[fields.GENERAL_BARCODE],
      [fields.GENERAL_EQUIPMENT_STATUS_ID]: data[fields.GENERAL_EQUIPMENT_STATUS_ID],
      [fields.GENERAL_ASSORTMENT_LIST]: data[fields.GENERAL_ASSORTMENT_LIST],
      [fields.GENERAL_NOTES]: data[fields.GENERAL_NOTES],   
      [fields.PRIMARY_PLANOGRAM]: data[fields.FORMATTED_PRIMARY_PLANOGRAM_INFO],
      [fields.IS_SMART]: data[fields.IS_SMART] ? 'Yes' : 'No',
      [fields.SMART_EQUIPMENT_STATUS_ID]: data[fields.SMART_EQUIPMENT_STATUS_ID],
      [fields.FORMATTED_EQUIPMENT_INFO]: data[fields.FORMATTED_EQUIPMENT_INFO],
      [fields.PARENT_ID]: data[fields.PARENT_ID],
      [fields.VISUALIZATION_TYPE_CODE]: data[fields.VISUALIZATION_TYPE_CODE],
      [fields.FORMATTED_PARENT_EQUIPMENT_INFO]: data[fields.FORMATTED_PARENT_EQUIPMENT_INFO],
    },
  };
};

/////////////////////////////
const refineDataForSaving = (data: IValues) => {
  return {
    ...(data.id && { id: data.id }),
    [fields.GENERAL_EQUIPMENT_ID]: data.general.equipmentId,
    [fields.GENERAL_MODEL_ID]: data.general.assetModelId,
    [fields.GENERAL_SERIAL_NUMBER]: data.general.serialNumber,
    [fields.GENERAL_BARCODE]: data.general.barcode,
    [fields.GENERAL_EQUIPMENT_STATUS_ID]: data.general.equipmentStatusId || null,
    [fields.GENERAL_ASSORTMENT_LIST]: data.general.equipmentAssortments,
    [fields.GENERAL_NOTES]: data.general.notes,
    [fields.PRIMARY_PLANOGRAM_FOR_UPDATE]: data.general.primaryPlanogramEquipmentAssignmentId,
    [fields.IS_SMART]: data.general.isSmart === 'Yes',
  };
};

export const getFilters = () => async (dispatch:Dispatch) => {
  try {
    const assetmodels:any[] = await get("api/assetmanagement/assetmodels/complexSearch");
    const assetmodeltypes:any[] = await get("api/assetmanagement/assetmodeltypes/complexSearch");
    const assetmodelbrands:any[] = await get("api/assetmanagement/assetmodelbrands/complexSearch");

    dispatch(actions.setFilter(
      constructInitialFilters([
        ...(assetmodels || []),
        ...(assetmodeltypes || []),
        ...(assetmodelbrands || []),
      ])
    ));

  } catch (error) {

  }
}

export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);


export const getEquipmentPlanogramsList = (EquipmentId:string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setplanogramsForEquipmentLoading(true))
    const response:IEquipmentPlanogram[] = await get('/api/planogram/planograms/assigned', {EquipmentId});
    const sortedResponse = response.sort((a, b) => a.isPrimary ? -1 : b.isPrimary ? 1 : 0)
    dispatch(actions.setplanogramsForEquipment(sortedResponse));
  } catch (error) {

  } finally {
    dispatch(actions.setplanogramsForEquipmentLoading(false))
  }
}

export const getEquipmentDiagram = (EquipmentId:string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setEquipmentDiagramLoading(true));
    const result = await get('/api/diagram/diagrams', { EquipmentId });
    dispatch(actions.setEquipmentDiagram(transformDiagram(result)));
  } catch (error) {

  } finally {
    dispatch(actions.setEquipmentDiagramLoading(false))
  }
}

export const setSections = (sections:IDiagramSection[]) => actions.setSections(sections);
export const setDeletedSections = (id:string) => actions.setDeletedSections({ id });
export const setDeletedShelves = (id:string, sectionId:string) => actions.setDeletedShelves({ id, sectionId });
export const setDeleteProducts = (products:{id:string, sectionId:string, shelfId:string}[]) => actions.setDeleteProducts(products);
export const resetDeleteSections = () => actions.resetDeleteSections();

export const transformDiagram = (diagram:any) => {
  return {
    ...diagram,
    dimensions: {
      width: Math.max(Number(diagram.width), MIN_DIMENSION) * SCALE_FACTOR,
      height: Math.max(Number(diagram.height), MIN_DIMENSION) * SCALE_FACTOR,
      depth: Math.max(Number(diagram.depth), MIN_DIMENSION) * SCALE_FACTOR,
    },
    sections: diagram.sections.map((section:any, sectionIndex:any) => ({
      ...section,
      original: true,
      updated: false,
      thickness: section.thickness * SCALE_FACTOR,
      order: sectionIndex + 1,
      dimensions: {
        width: Math.max(Number(section.width), MIN_DIMENSION) * SCALE_FACTOR,
        height: Math.max(Number(section.height), MIN_DIMENSION) * SCALE_FACTOR,
        depth: Math.max(Number(section.depth), MIN_DIMENSION) * SCALE_FACTOR,
      },
      innerDimensions: {
        width: Math.max(Number(section.width - section.thickness), MIN_DIMENSION) * SCALE_FACTOR,
        height: Math.max(Number(section.height - section.thickness), MIN_DIMENSION) * SCALE_FACTOR,
        depth: Math.max(Number(section.depth - section.thickness), MIN_DIMENSION) * SCALE_FACTOR,
      },
      shelves: section.shelves.map((shelf:any, shelfIndex:number) => ({
        ...shelf,
        original: true,
        updated: false,
        sectionId: shelf.diagramSectionId,
        order: shelfIndex + 1,
        thickness: shelf.thickness * SCALE_FACTOR,
        dimensions: {
          width: Math.max(Number(section.width - section.thickness), MIN_DIMENSION) * SCALE_FACTOR,
          height: Math.max(Number(Number(shelf.height).toFixed(2)) * SCALE_FACTOR + shelf.thickness * SCALE_FACTOR, MIN_DIMENSION),
          depth: Math.max(Number(shelf.depth), MIN_DIMENSION) * SCALE_FACTOR,
        },
        products: shelf.equipments.map((product:any, productIndex:number) => ({
          ...product,
          original: true,
          updated: false,
          url: product.imageUrl,
          equipmentType: product.type,
          order: productIndex + 1,
          marginRight: (product.marginRight || 0) * SCALE_FACTOR,
          marginLeft: (product.marginLeft || 0) * SCALE_FACTOR,
          dimensions: {
            width: Number(product.width) * SCALE_FACTOR,
            height: Number(product.height) * SCALE_FACTOR,
            depth: Number(product.depth || MIN_DIMENSION) * SCALE_FACTOR,
          }
        }))
      }))
    }))
  }
}

export const getEquipmentsForDiagram = () => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setEquipmentsForDiagramLoading(true));
    const result = await get(`/api/diagram/diagrams/getDiagramEquipments`);
    dispatch(actions.setEquipmentsForDiagram(transformEquipmentsForDiagram(result)));
  } catch (error) {

  } finally {
    dispatch(actions.setEquipmentsForDiagramLoading(false));
  }
}

const transformEquipmentsForDiagram = (equipments:IDiagramEqpmentListItem[]):IDiagramEqpmentListItem[] => {

  return equipments.map((equipment:IDiagramEqpmentListItem) => ({
    ...equipment,
    id: uuidv4(),
    equipmentType: equipment.type,
    url: equipment.imageUrl,
    dimensions: {
      width: equipment.width * SCALE_FACTOR,
      height: equipment.height * SCALE_FACTOR,
      depth: equipment.depth * SCALE_FACTOR,
    }
  }))
}


export const updateDiagram = (
  diagram:IDiagram,
  deleteSections: [],
  deletedShelves: [],
  deleteEquipment: [],
) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setDiagramSaveLoading(true));

    let sectionX = 0;
    const sectionsWithX = diagram.sections.map((s, i) => {
        sectionX += diagram.sections[i - 1] ? diagram.sections[i - 1].dimensions.width : 0
        return { ...s, sectionX: sectionX}
    });

    const updateSections:any[] = sectionsWithX.filter(({ updated, original }) => updated || !original);
    const diagramForUpdate = await refineDataForUpdate(diagram, deleteSections, deletedShelves, deleteEquipment, updateSections);
    await put('/api/diagram/diagrams', {}, diagramForUpdate);
    dispatch(resetDeleteSections());

  } catch (error) {

  } finally {
    dispatch(actions.setDiagramSaveLoading(false))
  }
}

const refineDataForUpdate = async (
  diagram:IDiagram,
  deleteSections: { id:string }[],
  deletedShelves: { id:string, sectionId:string }[],
  deleteEquipments: { id:string, sectionId:string, shelfId:string }[],
  updateSections:any[],
) => {
  const formData = new FormData();
  const planogramWidth = diagram.sections.reduce((a, { dimensions }) => a + dimensions.width, 0)
  const scale = SCALE_FACTOR * getScaleFactor(planogramWidth)

  const form = {
    id: diagram.id,
    // "thickness": 0,
    scale,
    width: scaleValueForBE(diagram.dimensions.width),
    depth: scaleValueForBE(diagram.dimensions.depth),
    height: scaleValueForBE(diagram.dimensions.height),
    sections: updateSections.map((section:any) => {
        return {
            ...(section.original ? {id: section.id} : {}),
            diagramId: diagram.id,
            order: section.order,
            color: section.color,
            width: scaleValueForBE(section.innerDimensions.width),
            depth: scaleValueForBE(section.innerDimensions.depth),
            height: scaleValueForBE(section.innerDimensions.height),
            thickness: section.thickness / SCALE_FACTOR,
            deleteShelves: deletedShelves.filter(({ sectionId }) => sectionId === section.id),
            shelves: section.shelves.reduce((a:any[], shelf:any, sIndex:number) => {
                return [
                    ...a,
                    ...(isItemUpdated(shelf)
                        ? [{
                            ...(shelf.original ? {id: shelf.id} : {}),
                            ...(shelf.original ? {diagramSectionId: section.id || "" } : {}),
                            color: shelf.color,
                            alignment: shelf.alignment || SHELF_DEFAULT_ALIGNMENTS[0],
                            order: shelf.order,
                            width: scaleValueForBE(shelf.dimensions.width),
                            depth: scaleValueForBE(shelf.dimensions.depth),
                            height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
                            thickness: shelf.thickness / SCALE_FACTOR,
                            equipments: shelf.products?.reduce((allProducts:IDiagramEquipment[], p:IDiagramEquipment) => {

                                return [
                                    ...allProducts,
                                    ...(isItemUpdated(p) ? [{
                                      ...(p.original ? {id: p.id} : {}),
                                      ...(p.original ? {diagramSectionShelfId: shelf.id} : {}),
                                      equipmentUniqueId: p.equipmentUniqueId,
                                      order: p.order,
                                      width: Math.max(Number(p.dimensions.width), MIN_DIMENSION) / SCALE_FACTOR,
                                      height: Math.max(Number(p.dimensions.height), MIN_DIMENSION) / SCALE_FACTOR,
                                      depth: Math.max(Number(p.dimensions.depth), MIN_DIMENSION) / SCALE_FACTOR,
                                      marginLeft: scaleValueForBE(p.marginLeft),
                                      marginRight: scaleValueForBE(p.marginRight),
                                    }] : [])
                                ]
                            }, []),
                            deleteEquipments: deleteEquipments.filter(({sectionId, shelfId}) =>
                                shelfId === shelf.id && section.id === sectionId)
                                .map((p) => ({id: p.id})),
                        }]
                        : [])
                ]
            }, [])
        }
    }),
    deleteSections
}
  
  formData.append('command', JSON.stringify(form));
  return formData;
}