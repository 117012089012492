import { useState } from "react";
import { Dropdown } from "react-bootstrap";

const MoreOptions: React.FC = () => {
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      align={"start"}
      show={show}
      onClick={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      style={{opacity: 0.5, pointerEvents: "none"}} // disables the DDL
    >
      <Dropdown.Toggle id="dropdown-toggle-top" >
        <i className="ki ki-bold-more-hor" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#">Coming soon</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreOptions;
