import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntities, IEditItem } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, IEditItem> {
  baseArticles: [];
  baseArticlesLoading: boolean;
}

const initialState: IState = {
  ...defaultInitialState,
  baseArticles: [],
  baseArticlesLoading: false,
};

const reducers = {
  ...defaultReducers,
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers,
});
