import { RootState } from "../../../../../redux/store";

export const documentsLoading = (state: RootState) => state.documentation.itemsLoading;
export const documentsActionLoading = (state: RootState) => state.documentation.actionsLoading;
export const dropDownLoading = (state: RootState) => state.documentation.dropDownLoading;

export const documents = (state: RootState) => state.documentation.items;

export const documentsCount = (state: RootState) => state.documentation.itemsCount;
export const documentForEdit = (state: RootState) => state.documentation.itemForEdit;
export const getItemsSelect = (state: RootState) => state.documentation.dropDownItems;

export const error = (state: RootState) => state.documentation.error;
export const errorAction = (state: RootState) => state.documentation.errorAction;
export const dropDownError = (state: RootState) => state.documentation.dropDownError;

export const validationErrors = (state: RootState) => state.documentation.validationErrors;

export const filters = (state: RootState) => state.documentation.filters;