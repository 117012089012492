import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.irScenes.listLoading;
export const isItemActionLoading = (state:RootState) => state.irScenes.actionsLoading;
export const getItems = (state:RootState) => state.irScenes.entities;
export const getItemsSelect = (state:RootState) => state.irScenes.entitiesSelect;
export const getItemsCount = (state:RootState) => state.irScenes.totalCount;
export const getItemForEdit = (state:RootState) => state.irScenes.itemForEdit;
export const getError = (state:RootState) => state.irScenes.error;
export const validationErrors = (state: RootState) => state.irScenes.validationErrors;
