import { customerTypesSlice } from "./customerTypesSlice";
import { get } from "../../../../../api/requester";
import * as defaultActions from "../../../../../redux/_helpers";
import { constructInitialFilters } from "../../../../components/common/filters/utils";

const { actions } = customerTypesSlice;

export const QPARAMS_CHANGED = "customerTypes/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "CustomerTypes_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerTypes_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "CustomerTypes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerTypes_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerTypes_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "CustomerTypes_Post",
  actions,
  defaultActions.convertCreateItemParams(["name"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerTypes_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);