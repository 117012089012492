import { Card } from "react-bootstrap";
import { Access } from "../../../../app/user-access/usePageAccessHook";
import { AccessButton } from "../../controls/AccessButton";
import MoreOptions from "../../controls/MoreOptions";
import { ReactNode } from "react";

interface IProps {
  header: string;
  entity: string;
  newItemLink?: string;
  handleClick: () => void;
  hasAccess: Access;
  className?:string;
  customActionButton?: any;
  options?: any[];
  children:ReactNode | ReactNode[];
}

export const TableCard: React.FC<IProps> = ({options, customActionButton, children, header, entity, newItemLink, handleClick, hasAccess, className}) => {
  return (
    <Card className={className || ""}>
      <Card.Header className="d-flex py-6 justify-content-between">
        <h3 className="card-label fs-17 fw-500 d-flex align-items-center m-0" data-testid="items-table-title">{header}</h3>
        <div className="d-flex align-items-center">
          {hasAccess === Access.WRITE && (
            <>
              {customActionButton || <AccessButton
                onClick={handleClick}
                title={`New ${entity}`}
                newItemLink={newItemLink || "#"}
              />}
              {options?.length
                && <>
                  <span className="vertical-divider" />
                  <MoreOptions />
                </>}
            </>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
};
