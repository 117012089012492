import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { ISection } from "../../_interfaces/builder";
import { IAssetTemplate, ITemplateState } from "../../_interfaces/state";

const initialState: ITemplateState = {
    ...defaultInitialState,
    templates: [],
    selectedAssetTemplate: null,
    loadingTemplateBuilder: false,
    templateSaveLoading: false,
    deletedSections: [],
    deletedShelves: [],
    warning: null,
    templateUpdated: false,
}

const reducers = {
    ...defaultReducers,
    clearWarning: (state:ITemplateState, action:PayloadAction) => {
        state.warning = null;
    },
    setWarning: (state:ITemplateState, action:PayloadAction<string>) => {
        state.warning = action.payload
    },
    setAssetTemplates: (state:ITemplateState, action:PayloadAction<IAssetTemplate[]>) => {
        state.templates = action.payload;
    },
    setSelectedAssetTemplate: (state:ITemplateState, action:PayloadAction<IAssetTemplate | null>) => {
        state.selectedAssetTemplate = action.payload;
    },
    removeLoader: (state:ITemplateState, action:PayloadAction) => {
        state.loadingTemplateBuilder = false;
    },
    setSections: (state:ITemplateState, action:PayloadAction<ISection[]>) => {
        state.templateUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            dimensions: {
                width: action.payload.reduce((a, { innerDimensions }) => a + innerDimensions.width, 0),
                depth: Math.max(...action.payload.map(({innerDimensions}) => innerDimensions.depth)),
                height: Math.max(...action.payload.map(({innerDimensions}) => innerDimensions.height))
            },
            sections: action.payload
        }
    },
    setTemplateTitle: (state:ITemplateState, action:PayloadAction<string>) => {
        state.templateUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            title: action.payload,
        }
    },
    setTemplateActive: (state:ITemplateState, action:PayloadAction<boolean>) => {
        state.templateUpdated = true;
        state.itemForEdit = {
            ...state.itemForEdit,
            isActive: action.payload,
        }
    },
    setLoadingTemplateBuilder: (state:ITemplateState, action:PayloadAction<boolean>) => {
        state.loadingTemplateBuilder = action.payload;
    },
    setTemplateSaveLoading: (state:ITemplateState, action:PayloadAction<boolean>) => {
        state.templateUpdated = false;
        state.templateSaveLoading = action.payload;
    },
    setDeletedSections: (state:ITemplateState, action:PayloadAction<{id:string}>) => {
        state.templateUpdated = true;
        state.deletedSections = [...state.deletedSections, action.payload];
    },
    setDeletedShelves: (state:ITemplateState, action:PayloadAction<{id:string, sectionId:string}>) => {
        state.templateUpdated = true;
        state.deletedShelves = [...state.deletedShelves, action.payload];
    },
    resetDeleteSections: (state:ITemplateState, action:PayloadAction) => {
        state.deletedSections = [];
        state.deletedShelves = [];
    },
    setTemplateUpdated: (state:ITemplateState, action:PayloadAction<boolean>) => {
        state.templateUpdated = action.payload
    }
}

export const templatesSlice = createSlice({
    name: "planogramTemplates",
    initialState,
    reducers,
});
