import * as columnFormatters from "../../../_metronic/_partials/table/column-formatters";
import * as fields from './fields'

export const PATHS = {
    ROOT: 'irproducts',
    ONBOARDING: 'onboarding',
    FOREIGN: 'foreign',
}


export const ONBOARDING_COLUMNS:any[] = [
    {
      dataField: fields.IMAGE_URL,
      text: "Image",
      sort: false,
      formatter: columnFormatters.ImageFormatter,
      style: { width: "2%" },
    },
    {
      dataField: fields.CODE,
      text: "Id",
      sort: true,
      style: { width: "7%", maxWidth: '7%' },
    },
    {
      dataField: fields.NAME,
      text: "Description",
      sort: true,
      style: { width: "25%", maxWidth: '25%' },
    },
    {
      dataField: fields.CATEGORY,
      text: "Category",
      sort: true,
       style: { width: "15%", maxWidth: '15%' },
    },
    {
      dataField: fields.ASSET_TYPE,
      text: "Asset Type",
      sort: true,
      style: { width: "35%", maxWidth: '35%' },
      formatter: columnFormatters.AssetTypeFormatter
    },
    {
      dataField: fields.ONBOARD_PERCENTAGE,
      text: "Status",
      sort: true,
      style: { width: "17%", maxWidth: '17%' },
      headerFormatter: columnFormatters.OnboardHeaderStatusFormatter,
      formatter: columnFormatters.OnboardStatusFormatter,
    },
];