import { territoriesSlice } from "./territoriesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as defaultCommonActions from "../../../../../redux/_common";

const { actions } = territoriesSlice;

export const moduleUrl = "/api/appmasterdata/territories"

export const QPARAMS_CHANGED = "territories/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
// export const setValidationErrors = () => defaultActions.setValidationErrors(actions);
export const clearTerritory = () => defaultActions.clearItem(actions);

export const fetchTerritoriesSelectSwagger = defaultActions.fetchItemsSwagger(
  "Territories_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);


export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Territories_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Territories_Get", actions);

export const fetchItemsSwaggerSelect = TerritoryAssignmentId => fetchTerritoriesSelectSwagger({TerritoryAssignmentId: TerritoryAssignmentId?.id || ""})

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Territories_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Territories_Delete", actions);

export const saveItemAction = (territory, id) => defaultCommonActions.saveItem(moduleUrl, actions, territory, id);

export const createItemSwagger = defaultActions.createItemSwagger(
  "Territories_Post",
  actions,
  defaultActions.convertCreateItemParams(["countryId", "territoryAssignmentId", "regionId", "cityId", "code", "description"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("Territories_Put", actions);

export const clearErrors = () => defaultActions.clearError(actions);
