import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.customerPartnerFunctions.listLoading;
export const isItemActionLoading = (state:RootState) => state.customerPartnerFunctions.actionsLoading;
export const getItems = (state:RootState) => state.customerPartnerFunctions.entities;
export const getItemsSelect = (state:RootState) => state.customerPartnerFunctions.entitiesSelect;
export const getItemsCount = (state:RootState) => state.customerPartnerFunctions.totalCount;
export const getItemForEdit = (state:RootState) => state.customerPartnerFunctions.itemForEdit;
export const getError = (state:RootState) => state.customerPartnerFunctions.error;
export const validationErrors = (state: RootState) => state.customerPartnerFunctions.validationErrors;
