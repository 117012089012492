import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.customerSegments.listLoading;
export const isItemActionLoading = (state:RootState) => state.customerSegments.actionsLoading;
export const getItems = (state:RootState) => state.customerSegments.entities;
export const getItemsSelect = (state:RootState) => state.customerSegments.entitiesSelect;
export const getItemsCount = (state:RootState) => state.customerSegments.totalCount;
export const getItemForEdit = (state:RootState) => state.customerSegments.itemForEdit;
export const getError = (state:RootState) => state.customerSegments.error;
export const validationErrors = (state: RootState) => state.customerSegments.validationErrors;

