import { CURRENCY } from "../../../../app/modules/WebShop/constants";

export const PriceColumnFormatter = (cell: string, row: any) => {
    if (cell) {
        return (
          <>{CURRENCY}{Number(cell).toFixed(2)}</>
        );
    } else {
        return '';
    }
};
