import { RootState } from "../../../../../redux/store";

export const templatesLoading = (state: RootState) => state.planogramTemplates.itemsLoading;
export const templateLoading = (state: RootState) => state.planogramTemplates.actionsLoading;
export const loadingBuilder = (state:RootState) => state.planogramTemplates.loadingTemplateBuilder;
export const templateSaveLoading = (state:RootState) => state.planogramTemplates.templateSaveLoading;

export const error = (state: RootState) => state.planogramTemplates.error;
export const warning = (state: RootState) => state.planogramTemplates.warning;
export const errorAction = (state: RootState) => state.planogramTemplates.errorAction;
export const validationErrors = (state: RootState) => state.planogramTemplates.validationErrors;


export const templates = (state: RootState) => state.planogramTemplates.items;
export const templatesCount = (state: RootState) => state.planogramTemplates.itemsCount;
export const itemForEdit = (state: RootState) => state.planogramTemplates.itemForEdit;

export const assetTemplates = (state:RootState) => state.planogramTemplates.templates;
export const selectedAssetTemplate = (state:RootState) => state.planogramTemplates.selectedAssetTemplate;

export const deletedSections = (state:RootState) => state.planogramTemplates.deletedSections;
export const deletedShelves = (state:RootState) => state.planogramTemplates.deletedShelves;

export const getTemplateUpdated = (state:RootState) => state.planogramTemplates.templateUpdated;