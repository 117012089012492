import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.languages.listLoading;
export const isItemActionLoading = (state:RootState) => state.languages.actionsLoading;
export const getItems = (state:RootState) => state.languages.entities;
export const getItemsSelect = (state:RootState) => state.languages.entitiesSelect;
export const getItemsCount = (state:RootState) => state.languages.totalCount;
export const getItemForEdit = (state:RootState) => state.languages.itemForEdit;
export const getError = (state:RootState) => state.languages.error;
export const validationErrors = (state: RootState) => state.languages.validationErrors;
