import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "generalAppData",
  initialState: {
    swaggerValidationSchemas: {},
  },
  reducers: {
    setSwaggerValidations: (state, action) => {
      state.swaggerValidationSchemas = action.payload;
    },
  },
});
