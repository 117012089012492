import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEntities, IEditItem } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, IEditItem> {
  baseArticles: [];
  baseArticlesLoading: boolean;
  filters:any;
}

const initialState: IState = {
  ...defaultInitialState,
  baseArticles: [],
  baseArticlesLoading: false,
  filters: {},
};

const reducers = {
  ...defaultReducers,
};

export const assetModelsSlice = createSlice({
  name: "assetModels",
  initialState,
  reducers,
});
