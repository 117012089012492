import { useState, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector as useSelector } from "../common/_customHooks/redux";
import { selectors } from "./_redux";
import { getCurrentUserOrganizationUnit, setCurrentUserOrganizationUnit } from "./organizationUnitStorage";

export const useUserOrganizationUnitHook = () => {
  const [userOrganizationUnit, setUserOrganizationUnit] = useState({});

  const orgUnits = useSelector(state => {
    return selectors.getOrganizationUnits(state);
  }, shallowEqual);

  useEffect(() => {
    let orgUnit;
    const orgUnitInStorage = getCurrentUserOrganizationUnit();
    if (orgUnitInStorage && orgUnits && orgUnits.length > 0) {
      orgUnit = orgUnits.find(x => x.id === orgUnitInStorage);
    }

    if (!orgUnit && orgUnits && orgUnits.length > 0) {
      orgUnit = orgUnits[0];
      setCurrentUserOrganizationUnit(orgUnit.id);
    }
    setUserOrganizationUnit(orgUnit);
  }, [orgUnits]);

  return [userOrganizationUnit, setUserOrganizationUnit];
};
