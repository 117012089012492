import { customerDistributionTypesSlice } from "./customerDistributionTypesSlice";
import * as defaultActions from "../../../../../redux/_helpers";

const { actions } = customerDistributionTypesSlice;

export const QPARAMS_CHANGED = "customerDistributionTypes/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "CustomerDistributionTypes_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerDistributionTypes_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "CustomerDistributionTypes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerDistributionTypes_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerDistributionTypes_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "CustomerDistributionTypes_Post",
  actions,
  defaultActions.convertCreateItemParams(["name"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerDistributionTypes_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);