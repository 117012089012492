import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import PopupPicker from '../../../../app/components/common/PopupPicker/PopupPicker';

type IProps = {
  cellValue: string[];
  pathname: string;
  rowId:string;
}

export const TagsFormatter:FC<IProps> = ({cellValue, pathname, rowId}) => {
  const visibleTags:string[] = [];
  const rest:string[] = [];

  cellValue?.forEach((tag, i) =>
      i <= 3 ? visibleTags.push(tag) : rest.push(tag)
  );

  return (
    <div className="flex jc-flex-start">
      {visibleTags.map((value) => <Badge key={value} bg="secondary" className='mr-3'>{value}</Badge>)}
      <PopupPicker
        key="popupPicker"
        selectable={false}
        group={rowId}
        label={rest.length > 0 ? `+${rest.length} more` : ''}
        items={cellValue.map((label) => ({label, selected: false}))}
        toggleClassname="mt-0 mb-0"
      />
    </div>
  );
}
