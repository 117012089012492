import { irScenesSlice } from "./irScenesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as fields from "../../pages/IRData/tabs/irScenes/edit/constants";

const { actions } = irScenesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

// CRUD
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "SceneTypes_Get",
  actions,
  "SceneTypes/QParamsChanged",
);

export const createItemSwagger = defaultActions.createItemSwagger(
  "SceneTypes_Post",
  actions,
  defaultActions.convertCreateItemParams([
    fields.SCENE_NAME,
    fields.SCENE_LOCAL_NAME,
    fields.SCENE_ORDER,
    fields.SCENE_IS_MANDATORY,
  ]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("SceneTypes_Put", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("SceneTypes_Delete", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("SceneTypes_GetById", actions);

// Select
export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "SceneTypes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

//Reset state
export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
