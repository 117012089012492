/**
 * @usage deepCopy function creates a Deep copy for any object or array, unlike the JS spread operator "..." which creates a Shallow copy
 * @param objectType - {} | []
 */
 export const deepCopy = (objectType: {} | []) => {
  return JSON.parse(JSON.stringify(objectType));
};

/**
 * 
 * @param dateString string in format "2012-08-20T15:00:00-07:00" that come form BE without "Z" UTC suffix
 * @returns "2012-08-20T15:00:00-07:00Z"
 */
export const addUTCSuffix = (dateString:string) => {
  return `${dateString}${dateString.indexOf('Z') < 0 ? 'Z' : ''}`
}