import { RootState } from "../../../../../redux/store";

export const equipmentStatusesLoading = (state: RootState) => state.equipmentStatuses.itemsLoading;
export const equipmentStatusActionLoading = (state: RootState) => state.equipmentStatuses.actionsLoading;
export const dropDownLoading = (state: RootState) => state.equipmentStatuses.dropDownLoading;

export const equipmentStatuses = (state: RootState) => state.equipmentStatuses.items;
export const equipmentStatusesCount = (state: RootState) => state.equipmentStatuses.itemsCount;
export const equipmentStatusForEdit = (state: RootState) => state.equipmentStatuses.itemForEdit;
export const dropDownValues = (state: RootState) => state.equipmentStatuses.dropDownItems;

export const error = (state: RootState) => state.equipmentStatuses.error;
export const errorAction = (state: RootState) => state.equipmentStatuses.errorAction;
export const dropDownError = (state: RootState) => state.equipmentStatuses.dropDownError;

export const validationErrors = (state: RootState) => state.equipmentStatuses.validationErrors;