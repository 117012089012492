import { Dispatch } from "redux";
import { rtrTasksSlice } from "./rtrTasksSlice";
import { get, post } from "../../../../../api/requester";
import { getResponseErrorMessage } from "../../../../../redux/_helpers";
import {
  IConfirmedProducts,
  ICorrectedProducts,
  IError,
  ISeneValidation,
  IScroll,
  ITaskFilters,
  IFeed,
  IFilter,
  IFilterGroups,
  IProducts,
} from "../../_interfaces/reduxState";
import { format } from "date-fns";
import { constructInitialFilters } from "../../../../components/common/filters/utils";

interface IParams {
  Page: number;
  Count: number;
  "Filters.StartDate"?: string;
  "Filters.EndDate"?: string;
  "Filters.CustomerSearch"?: string;
  "Filters.TaskSearch"?: string;
  "Filters.ComplexSearch"?: string;
}

export const modulePath = "/api/imagerecognition/RtrMonitoring"
const { actions } = rtrTasksSlice;

export const getTasks = (page: number, count: number, filters: ITaskFilters) => async (dispatch: Dispatch) => {
  dispatch(actions.setLoading(true));

  let params: IParams = {Page: page, Count: count}

  if (filters.byTask.startDate) {
    params["Filters.StartDate"] = `${format(filters.byTask.startDate, "yyyy-MM-dd")}`
  }
  if (filters.byTask.endDate) {
    params["Filters.EndDate"] = `${format(filters.byTask.endDate, "yyyy-MM-dd")}`
  }
  if (filters.byTask.search) {
    params["Filters.CustomerSearch"] = filters.byTask.search;
  }

  if (filters.byTask.id) {
    params["Filters.TaskSearch"] = filters.byTask.id;
  }

  if (filters.byTask.ComplexSearch) {
    params["Filters.ComplexSearch"] = filters.byTask.ComplexSearch;
  }

  try {
    const res = await get(modulePath, params);
    dispatch(actions.getTasks(res));
    dispatch(actions.setLoading(false));
  } catch (errors: any) {
    dispatch(actions.setLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors.status, message }));
  }
};

export const resetTasks = () => async (dispatch: Dispatch) => {
  dispatch(actions.resetTasks());
};

export const resetTaskFilters = () => (dispatch:Dispatch) => {
  dispatch(actions.resetTaskFilters());
}

export const getSceneProductsDetails = (id:string, complexSearch:string ) => async (dispatch:Dispatch) => {
  dispatch(actions.setSceneProductsLoading(true));
  try {
    const params = complexSearch ? { complexSearch } : {}
    const sceneProducts = await get(`${modulePath}/SceneProductsDetails/${id}`, params)

    dispatch(actions.getSceneProducts(sceneProducts));
    // dispatch(actions.getSceneProducts(sceneProducts.map((article:IProducts) => {
    //   return {
    //     ...article,
    //     title: article.title
    //       ? article.title
    //       : article.originalTitle || article.prevTitle 
    //   }
    // })));
    // dispatch(actions.setError({status: "", message: ""}))
  } catch (errors:any) {
    dispatch(actions.setSceneProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
}

export const getSceneHeaderDetails = (id:string) => async (dispatch:Dispatch) => {
  dispatch(actions.setLoading(true));
  try {
    const sceneHeader = await get(`${modulePath}/TaskScenesDetails/${id}`)
    dispatch(actions.getSceneHeader(sceneHeader));
    dispatch(actions.setLoading(false));
  } catch (errors:any) {
    dispatch(actions.setLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
}

export const getTaskDetails = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actions.setLoading(true));
  try {
    const res = await get(`${modulePath}/TaskDetails/${id}`);
    dispatch(actions.getTaskDetails(res));
    dispatch(actions.setLoading(false));
  } catch (errors: any) {
    dispatch(actions.setLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

export const setLiveTaskChanges = (taskData: IFeed) => (dispatch: Dispatch) => {
  dispatch(actions.setLiveTaskChanges(taskData));
};

export const setTaskFilters = (filters: ITaskFilters) => (dispatch: Dispatch) => {
  dispatch(actions.setTaskFilters(filters));
};

export const setCurrentPage = (scrollData: IScroll) => (dispatch: Dispatch) => {
  dispatch(actions.setInfinityScroll(scrollData));
};

export const getStatuses = () => async (dispatch: Dispatch) => {
  try {
    const res = await get(`${modulePath}/GetAuditStatus`);
    dispatch(actions.getStatuses(res));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

export const setSceneStatus = (state: string) => (dispatch: Dispatch) => {
  dispatch(actions.setSceneStatus({ status: state, viewOnly: Boolean(state === "Validated" || state === "Rejected") }));
};

export const setUnlockSceneValidation = (state: boolean) => (dispatch: Dispatch) => {
  dispatch(actions.setUnlockSceneValidation(state));
};

// Products from search
export const getAvailableProducts = (filter: string) => async (dispatch: Dispatch) => {
  try {
    const res = await get(`${modulePath}/GetArticles`, { Filter: filter, getForeign: true })
    dispatch(actions.getAvailableProducts(res));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

/**
 * Product Confirmation
 * 
 * @DataStructure 3 - "confirmedProducts"
 * 
 * @param id article/product id
 * @param isConfirmed current state
 * 
 */
export const collectConfirmedProduct = (products: IConfirmedProducts) => (dispatch: Dispatch) => {
  try {
    dispatch(actions.collectConfirmedProduct(products));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

export const removeConfirmedProduct = (products: IConfirmedProducts) => (dispatch: Dispatch) => {
  try {
    // dispatch(actions.setSceneProductsLoading(true));
    dispatch(actions.removeConfirmedProduct(products));
  } catch (errors: any) {
    // dispatch(actions.setSceneProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};


export const setConfirmedProducts = (confirmedProducts: IConfirmedProducts) => async (dispatch: Dispatch) => {
  // dispatch(actions.setSceneProductsLoading(true));
  try {
    const response = await post(`${modulePath}/ConfirmedSceneDetail`, {}, { sceneDetails: Object.values(confirmedProducts) });
    return response
    // dispatch(actions.setSceneProductsLoading(false));
  } catch (errors: any) {
    dispatch(actions.setSceneProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
    return 'Fail!'
  }
};


/**
 * Product Correction
 * 
 * @DataStructure 4 - "correctedProducts"
 * 
 * @param irArticleId new product(article) irArticleId
 * @param masterDataArticleId new product(article) masterDataArticleId
 * @param sceneDetailId current product(article) id
 * @param comment
 * 
 */
export const collectCorrectedProduct = (products: ICorrectedProducts) => (dispatch: Dispatch) => {
  try {
    dispatch(actions.collectCorrectedProduct(products));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

export const removeCorrectedProduct = (products: ICorrectedProducts) => (dispatch: Dispatch) => {
  try {
    dispatch(actions.removeCorrectedProduct(products));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

export const setCorrectedProducts = (correctedProducts: ICorrectedProducts) => async (dispatch: Dispatch) => {
  dispatch(actions.setSceneProductsLoading(true));

  try {
    await post(`${modulePath}/SetSceneDetailCorrection`, {}, { corrections: Object.values(correctedProducts) });
  } catch (errors: any) {
    dispatch(actions.setSceneProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
  }
};

// Set Loading(
export const setLoading = (loading: boolean) => (dispatch: Dispatch) => {
  dispatch(actions.setLoading(loading));
};

// Set Error
export const setError = (error: IError) => (dispatch: Dispatch) => {
  dispatch(actions.setError(error));
};

// Scene
export const validateScene = (sceneStatusDetails: ISeneValidation) => async (dispatch: Dispatch) => {
  try {
    await post(`${modulePath}/SetAuditStatus`, {}, sceneStatusDetails );
    return true;
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
    return null;
  }
};

export const removeArtefact = (sceneHeaderId:string, isInvalid:boolean) => async (dispatch:Dispatch) => {
  try {
    // dispatch(actions.setSceneProductsLoading(true));
    await post (`${modulePath}/SetSceneDetailAsInvalid`, {}, {sceneHeaderId, isInvalid})
    // dispatch(actions.setSceneProductsLoading(false));
  } catch (errors:any) {
    // dispatch(actions.setSceneProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
    return null;
  }
}


//Get scene filters
export const getFilters = (sceneId:string) => async (dispatch: Dispatch) => {
  try {
    const editedBy:IFilter[] = await get(`${modulePath}/TaskEditedBy`);
    const brand:IFilter[] = await get(`${modulePath}/TaskBrands/${sceneId}`);
    const category:IFilter[] = await get(`${modulePath}/TaskCategories/${sceneId}`);

    const result:IFilterGroups = {
      editedBy: editedBy.map((val) => ({...val, active: false})),
      brand: brand.map((val) => ({...val, active: false})),
      category: category.map((val) => ({...val, active: false})),
    } 
    return result

  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, 'Error occurred!');
    dispatch(actions.setError({ status: errors?.status, message }));
    return { editedBy: [], brand: [], category: [] }
  }
}

//Get task list filters
export const getTasksListFilters = () => async (dispatch:Dispatch) => {
  try {

    const sceneTypes:IFilter[] = await get(`${modulePath}/sceneTypes/complexSearch`);
    const auditStatuses:IFilter[] = await get(`${modulePath}/auditStatuses/complexSearch`);
    const productChangedBy:IFilter[] = await get(`${modulePath}/productChangedBy/complexSearch`);
    const hideBySceneStatus:IFilter[] = await get(`${modulePath}/hideBySceneStatus/complexSearch`);

    dispatch(actions.setFilter(
      constructInitialFilters([
        ...(sceneTypes || []),
        ...(auditStatuses || []),
        ...(productChangedBy || []),
        ...(hideBySceneStatus || []),
      ])
    ));

  } catch (errors:any) {
    const message = getResponseErrorMessage(errors, "Error fetching filters occurred!");
    dispatch(actions.setError({ status: errors?.status, message }));
    return null;
  }
}
