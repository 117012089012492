import { Dispatch } from "redux";

import { planogramSlice } from "./planogramSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get, post, put, del } from "../../../../../api/requester";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { assetModelId, customerId, customerSegmentId, equipmentId, SCALE_FACTOR, SHELF_DEFAULT_ALIGNMENTS } from "../../constants";
import { IAssignment, IPlanogramBE, ISelect } from "../../_interfaces/state";
import { IProducts, ISection, IShelf, IUpdatePlanogram, IUpdatePlanogramShelf, IUpdatedProduct } from "../../_interfaces/builder";
import { createPlanogramImage, createSections, getScaleFactor, isItemUpdated, refineAssignment, refineProductForUpdate, resetPlanogramObject, scaleValueForBE } from "./actionUtils";
import { getErrorMessage } from "../../../../../redux/_helpers";

const { actions } = planogramSlice;

export const moduleUrl = "/api/planogram/planograms";

export const setValidationErrors = actions.setValidationErrors;

export const clearAll = () => defaultActions.clearAll(actions);
export const clearItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const catchError = (error:{errors:string, callType:string, errorProp:string}) => actions.catchError(error);
export const catchWarning = (error:string) => actions.setWarning(error);
export const clearWarning = () => actions.clearWarning();
export const setItem = (planogram:IPlanogramBE) => actions.setItem(planogram);
export const setPlanogramUpdated = (value:boolean) => actions.setPlanogramUpdated(value);

export const getPlanograms = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);
// export const getPlanogram = (id:string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, resetPlanogramObject)
export const getPlanogram = (id:string) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setLoadingPlanogramBuilder(true));
        dispatch(actions.setItem(null));
        const planogram = await get(`${moduleUrl}/${id}`);
        dispatch(setItem(resetPlanogramObject(planogram)));
        dispatch(actions.setPlanogramUpdated(false))
        dispatch(actions.setLoadingPlanogramBuilder(false));
    } catch (errors:any) {
        dispatch(actions.setLoadingPlanogramBuilder(false));
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const copyPlanograms = (ids:string[]) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setCopyLoading(true));
        const res = await post(`${moduleUrl}/Copy`, {}, {copyPlanograms: ids.map((id) => ({id}))});
        dispatch(actions.setCopyLoading(false));
        return res;
    } catch (errors:any) {
        dispatch(actions.setCopyLoading(false));
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//errors ? constructErrors(errors) : 'Something went wrong!',
            callType: "actionsLoading", errorProp: "error"
        }));
        return null;
    }
}

export const deletePlanograms = (ids:string[]) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setDeleteLoading(true))
        await del(`${moduleUrl}/Delete`, {}, { deletePlanograms: ids.map((id) => ({id})) });
        dispatch(actions.setDeleteLoading(false))
        return true;
    } catch (errors:any) {
        dispatch(actions.setDeleteLoading(false));
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//errors ? constructErrors(errors) : 'Something went wrong!',
            callType: "actionsLoading", errorProp: "error"
        }));
        return null;
    }
}

export const getPlanogramAssignmentMessage = (PlanogramId:string) => async (dispatch:Dispatch) => {
    try {
        const assignmentMessage = await get('/api/planogram/planogramassignment/AssignmentMessage', {PlanogramId});
        assignmentMessage && dispatch(actions.setAssignmentMessage(assignmentMessage))
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const getPlanogramAssignment = (PlanogramId:string) => async (dispatch:Dispatch) => {
    try {
        const res = await get(`/api/planogram/planogramAssignment/${PlanogramId}`);
        dispatch(setAssignment(refinePlanogramAssignment(res)));
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const deleteAssignment = (id:string) => async (dispatch:Dispatch) => {
    try {
        await del('/api/planogram/planogramassignment/Delete', {}, {id})
        dispatch(resetAssignment());
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const saveAssignment = (assignment:IAssignment, id:string) => async (dispatch:Dispatch) => {
    try {
        await post('/api/planogram/planogramassignment', {}, {
            ...refineAssignment(assignment),
            planogramId: id
        });
        return 'success';
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
        
        return 'fail';
    }
}

export const getFilters = () => async (dispatch:Dispatch) => {
    try {
        const assetModels:any[] = await get("/api/imagerecognition/scenetypes/complexSearch");
        const customerSegments:any[] = await get("api/appmasterdata/customer/segments/complexSearch");
        const assetModelTypes:any[] = await get("api/imagerecognition/subscenetypes/complexSearch");
        const planogramSearch:any[] = await get("/api/planogram/planograms/complexSearch");
        
        dispatch(actions.setFilter(
            constructInitialFilters([
                ...(planogramSearch || []),
                ...(assetModels || []),
                ...(customerSegments || []),
                ...(assetModelTypes || []),
            ])
        ));
    } catch (errors) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);

export const getProducts = () => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setProductsLoading(true))
        const products = await get(`${moduleUrl}/getPlanogramProducts`);
        dispatch(actions.setProducts(products.map((product:any) => ({
            ...product,
            dimensions: {
                width: product.width * SCALE_FACTOR,
                height: product.height * SCALE_FACTOR,
                depth: product.depth * SCALE_FACTOR,
            },
            articleCode: product.code,
            planogramSectionShelfId: -1,
            articleId: product.id,  
            articleName: product.name,
            order: -1,
            top: 0,
            down: 0,
            right: 0,
            left: 0,
        }))));
        dispatch(actions.setProductsLoading(false))
    } catch (errors:any) {
        dispatch(actions.setProductsLoading(false))
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    }
}

export const setSections = (sections:ISection[]) => actions.setSections(sections);
export const setPlanogramTitle = (title:string) => actions.setPlanogramTitle(title);
export const setDeletedSections = (id:string) => actions.setDeletedSections({ id });
export const setDeletedShelves = (id:string, sectionId:string) => actions.setDeletedShelves({ id, sectionId });
export const setDeleteProducts = (products:{id:string, sectionId:string, shelfId:string}[]) => actions.setDeleteProducts(products);
export const resetDeleteSections = () => actions.resetDeleteSections();
export const resetAssignment = () => actions.resetAssignment();
export const setAssignment = (assignment:IAssignment) => actions.setAssignment(assignment);
export const setIs3d = (is3d:boolean) => actions.setIs3d(is3d);


export const savePlanogram = (planogram:IPlanogramBE, assignment:IAssignment) => async (dispatch:Dispatch) => {
    try {
        // const asdf = await createPlanogramImage(planogram.title);

        // return null
        dispatch(actions.setPlanogramSaveLoading(true));
        
        const planogramWidth = planogram.sections.reduce((a, { dimensions }) => a + dimensions.width, 0)
        const file = await createPlanogramImage(planogram.title, planogramWidth);
        const planogramForSave = await refineDataForCreation(planogram, file, assignment);
        const response = await post(moduleUrl, {}, planogramForSave, {contentType: 'multipart/form-data;boundary="boundary"'});
        dispatch(actions.setPlanogramSaveLoading(false));
        return response;
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsSaving", errorProp: "error"
        }));
        dispatch(actions.setPlanogramSaveLoading(false));
        return null
    }
}

interface IUpdateSection extends ISection {
    sectionX:number;
}

export const updatePlanogram = (
    planogram:IPlanogramBE,
    deleteSections: [],
    deletedShelves: [],
    deletedProducts: []
) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setPlanogramSaveLoading(true));

        let sectionX = 0
        const sectionsWithX = planogram.sections.map((s, i) => {
            sectionX += planogram.sections[i - 1] ? planogram.sections[i - 1].dimensions.width : 0
            return { ...s, sectionX: sectionX}
        })

        const updateSections:IUpdateSection[] = sectionsWithX.filter(({ updated, original }) => updated || !original);
        let file = null;
        if (updateSections.length > 0 || deleteSections.length > 0) {
            const planogramWidth = planogram.sections.reduce((a, { dimensions }) => a + dimensions.width, 0)
            file = await createPlanogramImage(planogram.title, planogramWidth);
        }
        
        dispatch(actions.setLoadingPlanogramBuilder(true));
        const planogramForUpdate = await refineDataForUpdate(planogram, deleteSections, deletedShelves, deletedProducts, updateSections, file);
        await put(moduleUrl, {}, planogramForUpdate, {contentType: 'multipart/form-data;boundary="boundary"'});
        await dispatch(getPlanogram(planogram.id) as any);
        dispatch(resetDeleteSections());
        return true
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsSaving", errorProp: "error"
        }));
    } finally {
        dispatch(actions.setPlanogramSaveLoading(false));
        dispatch(actions.setLoadingPlanogramBuilder(false));
    }
}

const refinePlanogramAssignment = (assignment:any) => {
    const assignmentObject = {
        [assetModelId]: assignment
            ? assignment[assetModelId].map((e:any) => ({ id: e.assetModelId, deleteKey: e.id}))
            : [],
        [equipmentId]: assignment
            ? assignment[equipmentId].map((e:any) => ({ id: e.equipmentId, deleteKey: e.id}))
            : [],
        [customerSegmentId]: assignment
            ? assignment[customerSegmentId].map((e:any) => ({ id: e.customerSegmentId, deleteKey: e.id}))
            : [],
        [customerId]: assignment
            ? assignment[customerId].map((e:ISelect) => ({ id: e.id, deleteKey: e.id, name: e.name}))
            : [],
    }

    return assignmentObject;
}


const refineDataForCreation = async (planogram:IPlanogramBE, file:any, assignment:IAssignment) => {
    const formData = new FormData();

    const planogramWidth = planogram.sections.reduce((a, { dimensions }) => a + dimensions.width, 0)
    const scale = SCALE_FACTOR * getScaleFactor(planogramWidth)

    const form = {
        title: planogram.title,
        scale,
        dimensions: {
            width: scaleValueForBE(planogram.dimensions.width),
            depth: scaleValueForBE(planogram.dimensions.depth),
            height: scaleValueForBE(planogram.dimensions.height),
        },
        is3D: planogram.is3D,
        createSections: createSections(planogram.sections, scale),
        createPlanogramImage: {
            fileName: `${planogram.title.replaceAll(' ', '_')}_img.jpeg`
        },
        createPlanogramAssignment: refineAssignment(assignment),
    }

    formData.append('file', file);
    formData.append('command', JSON.stringify(form));
    return formData;
}

const refineDataForUpdate = async (
    planogram:IPlanogramBE,
    deleteSections: { id:string }[],
    deletedShelves: { id:string, sectionId:string }[],
    deleteProducts: { id:string, sectionId:string, shelfId:string }[],
    updateSections:IUpdateSection[],
    file:any,
) => {
    const formData = new FormData();
    const planogramWidth = planogram.sections.reduce((a, { dimensions }) => a + dimensions.width, 0)
    const scale = SCALE_FACTOR * getScaleFactor(planogramWidth)

    const form:IUpdatePlanogram = {
        id: planogram.id,
        title: planogram.title,
        is3D: planogram.is3D,
        scale,
        dimensions: {
            width: scaleValueForBE(planogram.dimensions.width),
            depth: scaleValueForBE(planogram.dimensions.depth),
            height: scaleValueForBE(planogram.dimensions.height),
        },
        sections: updateSections.map((section:IUpdateSection) => {
            let shelfY = section.thickness;
            return {
                ...(section.original ? {id: section.id} : {}),
                planogramId: planogram.id,
                order: section.order,
                color: section.color,
                dimensions: {
                    width: scaleValueForBE(section.innerDimensions.width),
                    depth: scaleValueForBE(section.innerDimensions.depth),
                    height: scaleValueForBE(section.innerDimensions.height),
                },
                thickness: section.thickness / SCALE_FACTOR,
                deleteShelves: deletedShelves.filter(({ sectionId }) => sectionId === section.id),
                shelves: section.shelves.reduce((a:IUpdatePlanogramShelf[], shelf:IShelf, sIndex:number) => {
                    let shelfX = section.sectionX + section.thickness;
                    if (sIndex > 0) shelfY += section.shelves[sIndex - 1].dimensions.height;
                    const {
                        products,
                        alignment,
                        dimensions,
                        thickness:shelfThickness
                    } = shelf;
                    const { width, height } = dimensions;
                    const down = shelfY + (height - shelfThickness);
                    if (alignment !== SHELF_DEFAULT_ALIGNMENTS[0]) {
                        const productsTotalWidth = products?.reduce((a, e) => a + e.dimensions.width + e.marginRight + e.marginLeft, 0) || 0;
                        if (alignment === SHELF_DEFAULT_ALIGNMENTS[1]) {
                            shelfX += (width - productsTotalWidth) / 2;
                        } else if (alignment === SHELF_DEFAULT_ALIGNMENTS[2]) {
                            shelfX += width - productsTotalWidth;
                        }
                    }

                    let productX:number = shelfX;
    
                    return [
                        ...a,
                        ...(isItemUpdated(shelf)
                            ? [{
                                ...(shelf.original ? {id: shelf.id} : {}),
                                ...(shelf.original ? {planogramSectionId: section.id || "" } : {}),
                                color: shelf.color,
                                alignment: shelf.alignment || SHELF_DEFAULT_ALIGNMENTS[0],
                                order: shelf.order,
                                dimensions: {
                                    width: scaleValueForBE(shelf.dimensions.width),
                                    depth: scaleValueForBE(shelf.dimensions.depth),
                                    height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
                                },
                                thickness: shelf.thickness / SCALE_FACTOR,
                                products: shelf.products?.reduce((allProducts:IUpdatedProduct[], p:IProducts, j:number) => {
                                    let prevProductSize = 0
                                    if (j > 0) {
                                        prevProductSize =
                                            shelf.products?.[j - 1].dimensions.width
                                                + shelf.products?.[j - 1].marginRight
                                    }
                                    productX += p.marginLeft + prevProductSize

                                    return [
                                        ...allProducts,
                                        ...refineProductForUpdate(p, shelf, down, productX, scale)
                                    ]
                                }, []),
                                deleteProducts: deleteProducts.filter(({sectionId, shelfId}) =>
                                    shelfId === shelf.id && section.id === sectionId)
                                    .map((p) => ({id: p.id})),
                            }]
                            : [])
                    ]
                }, [])
            }
        }),
        deleteSections,
        ...(updateSections.length > 0 || deleteSections.length > 0 ? {
            createPlanogramImage: {
                fileName: `${planogram.title.replaceAll(' ', '_')}_img.jpeg`
            }
        } : {}),
    }
    
    if (updateSections.length > 0 || deleteSections.length > 0) {
        formData.append('file', file);
    }
    formData.append('command', JSON.stringify(form));
    return formData;
}