import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "./pagination";
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, tableDefaultKeyColumn } from "../helpers";

export function BootstrapTableExtendedPaged({
  columns,
  entities = [],
  isLoading,
  remote = true,
  keyField = tableDefaultKeyColumn,
  paginationOptions,
  onTableChangeHandler,
  onRowClickHandler = undefined,
  selectRowHandler,
  rowClasses = undefined,
  customProps = {},
  withPagination = true
}) {
  return (
    <>
      {withPagination ? (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination isLoading={isLoading} paginationProps={paginationProps}>
                <BootstrapTableExtended
                  columns={columns}
                  entities={entities}
                  remote={remote}
                  keyField={keyField}
                  onTableChangeHandler={onTableChangeHandler}
                  selectRowHandler={selectRowHandler}
                  rowClasses={rowClasses}
                  tableProps={paginationTableProps}
                  isLoading={isLoading}
                  onRowClickHandler={onRowClickHandler}
                  customProps={customProps}
                />
              </Pagination>
            );
          }}
        </PaginationProvider>
        ) : (
          <BootstrapTableExtended
            columns={columns}
            entities={entities}
            remote={remote}
            keyField={keyField}
            onTableChangeHandler={onTableChangeHandler}
            selectRowHandler={selectRowHandler}
            rowClasses={rowClasses}
            tableProps={{}}
            isLoading={isLoading}
            onRowClickHandler={onRowClickHandler}
            customProps={customProps}
          />
        )
      }
    </>
  );
}

export function BootstrapTableExtended({
  columns,
  entities,
  remote = true,
  keyField = tableDefaultKeyColumn,
  onTableChangeHandler,
  onRowClickHandler,
  selectRowHandler,
  rowClasses,
  tableProps,
  isLoading = true,
  customProps = {}
}) {
  const noDataMessage = () => <h6 className="text-center text-muted mt-5">{isLoading ? "Loading..." : "There are no records"}</h6>

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      classes={`table table-head-custom table-vertical-center ${onRowClickHandler ? "extended-table-hover" : null}`}
      bootstrap4
      bordered={false}
      remote={remote}
      keyField={keyField}
      data={entities ? entities : []}
      columns={columns}
      sort={{ sortCaret: sortCaret }}
      onTableChange={onTableChangeHandler}
      selectRow={selectRowHandler}
      rowClasses={rowClasses}
      noDataIndication={noDataMessage}
      rowEvents={onRowClickHandler}
      {...tableProps}
      {...customProps}
    >
      <PleaseWaitMessage entities={entities} />
      <NoRecordsFoundMessage entities={entities} />
    </BootstrapTable>
  );
}
