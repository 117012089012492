import { assetModelTypesSlice } from "./assetModelTypesSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get } from "../../../../../api/requester";
import { getErrorMessage } from "../../../../../redux/_helpers"
import { Dispatch } from "redux";
import * as fields from "../../pages/AssetsData/tabs/types/edit/constants"

const { actions } = assetModelTypesSlice;
export const moduleUrl = "/api/assetmanagement/assetmodeltypes"

// Default actions currying
export const setValidationErrors = actions.setValidationErrors;

export const getAssetModelTypes = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const getAssetModelType = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const saveAssetModelType = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const deleteAssetModelType = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearAssetModelType = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

export const getVizualizationTypes = () => async (dispatch:Dispatch) => {
  dispatch(actions.startVisualizationTypesDropDownItemsCall());

  try {
    const res = await get(`/api/assetmanagement/assetmodeltypevisualizationtypes/select`);
    dispatch(actions.setVisualizationTypesDropDownItems(res ? res : []));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "subDropDownLoading", errorProp: "error" }));
  }
}

/**
 * Get related SubTypes to a certain Type by it`s ID
 */
export const getSubDropDownItems = (typeId: string, params: {}) => async (dispatch: Dispatch) => {
  dispatch(actions.startSubDropDownItemsCall());

  try {
    const res = await get(`${moduleUrl}/${typeId}/assetsubtype/select`, params);
    dispatch(actions.setSubDropDownItems(res ? res : []));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "subDropDownLoading", errorProp: "error" }));
  }
};

export const getAssetModelVizualizationType = (id: string) => async (dispatch:Dispatch) => {
  try {
    const res = await get(`${moduleUrl}/${id}`);
    const types = await get(`/api/assetmanagement/assetmodeltypevisualizationtypes/select`);
    const currentType = types.find(({ id }:{id:string}) => id === res[fields.ASSET_MODEL_VISUALIZATION])
    return currentType?.name || '';
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "subDropDownLoading", errorProp: "error" }));
  }
}

export const getFilters = () => defaultActions.getFilters(moduleUrl, actions);
export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);
