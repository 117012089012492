import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter<ComponentProps>(Component: React.FunctionComponent<ComponentProps>) {
    function ComponentWithRouterProps(props: ComponentProps) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProps;
}