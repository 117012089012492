import { articlePackSizesSlice } from "./articlePackSizesSlice";
import * as defaultActions from "../../../../../redux/_helpers";

export const QPARAMS_CHANGED = "articlePackSizes/QParamsChanged";
const { actions } = articlePackSizesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "ArticlePackSizes_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("ArticlePackSizes_Get", actions);
export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "ArticlePackSizes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("ArticlePackSizes_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("ArticlePackSizes_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "ArticlePackSizes_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "description", "isForeign"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("ArticlePackSizes_Put", actions);

export const clearError = () => defaultActions.clearError(actions);
