import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";

interface IState extends IDefaultState<any, any, any> {
    routesLoading: boolean;
    // routes: IArticlesDetailsList[];
  }
  
const initialState:IState = {
    ...defaultInitialState,
    routesLoading: false,
    // articles: [],
}

const reducers = {
  ...defaultReducers,
//   setArticles: (state:IState, action:PayloadAction<IArticlesDetailsList[]>) => {
//     state.articles = action.payload;
//     state.routesLoading = false;
//   },
  estRoutesLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.routesLoading = action.payload;
  },
}

export const routePlanningSlice = createSlice({
  name: "routePlanning",
  initialState,
  reducers,
});