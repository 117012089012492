// General Info TAB
export const GENERAL = 'general';
export const GENERAL_ID = "id";
export const GENERAL_EQUIPMENT_ID = "equipmentId";
export const GENERAL_MODEL_ID = "assetModelId"
export const GENERAL_SERIAL_NUMBER = "serialNumber"
export const GENERAL_BARCODE = "barcode"
export const GENERAL_EQUIPMENT_STATUS_ID = "equipmentStatusId"
export const GENERAL_ASSORTMENT_LIST = "equipmentAssortments"
export const GENERAL_NOTES = "notes"
export const FORMATTED_EQUIPMENT_INFO = "formattedEquipmentInfo";
export const FORMATTED_PARENT_EQUIPMENT_INFO = "formattedParentEquipmentInfo";
export const PARENT_ID = "parentId";
export const VISUALIZATION_TYPE_CODE = "visualizationTypeCode";
export const FORMATTED_PRIMARY_PLANOGRAM_INFO = 'formattedPrimaryPlanogramInfo'

// Model TAB
export const MODEL = 'model';
export const MODEL_CODE = "code";
export const MODEL_DESCRIPTION = "description";
export const MODEL_MODEL = "model"
export const MODEL_TYPE_ID = "assetModelTypeId"
export const MODEL_SUB_TYPE_ID = "assetModelSubTypeId"
export const MODEL_BRAND_ID = "assetModelBrandId"
export const MODEL_EXT_WIDTH = "exteriorWidth"
export const MODEL_EXT_DEPTH = "exteriorDepth"
export const MODEL_EXT_HEIGHT = "exteriorHeight"
export const MODEL_INT_WIDTH = "interiorWidth"
export const MODEL_INT_DEPTH = "interiorDepth"
export const MODEL_INT_HEIGHT = "interiorHeight"
export const MODEL_SHELF_WIDTH = "shelfWidth"
export const MODEL_SHELF_DEPTH = "shelfDepth"
export const MODEL_DOORS = "numberOfDoors"
export const MODEL_SHELVES = "numberOfShelves"
export const MODEL_POWER = "power"
export const MODEL_PRICE = "price"


// Planograms Tab
export const PRIMARY_PLANOGRAM = "primaryPlanogram";
export const PRIMARY_PLANOGRAM_OBJECT = "primaryPlanogramObject";
export const IS_SMART = 'isSmart';
export const SMART_EQUIPMENT_STATUS_ID = 'smartEquipmentStatusId';
export const PRIMARY_PLANOGRAM_FOR_UPDATE = 'primaryPlanogramEquipmentAssignmentId';


// ---------------------------------
export const TABS = [GENERAL, MODEL];
