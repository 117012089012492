/* eslint-disable no-restricted-imports */
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../app/common/_customHooks/redux";
import CustomModal from "../../../../app/components/common/CustomModal";
import SvgIcon from "../../../../app/components/common/SvgIcon";
import { useParams } from "react-router-dom";

interface IProps {
  id?: any;
  show: boolean;
  onHide: any;
  modelName: string;
  deleteItemAction: any;
  deleteCallback?: any;
  hasAccess?: boolean;
  errorAction?: any;
  loadingSelector?: any;
}

export const DeleteItemDialog: React.FC<IProps> = ({
  show,
  onHide,
  modelName,
  deleteItemAction,
  deleteCallback,
  hasAccess,
  errorAction,
  loadingSelector,
}) => {
  const { id = '' } = useParams();
  const [deleteAction, setDeleteAction] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const error: any = useSelector(errorAction ? errorAction : state => null);
  const itemLoading: any = useSelector(loadingSelector ? loadingSelector : state => null);

  const deleteItem = () => {
    // server request for deleting item by id
    setDeleteAction(false);
    dispatch(deleteItemAction(id)).then((result: any) => {
      // closing delete modal
      setResult(result);
      setDeleteAction(true);
    });
  };

  useEffect(() => {
    if (error) setShowError(true);
    if (deleteAction && !error) {
      onHide();
      if (deleteCallback) deleteCallback(result);
    }
  }, [deleteAction]);

  return (
    <>
      <Modal show={show && hasAccess} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title id="delete-dialog" data-testid="modal-title">{`Archive ${modelName}`}</Modal.Title>
          <div
            data-testid="close-delete-modal"
            className="btn btn-icon btn-sm btn-hover-secondary ml-auto"
            onClick={onHide}
          >
            <SvgIcon
              iconLocation="/media/svg/icons/Navigation/Close-2.svg"
              size="4"
              bootstrapColor="grey"
              title="Close"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {error && <ErrorModal error={error} showError={showError} setShowError={setShowError} />}
          <span>{`Are you sure you want to archive this ${modelName}?`}</span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              data-testid="delete-item"
              type="button"
              onClick={deleteItem}
              className={`btn btn-warning m-0 ml-2 d-flex justify-content-center align-items-center 
                  ${itemLoading ? "pointer-events-none" : ""}`}
              disabled={itemLoading}
              style={{ height: "3rem", minWidth: "7rem" }}
            >
              {itemLoading ? (
                <>
                  <div className="spinner spinner-white" style={{ margin: "0 0.5rem 0 -0.3rem", width: "1.5rem" }} />
                  <>Processing</>
                </>
              ) : (
                <>Archive</>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface IErrorProps {
  error: any;
  title?: string;
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ErrorModal: React.FC<IErrorProps> = ({ error, title, showError, setShowError }) => {
  return (
    <CustomModal
      showModal={showError}
      customStyles={{ zIndex: "1060" }}
      title={title ? title : "Error"}
      body={
        <>
          {Array.isArray(error) ? (
            error.map((errorMessage, index) => (
              <p key={"message" + index}>
                <span className="text-danger">{errorMessage}</span>
              </p>
            ))
          ) : (
            <span className="text-danger">{error}</span>
          )}
        </>
      }
      buttons={[{ variant: "secondary", onClick: () => setShowError(false), text: "Close" }]}
    />
  );
};
