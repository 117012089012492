import { getDefaultSlice } from "../../../../../redux/_helpers";

const customReducers = {
  deletePromotionArticle: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.articles = state.itemForEdit.articles.filter(item => item.id !== action.payload);
  },
  deletePromotionCustomer: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.customers = state.itemForEdit.customers.filter(item => item.id !== action.payload);
  },
  addPromotionArticle: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    const articles = state.itemForEdit.articles;
    for (const article of action.payload) {
      articles.push(article);
    }
    state.itemForEdit.articles = articles;
  },
  addPromotionCustomer: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    const customers = state.itemForEdit.customers;
    for (const customer of action.payload) {
      customers.push(customer);
    }
    state.itemForEdit.customers = customers;
  },
};

const initiaState = {
  articles: [],
  articlesLoading: false,
  customers: [],
  customersLoading: false,
  itemForEdit: {},
};

export const promotionsSlice = getDefaultSlice("promotions", customReducers, initiaState);
