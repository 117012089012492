import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { IState } from "../../_interfaces/reduxState";
import { createSlice } from "@reduxjs/toolkit";

const initialState: IState = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const assetModelBrandsSlice = createSlice({
  name: "assetModelBrands",
  initialState,
  reducers,
});
