import { taskTypesStatusesSlice } from "./taskTypesStatusesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

const { actions } = taskTypesStatusesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "TaskStatuses_Get",
  actions,
  constants.QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("TaskStatuses_Get", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("TaskStatuses_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("TaskStatuses_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger("TaskStatuses_Post", actions);

export const updateItemSwagger = defaultActions.updateItemSwagger("TaskStatuses_Put", actions);

export const clear = () => defaultActions.clear(actions);
export const clearErrors = () => defaultActions.clearError(actions);

