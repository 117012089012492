import { rejectionReasonsSlice } from "./rejectionReasonsSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as fields from "../../pages/RejectionReasonsData/edit/constants";

const { actions } = rejectionReasonsSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

// CRUD
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "RejectionReasons_Get",
  actions,
  "RejectionReasons/QParamsChanged",
);

export const createItemSwagger = defaultActions.createItemSwagger(
  "RejectionReasons_Post",
  actions,
  defaultActions.convertCreateItemParams([
    fields.REJECTION_REASON_CODE,
    fields.REJECTION_REASON_NAME,
    fields.REJECTION_REASON_LOCAL_NAME,
    fields.REJECTION_REASON_ORDER,
    fields.REJECTION_REASON_IS_ACTIVE,
  ]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("RejectionReasons_Put", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("RejectionReasons_Delete", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("RejectionReasons_GetById", actions);

// Select
export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "RejectionReasons_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

//Reset state
export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
