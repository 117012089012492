import { rolesSlice } from "./rolesSlice";
import * as defaultActions from "../../../../../redux/_common";
import { Dispatch } from "redux";
import { get } from "../../../../../api/requester";
import { IPermissions } from "../../_interfaces/reduxState";
import { getErrorMessage } from "../../../../../redux/_helpers";

const { actions } = rolesSlice;
export const moduleUrl = "/api/Authorization/Roles"

// Default actions currying
export const setValidationErrors = actions.setValidationErrors;

export const getRoles = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const getRole = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const saveRole = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const deleteRole = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearRole = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

// Custom Actions

// Admin role - menus for the checkbox tree
export const getAccess = () => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "menuItemsLoading" }));
  try {
    const res = await get(`${moduleUrl}/MenuItems`);
    dispatch(actions.setAccess(res));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "menuItemsLoading", errorProp: "errorAction" }));
  }
};
// Mobile role - features for payment plan
export const getFeatures = () => async (dispatch: Dispatch) => {
  dispatch(actions.startItemCall({ callType: "actionsLoading" }));
  try {
    const res = await get(`${moduleUrl}/Features`);
    dispatch(actions.setFeatures(res));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};

export const setPermissions = (permissions: IPermissions[], type: "admin" | "mobile") => (dispatch: Dispatch) => {
  if (type === "admin") {
    dispatch(actions.setPermissions(permissions));
  } else {
    dispatch(actions.setMobilePermissions(permissions));
  }
};

export const setIsMobile = (state: string) => (dispatch: Dispatch) => {
  dispatch(actions.setIsMobile(state));
};
