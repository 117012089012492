import { RootState } from "../../../../../redux/store";

export const assetModelTypesLoading = (state: RootState) => state.assetModelTypes.itemsLoading;
export const assetModelTypeActionLoading = (state: RootState) => state.assetModelTypes.actionsLoading;
export const dropDownLoading = (state: RootState) => state.assetModelTypes.dropDownLoading;

export const assetModelTypes = (state: RootState) => state.assetModelTypes.items;
export const assetModelTypesCount = (state: RootState) => state.assetModelTypes.itemsCount;
export const assetModelTypeForEdit = (state: RootState) => state.assetModelTypes.itemForEdit;
export const dropDownValues = (state: RootState) => state.assetModelTypes.dropDownItems;

export const error = (state: RootState) => state.assetModelTypes.error;
export const errorAction = (state: RootState) => state.assetModelTypes.errorAction;
export const dropDownError = (state: RootState) => state.assetModelTypes.dropDownError;


export const subDropDownLoading = (state: RootState) => state.assetModelTypes.subDropDownLoading;
export const subDropDownValues = (state: RootState) => state.assetModelTypes.subDropDownItems;
export const subDropDownError = (state: RootState) => state.assetModelTypes.subDropDownError;


export const visualizationTypesDropDownLoading = (state: RootState) => state.assetModelTypes.visualizationTypesDropDownLoading;
export const visualizationTypesDropDownValues = (state: RootState) => state.assetModelTypes.visualizationTypesDropDownItems;
export const visualizationTypesDropDownError = (state: RootState) => state.assetModelTypes.visualizationTypesDropDownError;



export const validationErrors = (state: RootState) => state.assetModelTypes.validationErrors;

export const filters = (state: RootState) => state.assetModelTypes.filters;