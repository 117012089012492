import axios from "axios";
import { authProvider } from "../app/auth/authProvider";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../app/user-access/organizationUnitStorage";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_PUBLIC_URL}/`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async config => {
    const token = await getCurrentAccessToken();
    config.headers.Authorization = `Bearer ${token.accessToken}`;
    const userOrgUnit = getCurrentUserOrganizationUnit();

    if (userOrgUnit) config.headers.OrganizationUnit = getCurrentUserOrganizationUnit();
    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => response,
  error => {
    console.log("Responce Error: ", error);

    if (error.message && error.message.indexOf("timeout") !== -1)
      return new Promise(() => console.log("request timeout error"));

    const { status } = error.response;

    if (status === 401) {
      authProvider.logoutRedirect();
    }

    return Promise.reject(error);
  },
);

export default instance;
