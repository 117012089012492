import { RootState } from "../../../../../redux/store";

export const assetsLoading = (state: RootState) => state.equipment.itemsLoading;
export const assetsActionLoading = (state: RootState) => state.equipment.actionsLoading;
export const dropDownLoading = (state: RootState) => state.equipment.dropDownLoading;

export const assets = (state: RootState) => state.equipment.items;
export const assetsCount = (state: RootState) => state.equipment.itemsCount;
export const assetForEdit = (state: RootState) => state.equipment.itemForEdit;
export const getItemsSelect = (state: RootState) => state.equipment.dropDownItems;

export const error = (state: RootState) => state.equipment.error;
export const warning = (state: RootState) => state.planogram.warning;
export const errorAction = (state: RootState) => state.equipment.errorAction;
export const dropDownError = (state: RootState) => state.equipment.dropDownError;
export const validationErrors = (state: RootState) => state.equipment.validationErrors;

export const equipmentForTasks = (state:RootState) => state.equipment.equipmentForTasks;
export const equipmentForTasksLoading = (state:RootState) => state.equipment.equipmentForTasksLoading;

export const planogramsForEquipment = (state:RootState) => state.equipment.planogramsForEquipment;
export const planogramsForEquipmentLoading = (state:RootState) => state.equipment.planogramsForEquipmentLoading;

export const filters = (state: RootState) => state.equipment.filters;

// DIAGRAM
export const equipmentDiagram = (state:RootState) => state.equipment.equipmentDiagram;
export const equipmentDiagramLoading = (state:RootState) => state.equipment.equipmentDiagramLoading;
export const diagramUpdated = (state:RootState) => state.equipment.diagramUpdated;

export const deletedSections = (state:RootState) => state.equipment.deletedSections;
export const deletedShelves = (state:RootState) => state.equipment.deletedShelves;
export const deleteEquipment = (state:RootState) => state.equipment.deleteEquipment;

export const diagramSaveLoading = (state:RootState) => state.equipment.saveLoading;

export const equipmentsForDiagram = (state:RootState) => state.equipment.equipmentsForDiagram;
export const productsLoading = (state:RootState) => state.equipment.equipmentsForDiagramLoading;
