import React from "react";
import { StatusCssClasses, StatusTitles } from "../helpers"

export const StatusColumnFormatter = (cellValue:string) => {
  const css = StatusCssClasses[+cellValue] || StatusCssClasses[StatusCssClasses.indexOf(cellValue.toLowerCase())];
  const label = StatusTitles[+cellValue] || StatusTitles[StatusCssClasses.indexOf(cellValue.toLowerCase())];

  return (
    <span className={`label label-lg label-light-${css} label-inline`}>
      {label}
    </span>
  );
};
