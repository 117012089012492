import instance from "./api";

export default class communication {
  constructor(url) {
    this.url = url;
  }

  find(parentId = "", config = {}) {
    const getUrl = parentId ? `${this.url}/${parentId}` : this.url;
    return instance.get(getUrl, config);
  }

  findByFilter(params, config = {}) {
    return instance.get(`${this.url}`, { ...config, params: params });
  }

  findById(id, config = {}) {
    return instance.get(`${this.url}/${id}`, config);
  }

  create(obj, config = {}) {
    return instance.post(this.url, obj, config);
  }

  update(id, obj, config = {}) {
    return instance.put(`${this.url}/${id}`, obj, config);
  }

  delete(id, config = {}) {
    return instance.delete(`${this.url}/${id}`, config);
  }

}
