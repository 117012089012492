import { RootState } from "../../../../../redux/store";

export const malfunctionsLoading = (state: RootState) => state.malfunctions.itemsLoading;
export const equipmentStatusActionLoading = (state: RootState) => state.malfunctions.actionsLoading;
export const dropDownLoading = (state: RootState) => state.malfunctions.dropDownLoading;

export const malfunctions = (state: RootState) => state.malfunctions.items;
export const malfunctionsCount = (state: RootState) => state.malfunctions.itemsCount;
export const equipmentStatusForEdit = (state: RootState) => state.malfunctions.itemForEdit;
export const dropDownValues = (state: RootState) => state.malfunctions.dropDownItems;

export const error = (state: RootState) => state.malfunctions.error;
export const errorAction = (state: RootState) => state.malfunctions.errorAction;
export const dropDownError = (state: RootState) => state.malfunctions.dropDownError;

export const validationErrors = (state: RootState) => state.malfunctions.validationErrors;