import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../redux/_common";
import { IArticle, ICustomer, IOrderType, IWholesaler } from "./_interfaces";

interface IState extends IDefaultState<any, any, any> {
  orderTypesLoading: boolean;
  orderTypes: IOrderType[];

  customersLoading:boolean;
  customers: ICustomer[];

  wholesalers: IWholesaler[];
  wholesalersLoading: boolean;

  articles: any[];
  articlesLoading: boolean;

  orderItemsLoading:boolean;
  prevCartItems:IArticle[];

  selectedCustomerId:string;
}

const initialState: IState = {
    ...defaultInitialState,
    articles: [],
    articlesLoading: false,

    customersLoading: false,
    customers: [],

    wholesalers: [],
    wholesalersLoading: false,

    orderTypesLoading: false,
    orderTypes: [],

    orderItemsLoading: false,
    prevCartItems: [],

    selectedCustomerId: '',
  };
  
const reducers = {
  ...defaultReducers,
  setSelectedCustomerID: (state:IState, action:PayloadAction<string>) => {
    state.selectedCustomerId = action.payload;
  },
  setPrevCartItems: (state:IState, action:PayloadAction<IArticle[]>) => {
    state.orderItemsLoading = false;
    state.prevCartItems = action.payload;
  },
  setOrderItemsLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.orderItemsLoading = action.payload;
  },
  setOrderItems: (state:IState, action:PayloadAction<IArticle[]>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      orderItems: action.payload
    };
    state.orderItemsLoading = false;
  },
  setArticles: (state:IState, action:PayloadAction<any[]>) => {
    state.articles = action.payload;
    state.articlesLoading = false;
  },
  setArticlesLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.articlesLoading = action.payload;
  },

  setOrderTypes: (state:IState, action:PayloadAction<IOrderType[]>) => {
    state.orderTypes = action.payload;
    state.orderTypesLoading = false;
  },
  setOrderTypesLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.orderTypesLoading = action.payload;
  },

  setWholesalers: (state:IState, action:PayloadAction<IWholesaler[]>) => {
    state.wholesalers = action.payload;
    state.wholesalersLoading = false;
  },
  setWholesalersLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.wholesalersLoading = action.payload;
  },

  setCustomers: (state:IState, action:PayloadAction<ICustomer[]>) => {
    state.customers = action.payload;
    state.customersLoading = false;
  },
  setCustomersLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.customersLoading = action.payload;
  }
};

export const webShopSlice = createSlice({
  name: "webShop",
  initialState,
  reducers,
})
//getDefaultSlice("webShop", customReducers, initiaState);
