import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { default as RDatePicker } from "react-datepicker";
import { Error } from "./Error";
import { getFieldCSSClasses } from "./_helper";
import { useFormFieldsContext } from "./FormFieldsContext";
import RequiredFieldMarker from "./RequiredFieldMarker";

export function DatePicker({
  name = "",
  label = "",
  disabled = false,
  onChangeCalback = null,
  withErrorFeedback = true,
  customClass = null,
  customStyle = null,
  onInit = null,
  dateFormat = "dd/MM/yyyy",
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeFormat = "HH:mm",
  timeIntervals = "60",
  timeCaption = "Time",
  isRequired = false,
  ...props
}) {
  const { initialValues, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const onChange = val => {
    setFieldTouched(name, true);
    setFieldValue(name, val);
    if (onChangeCalback) {
      onChangeCalback(val);
    }
  };

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          {label && (
            <label htmlFor={name}>
              {label}
              <RequiredFieldMarker name={name} isRequired={isRequired} />
            </label>
          )}

          <RDatePicker
            id={name}
            className={getFieldCSSClasses(touched, errors, name, customClass)}
            style={customStyle}
            onBlur={field.onBlur}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={onChange}
            disabled={disabled || (isDisabled && isDisabled(name))}
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            autoComplete="off"
          />
          {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
        </>
      )}
    </>
  );
}
