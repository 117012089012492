import { assetModelSubTypesSlice } from "./assetModelSubTypesSlice";
import * as defaultActions from "../../../../../redux/_common";

const { actions } = assetModelSubTypesSlice;
export const moduleUrl = "/api/assetmanagement/assetmodelsubtypes"

// Default actions currying
export const setValidationErrors = actions.setValidationErrors;

export const getAssetModelSubTypes = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const getAssetModelSubType = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const saveAssetModelSubType = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const deleteAssetModelSubType = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearAssetModelSubType = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
