import promotionTypesCrud from "./promotionTypesCrud";
import { promotionTypesSlice } from "./promotionTypesSlice";
import * as defaultActions from "../../../../../redux/_helpers";

export const QPARAMS_CHANGED = "promotionTypes/QParamsChanged";
const { actions } = promotionTypesSlice;
const crudInstance = new promotionTypesCrud();

// export const fetchItemsWithDelay = defaultActions.fetchItemsWithDelay(crudInstance, actions, QPARAMS_CHANGED);

// export const fetchItems = defaultActions.fetchItems(crudInstance, actions, QPARAMS_CHANGED);

// export const fetchItem = defaultActions.fetchItem(crudInstance, actions);

// export const deleteItem = defaultActions.deleteItem(crudInstance, actions);

// export const createItem = defaultActions.createItem(crudInstance, actions);

// export const updateItem = defaultActions.updateItem(crudInstance, actions);

export const clear = () => defaultActions.clear(actions);
export const clearError = () => defaultActions.clearError(actions);
