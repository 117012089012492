import { countriesSlice } from "./countriesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

export const QPARAMS_CHANGED = "countries/QParamsChanged";
const { actions } = countriesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Countries_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Countries_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "Countries_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Countries_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Countries_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "Countries_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "name", "nameLocal"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("Countries_Put", actions);

export const fetchContryRegionsSwagger = defaultActions.fetchCustomItemsSwagger(
  "Countries_GetCountryRegions",
  actions,
  constants.COUNTRY_REGIONS,
  constants.COUNTRY_REGIONS_LOADING,
  constants.COUNTRY_REGIONS_COUNT,
);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
