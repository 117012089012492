import { useEffect, useState, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../app/common/_customHooks/redux";
import { BootstrapTableExtendedPaged } from "./BootstrapTableExtended";
import { handlerTableChange, tableDefaultKeyColumn, tableDefaultSizePerPageList } from "../helpers";
import { Spinner } from "react-bootstrap";
import { union } from "lodash";

interface IProps {
  columns: any;
  fetchItemsAction?: any;
  getItemsSelector: any;
  getItemsCountSelector: any;
  isItemsLoadingSelector: any;
  queryParams: any;
  useDefaultRowSelection?:boolean;
  setQueryParams?: any;
  keyField?: any;
  sizePerPageList?: any;
  onRowClickHandler?: any;
  [key:string]: any;
  customProps?: any;
}

export const ItemsTable: React.FC<IProps> = ({
  columns,
  fetchItemsAction,
  getItemsSelector,
  getItemsCountSelector,
  isItemsLoadingSelector,
  queryParams,
  setQueryParams,
  keyField = tableDefaultKeyColumn,
  sizePerPageList = tableDefaultSizePerPageList,
  onRowClickHandler,
  selectRowHandler,
  useDefaultRowSelection = false,
  customProps = {},
  ...props
}) => {
  // Getting current state from store (Redux)
  let entities = useSelector(state => getItemsSelector(state), shallowEqual);
  if (props.entitiesFilter) {
    entities = props.entitiesFilter(entities);
  }
  const totalCount = useSelector(state => getItemsCountSelector(state), shallowEqual);

  const listLoading = useSelector(isItemsLoadingSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if(props.preventReload && entities.length) return;

    if (fetchItemsAction) {
      if(props.data) {
        dispatch(fetchItemsAction(queryParams, props.data));
      } else {
        dispatch(fetchItemsAction(queryParams));
      }
    }
  }, [queryParams, props?.data?.id, dispatch]);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.Size,
    Page: queryParams.Page,
  };

  
  // Rows selection functionality
  const [ noSelectableRows, setNonSelectableRows ] = useState<string[]>([]);
  const [ selectedRows, setSelectedRows ] = useState<any[]>([]);
  const headrCheckbox = useRef<HTMLInputElement>(null);

  useEffect(() => {
    props.nonSelectableFilter && setNonSelectableRows(entities.reduce(props.nonSelectableFilter, []));
  }, [entities]);

  useEffect(() => {
    setSelectedRows(props.selectedRows || [])
  }, [props.selectedRows]);

  useEffect(() => {
    const rowIds = entities.map((item:any) => item[keyField]);
    const selectableRows = rowIds.filter((id:string) => !noSelectableRows.includes(id));
    const selectedRowsFromCurrentPage = rowIds.filter((id:string) => selectedRows.includes(id))

    if (headrCheckbox && headrCheckbox.current) {
      if (noSelectableRows.length === rowIds.length) {
        headrCheckbox.current.indeterminate = false;
        headrCheckbox.current.checked = false;
      }

      if (selectedRowsFromCurrentPage.length > 0) {
        if (selectedRowsFromCurrentPage.length !== selectableRows.length && !headrCheckbox.current.indeterminate) {
          headrCheckbox.current.indeterminate = true;
          headrCheckbox.current.checked = false;
        } else if (selectedRowsFromCurrentPage.length === selectableRows.length) {
          headrCheckbox.current.indeterminate = false;
          headrCheckbox.current.checked = true;
        }
      }
    }
  }, [selectedRows, noSelectableRows]);

  const onSelect = (row:any, isSelect:boolean, rowIndex:number, e:any) => {
    e.stopPropagation();
    e.preventDefault();
    if (isSelect) {
      const newRows = [...selectedRows, row[keyField]];
      props.setSelectedRows && props.setSelectedRows(newRows);
      setSelectedRows(newRows);
    } else {
      const newRows = selectedRows.filter((x:any) => x !== row[keyField]);
      props.setSelectedRows && props.setSelectedRows(newRows);
      setSelectedRows(newRows);
    }
  }

  const onSelectAll = (isSelect:boolean, rows:any[]) => {
    if (isSelect) {
      const newRows = rows.map((row:any) => row[keyField]);
      props.setSelectedRows && props.setSelectedRows(union(newRows, selectedRows));
      setSelectedRows(union(newRows, selectedRows));
    } else {
      const rowIds = rows.map((item:any) => item[keyField]);
      props.setSelectedRows && props.setSelectedRows(selectedRows.filter((id) => !rowIds.includes(id)));
      setSelectedRows(selectedRows.filter((id) => !rowIds.includes(id)));
    }
  }

  // Default row selection properties
  const selectRows = {
    mode: "checkbox",
    clickToSelect: false,
    headerColumnStyle: { width: '2%' },
    classes: 'selected-row',
    nonSelectable: noSelectableRows,
    selected: selectedRows,
    selectionRenderer: (row:any) => {
      return (
        <label
          className={`checkbox checkbox-lg checkbox-single`}
          style={{
            opacity: row.disabled ? .5 : 1,
            cursor: row.disabled ? 'default' : 'pointer'
          }}
        >
          <input
            type={row.mode}
            defaultChecked={selectedRows.some((item:any) => item.index === row.rowIndex)}
            {...row}
          />
          <span />
        </label>
      );
    },
    selectionHeaderRenderer: (headerRow:any) => {
      const disabled = noSelectableRows.length === entities.length;
      return (
        <label
          className={`checkbox checkbox-lg checkbox-single`}
          style={{
            opacity: disabled ? .5 : 1,
            cursor: disabled ? 'default' : 'pointer'
          }}
        >
          <input
            disabled={disabled}
            ref={headrCheckbox}
            id="header_chekcbox"
            type={headerRow.mode}
          />
          <span style={ disabled ? { pointerEvents: 'none' } : {} }/>
        </label>
      );
    },
    onSelect,
    onSelectAll,
  }


  return (
    <span style={{position: 'relative'}}>
      {(listLoading && props.spinner) && <ModalLoadingSpinner />}
      <BootstrapTableExtendedPaged
        remote
        customProps={customProps}
        columns={columns}
        entities={entities}
        isLoading={listLoading}
        keyField={keyField}
        rowClasses={props.rowClasses}
        paginationOptions={paginationOptions}
        onRowClickHandler={onRowClickHandler}
        onTableChangeHandler={handlerTableChange(setQueryParams)}
        selectRowHandler={props.selectRowHandler || (useDefaultRowSelection ? selectRows : undefined)}
      />
    </span>
  );
};


const ModalLoadingSpinner: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-absolute h-100 w-100"
      style={{ zIndex: 1, backgroundColor: "rgba(255,255,255,0.5)" }}
      data-testid="modal-loading-spinner"
    >
      <Spinner style={{ height: "3rem", width: "3rem" }} animation="border" variant="primary" />
    </div>
  );
};
