import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntities, IEditItem, ITaskEquipment, IEquipmentPlanogram, IDiagram, IDiagramSection, IDiagramEqpmentListItem } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, IEditItem> {
  equipmentForTasks: ITaskEquipment[];
  equipmentForTasksLoading: boolean;

  planogramsForEquipment: IEquipmentPlanogram[];
  planogramsForEquipmentLoading:boolean;

  warning: string | null;
  equipmentDiagram:IDiagram | null;
  equipmentDiagramLoading: boolean;
  diagramUpdated: boolean;
  deletedSections: { id:string }[];
  deletedShelves: { id:string, sectionId:string }[];
  deleteEquipment: { id:string, sectionId:string, shelfId:string }[];
  saveLoading: boolean;
  equipmentsForDiagram: IDiagramEqpmentListItem[];
  equipmentsForDiagramLoading: boolean;
}

const initialState: IState = {
  ...defaultInitialState,
  equipmentForTasks: [],
  equipmentForTasksLoading: false,

  planogramsForEquipment: [],
  planogramsForEquipmentLoading: false,

  //Diagram
  warning: null,
  equipmentDiagram: null,
  equipmentDiagramLoading:false,
  diagramUpdated: false,
  deletedSections: [],
  deletedShelves: [],
  deleteEquipment: [],
  saveLoading: false,
  equipmentsForDiagram: [],
  equipmentsForDiagramLoading: false,
};

const reducers = {
  ...defaultReducers,
  setplanogramsForEquipment: (state:IState, action:PayloadAction<IEquipmentPlanogram[]>) => {
    state.planogramsForEquipment = action.payload;
  },
  setplanogramsForEquipmentLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.planogramsForEquipmentLoading = action.payload;
  },


  setEquipmentForTasks: (state:IState, action:PayloadAction<ITaskEquipment[]>) => {
    state.equipmentForTasks = action.payload;
  },
  setEquipmentForTasksLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.equipmentForTasksLoading = action.payload;
  },






  // Diagram
  setWarning: (state:IState, action:PayloadAction<string>) => {
      state.warning = action.payload
  },
  clearWarning: (state:IState, action:PayloadAction) => {
      state.warning = initialState.warning;
  },
  setEquipmentDiagram: (state:IState, action:PayloadAction<IDiagram>) => {
    state.equipmentDiagram = action.payload;
  },
  setEquipmentDiagramLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.equipmentDiagramLoading = action.payload;
  },
  setDeletedSections: (state:IState, action:PayloadAction<{id:string}>) => {
      if (!state.deletedSections.some(({id}) => id === action.payload.id)) {
          state.deletedSections = [...state.deletedSections, action.payload];
          state.diagramUpdated = true;
      }
  },
  setDeletedShelves: (state:IState, action:PayloadAction<{id:string, sectionId:string}>) => {
      if (!state.deletedShelves.some(({id}) => id === action.payload.id)) {
          state.deletedShelves = [...state.deletedShelves, action.payload];
          state.diagramUpdated = true;
      }
  },
  setDeleteProducts: (state:IState, action:PayloadAction<{id:string, sectionId:string, shelfId:string}[]>) => {
      state.deleteEquipment = [...state.deleteEquipment, ...action.payload];
      state.diagramUpdated = true;
  },
  resetDeleteSections: (state:IState, action:PayloadAction) => {
      state.deletedSections = initialState.deletedSections;
      state.deletedShelves = initialState.deletedShelves;
      state.deleteEquipment = initialState.deleteEquipment;
  },
  setSections: (state:IState, action:PayloadAction<IDiagramSection[]>) => {
    if (state.equipmentDiagram) {
      state.diagramUpdated = true;
      state.equipmentDiagram = {
          ...state.equipmentDiagram,
          dimensions: {
              width: action.payload.reduce((a, { dimensions }) => a + dimensions.width, 0),
              depth: Math.max(...action.payload.map(({dimensions}) => dimensions.depth)),
              height: Math.max(...action.payload.map(({dimensions}) => dimensions.height))
          },
          sections: action.payload
      }
    }
  },
  setDiagramSaveLoading: (state:IState, action:PayloadAction<boolean>) => {
      state.saveLoading = action.payload;
  },
  setEquipmentsForDiagram: (state:IState, action:PayloadAction<IDiagramEqpmentListItem[]>) => {
    state.equipmentsForDiagram = action.payload;
  },
  setEquipmentsForDiagramLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.equipmentsForDiagramLoading = action.payload;
  }
};

export const equipmentSlice = createSlice({
  name: "Equipment",
  initialState,
  reducers,
});
