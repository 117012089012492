/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { selectors } from "../../../../../app/user-access/_redux";
import { setCurrentUserOrganizationUnit } from "../../../../../app/user-access/organizationUnitStorage";
import { useUserOrganizationUnitHook } from "../../../../../app/user-access/useUserOrganizationUnitHook";
import SvgIcon from "../../../../../app/components/common/SvgIcon";

export function OrganizationUnitsDropdown() {
  const [userOrganizationUnit] = useUserOrganizationUnitHook();
  const orgUnits = useSelector(state => {
    return selectors.getOrganizationUnits(state);
  }, shallowEqual);

  const [show, setShow] = useState(false);

  return (
    <Dropdown
      show={show}
      align="start"
      onClick={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <div className="topbar-item">
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-1">
            <>
              <SvgIcon
                iconLocation="/media/svg/icons/Devices/Server.svg"
                size="4"
                customClasses="mr-2"
                title="Database"
                bootstrapColor="primary"
              />
              <span className="symbol symbol-35 symbol-light-light">
                <span className="symbol-label fw-400 w-100 p-3 text-primary">
                  {userOrganizationUnit?.name}
                </span>
              </span>
            </>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        style={{ marginTop: "-1rem" }}
      >
        <ul className="navi navi-hover py-4">
          <li className="px-6 pointer-events-none text-muted">Database</li>
          <div className="dropdown-divider"/>
          {orgUnits.map(orgUnit => (
            <li
              key={orgUnit.id}
              className={clsx("navi-item", {
                active: orgUnit.id === userOrganizationUnit?.id,
              })}
            >
              <a
                href="#"
                onClick={() => {
                  setCurrentUserOrganizationUnit(orgUnit.id);
                  setTimeout(() => window.location.reload(), 400);
                }}
                className="navi-link"
              >
                <span className="navi-text">{orgUnit.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
