import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SvgIcon from "../SvgIcon";
import { useImageArrowNavigation } from "../../../common/_customHooks/useImageArrowNavigation";
import ImageModalBody from "./ImageModalBody";

export interface IProps {
	show: boolean
  onHide?: (() => void)
  centered?:boolean;
  imagesData: {
     url: string 
     title: string 
  }[]
  imageIndex: number
}

const ImageModal: React.FC<IProps> = ({ show, onHide, imagesData, imageIndex, centered = false }) => {
  const lastIndex = imagesData.length - 1;
  const [index, setIndex] = useState(imageIndex);
  const showArrows = Boolean(lastIndex);

  useEffect(() => {
    setIndex(imageIndex);
  }, [imageIndex])

  const prevImage = (idx: number) => {
    if (idx > 0) {
      setIndex(idx - 1);
    }
  };

  const nextImage = (idx: number) => {
    if (idx < lastIndex) {
      setIndex(idx + 1);
    }
  };

  useImageArrowNavigation(index, prevImage, nextImage);

  return (
    <Modal show={show} onHide={onHide} size="xl" centered={centered} className="full-screen-modal" aria-labelledby="contained-modal-title-center">
      <Modal.Header className="py-4">
        <Modal.Title
          id="contained-modal-title-center"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <span className="d-flex align-items-center ">
            <SvgIcon
              iconLocation="/media/svg/icons/Design/Image.svg"
              size="4"
              bootstrapColor="primary"
              customClasses="mr-2 pb-1"
              title="Stitched Image"
            />
            {imagesData[index]?.title}
          </span>

          <span className="pointer p-0 m-0" onClick={onHide}>
            <SvgIcon
              iconLocation="/media/svg/icons/Navigation/Close.svg"
              size="4"
              bootstrapColor="primary"
              customClasses="m-0 p-0"
              title="Close"
            />
          </span>
        </Modal.Title>
      </Modal.Header>

      <ImageModalBody
        lastIndex={lastIndex}
        prevImage={prevImage}
        nextImage={nextImage}
        index={index}
        url={imagesData[index]?.url}
        showArrows={showArrows}
      />
    </Modal>
  );
};

export default ImageModal;
