import React, { useEffect, useRef } from "react";
import { useField, useFormikContext } from "formik";
import SVG from "react-inlinesvg";
import { useFormFieldsContext } from "./FormFieldsContext";
import { Error } from "./Error";
import { getFieldCSSClasses } from "./_helper";
import RequiredFieldMarker from "./RequiredFieldMarker";
import { keyboardUtils } from "../../../../../app/common/_utils";
import SvgIcon from "../../../../../app/components/common/SvgIcon";

interface IProps {
  name: string;
  label?: string;
  withErrorFeedback?: boolean;
  disabled?: boolean;
  type?: string;
  onChangeCalback?: any;
  containerClass?: string;
  customClass?: string;
  customStyle?: any;
  onInit?: any;
  isRequired?: boolean;
  column?: boolean;
  shouldTouch?:boolean;
  [key: string]: any;
}

export const Input: React.FC<IProps> = ({
  name,
  label = "",
  withErrorFeedback = true,
  disabled = false,
  type = "text",
  onChangeCalback,
  customClass = "",
  containerClass = "",
  customStyle = {},
  containerStyle = {},
  onInit = null,
  isRequired = false,
  column = true,
  shouldTouch = true,
  ...props
}) => {
  const { initialValues, touched, errors, setFieldValue, setFieldTouched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);
  const inputFiled = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // if (e.target.value) {
    //   type === "number"
    //     ? setFieldValue(field.name, parseFloat(e.target.value))
    //     : setFieldValue(field.name, e.target.value);

    // } else {
    //   setFieldValue(field.name, null);
    // }
    type === "number"
        ? setFieldValue(field.name, parseFloat(e.target.value))
        : setFieldValue(field.name, e.target.value);
    shouldTouch && setTimeout(() => setFieldTouched(field.name, true), 0)
    if (onChangeCalback) {
      if (type === 'file') {
        onChangeCalback((e.currentTarget as HTMLInputElement).files);
      } else {
        onChangeCalback(e.target.value);
      }
    }
  };

  const onKeyDown = (e:React.KeyboardEvent) => {
    if (keyboardUtils.isEscKey(e)) {
      e.stopPropagation();
      e.preventDefault();
      if (props.iref?.current || inputFiled.current) {
        inputFiled.current?.blur();
        props.iref?.current?.blur();
      }
    }

    props.onKeyDown && props.onKeyDown(e);
  }

  const value = props?.format ? props.format(field.value) : field.value;

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <div className={`flex ${column ? 'flex-column' : 'flex-row align-center'} ${containerClass}`} style={containerStyle}>
          {label && (
            <label htmlFor={field.name} style={{...(column ? {} : {marginBottom: 0, marginRight: 5})}}>
              {label}
              <RequiredFieldMarker name={name} isRequired={isRequired} />
            </label>
          )}
          
          {type === "textarea" ? (
            <textarea
              id={field.name}
              data-testid={field.name}
              onKeyDown={onKeyDown}
              className={`${disabled ? "not-allowed text-muted" : null} ${
                withErrorFeedback
                  ? getFieldCSSClasses(touched, errors, field.name, customClass)
                  : `${customClass} form-control`
              }`}
              style={customStyle}
              {...{ ...field, ...props, onChange, value: value ?? "" }}
              disabled={disabled || (isDisabled && isDisabled(name))}
            />
          ) : (
            <>
              <input
                ref={props.iref || inputFiled}
                id={field.name}
                data-testid={field.name}
                step="any"
                type={type ?? "text"}
                onKeyDown={onKeyDown}
                className={`${disabled ? "not-allowed text-muted" : null} ${
                  withErrorFeedback
                    ? getFieldCSSClasses(touched, errors, field.name, customClass)
                    : `${customClass} form-control`
                }`}
                style={customStyle}
                {...{ ...field, ...props, onChange, value: value ?? "" }}
                disabled={disabled || (isDisabled && isDisabled(name))}
              />
              {props.icon && (
                <span className={`${props.icon.containerClasses}`} style={props.icon.containerStyles}>
                  <SvgIcon
                    bootstrapColor={props.icon.bootstrapColor}
                    iconLocation={props.icon.iconLocation}
                    size={props.icon.size}
                    onClick={props.icon.onClick}
                    customClasses={props.icon.customClasses}
                  />
                </span>
              )}
            </>
          )}

          {withErrorFeedback && <Error name={field.name} errors={errors} touched={touched} />}
        </div>
      )}
    </>
  );
};
