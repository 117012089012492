export const DEFAULT_FILE_TYPES = ['.jpg', '.jpeg', '.png', '.pdf', '.docx', '.doc', '.odt', '.mp4', '.xls', '.xlsx', '.csv', '.pptx', '.ppt' ]

const FILE_ICON_PATH = '/media/svg/files';
export const IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

export interface IFileAtachment {
    id: string;
    fileUrl: string;
    url?: string;
    file?: File;
    mimeType: string;
    fileName:string;
    originalFileName?: string;
    action: "create" | "update" | "delete" | "old";
}

export const downloadFile = async (file:IFileAtachment, setFileDownloading?: (val:boolean) => void) => {
    const link = file.fileUrl || file.url;
    const filePath = (file.fileUrl || file.url || '').split('/')
    const fileName = file.fileName || file.originalFileName || filePath[filePath.length - 1];

    if (link) {
        setFileDownloading && setFileDownloading(true);
        fetch(link, {
            method: 'GET',
        })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            setFileDownloading && setFileDownloading(false)
        });
    }
}

export const getVizualization = (file:IFileAtachment):{url:string, className:string} => {
    return IMAGE_TYPES.indexOf(file?.mimeType) >= 0
            ? {
                url: file?.fileUrl || file?.url || '',
                className: ''
            } : {
                url: getFileIcon(file?.mimeType),
                className: 'h-30 w-30'
            }
}

export const getFileIcon = (mimeType:string) => {
    switch (mimeType) {
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return `${FILE_ICON_PATH}/ppt.svg`;

        case 'application/msword':
        case 'application/doc':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return `${FILE_ICON_PATH}/doc.svg`;

        case 'application/pdf':
            return '/media/svg/files/pdf.svg';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            return `${FILE_ICON_PATH}/xls.svg`;

        case 'application/xml':
            return `${FILE_ICON_PATH}/xml.svg`;

        case 'text/csv':
            return `${FILE_ICON_PATH}/csv.svg`;

        case 'audio/mp4':
        case 'video/mp4':
            return `${FILE_ICON_PATH}/mp4.svg`;

        case 'image/jpeg':
        case 'image/jpg':
            return `${FILE_ICON_PATH}/jpg.svg`;

            
        case 'image/png':
            return `${FILE_ICON_PATH}/png.svg`;

        case 'application/zip':
            return `${FILE_ICON_PATH}/zip.svg`;

        case 'text/html':
            return `${FILE_ICON_PATH}/html.svg`;
            
        case 'text/css':
            return `${FILE_ICON_PATH}/css.svg`;

        case 'text/javascript':
            return `${FILE_ICON_PATH}/javascript.svg`;

        default:
            return '';
    }
}