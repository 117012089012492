import { Dispatch } from "redux";
import { dashboardSlice } from "./dashboardSlice";
import { get } from "../../../../api/requester";
import { getResponseErrorMessage } from "../../../../redux/_helpers";
import { IAccuracy, IAuditedScenes, IDailyIncome, ILowAccuracy, IOnboarding, IOrderedArticles, IRecentActivities, ITaskCategory, ITaskProgressSection, Period } from "../_interfaces/reduxState";

const { actions } = dashboardSlice;
export const modulePath = '/api/dashboard/';

export const setSelectedPeriod = (period:Period) => actions.setSelectedPeriod(period);

export const getTaskCategories = () => async (dispatch: Dispatch) => {
  try {
    const res: ITaskCategory[] = await get('/api/activity/taskcategories/select')
    dispatch(actions.setTaskCategories(res));
  } catch (errors: any) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    console.error(message);
  }
};


export const getTaskProgressReport = (period:Period) => async (dispatch:Dispatch) => {
  try {

    dispatch(actions.setTaskProgressReportLoading(true));
    const res:ITaskProgressSection = await get(`${modulePath}reporting/taskProgressSectionReport`, {DatePeriod: period});
    dispatch(actions.setTaskProgressReport(res));
    dispatch(actions.setTaskProgressReportLoading(false));
  } catch (errors: any) {
    dispatch(actions.setTaskProgressReportLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}


export const getDailyIncomeReport = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setDailyIncomeReportLoading(true));
    const res:IDailyIncome = await get(`${modulePath}reporting/dailyIncomeSectionReport`, {DatePeriod: period}) 
    dispatch(actions.setDailyIncomeReport(res));
    dispatch(actions.setDailyIncomeReportLoading(false));
  } catch (errors:any) {
    dispatch(actions.setDailyIncomeReportLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}


export const getOrderedArticlesReport = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setOrderedArticlesReportLoading(true));
    const res:IOrderedArticles = await get(`${modulePath}reporting/orderedArticlesSectionReport`, {DatePeriod: period}) 
    dispatch(actions.setOrderedArticlesReport(res));
    dispatch(actions.setOrderedArticlesReportLoading(false));
  } catch (errors:any) {
    dispatch(actions.setOrderedArticlesReportLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}

export const getAccuracyReport = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setAccuracyReportLoading(true));
    const res:IAccuracy = await get(`${modulePath}reporting/accuracySectionReport`, {DatePeriod: period}) 
    dispatch(actions.setAccuracyReport(res));
    dispatch(actions.setAccuracyReportLoading(false));
  } catch (errors: any) {
    dispatch(actions.setAccuracyReportLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}

export const getAuditedScenesReport = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setAuditedScenesLoading(true));
    const res:IAuditedScenes = await get(`${modulePath}reporting/auditedScenesSectionReport`, {DatePeriod: period}) 
    dispatch(actions.setAuditedScenesReport(res));
    dispatch(actions.setAuditedScenesLoading(false));
  } catch (errors: any) {
    dispatch(actions.setAuditedScenesLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}

export const getOnboardingProducts = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setOnboardingProductsLoading(true));
    const res:IOnboarding = await get(`${modulePath}reporting/onBoardedProductsSectionReport`, {DatePeriod: period}) 
    dispatch(actions.setOnboardingProducts(res));
    dispatch(actions.setOnboardingProductsLoading(false));
  } catch (errors: any) {
    dispatch(actions.setOnboardingProductsLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}

export const getRecentActivities = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setRecentActivitiesLoading(true));
    const res:IRecentActivities = await get(
      `${modulePath}reporting/recentActivitySectionReport/${period}`,
      {
        DatePeriod: period
      }
    );
    dispatch(actions.setRecentActivities(res));
    dispatch(actions.setRecentActivitiesLoading(false));
  } catch (errors: any) {
    dispatch(actions.setRecentActivitiesLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}

export const getLowAccuracy = (period:Period) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setLowAccuracyLoading(true));
    const res:ILowAccuracy[] = await get(`${modulePath}reporting/lowAccuracySectionReport`, {DatePeriod: period}) 
    dispatch(actions.setLowAccuracy(res));
    dispatch(actions.setLowAccuracyLoading(false));
  } catch (errors: any) {
    dispatch(actions.setLowAccuracyLoading(false));
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
    console.error(message);
  }
}
