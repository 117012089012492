import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import { Error } from "./Error";
import Select from "react-select";
import { getValue, getTouched, getError } from "./_helper";

const getBorderColor = (touched, errors) => {
  let color = "#E5EAEE";
  if (touched && errors) {
    color = "#F64E60  !important";
  }
  if (touched && !errors) {
    color = "#1BC5BD  !important";
  }
  return color;
};

export function SearchableSelectArray({
  name,
  label,
  disabled,
  loadOptionsAction,
  isLoadingSelector,
  optionsArray,
  nameProp = "name",
  valueProp = "id",
  namePropDevider = "-",
  onChangeCalback,
  customClass,
  customStyle,
  withErrorFeedback = true,
  hasNoneOption = true,
  isSelectValueComplexObject = true,
  isMulti = false,
  isAsync = false,
  countOfFetchedItems,
  onInit,
  ...props
}) {
  const { initialValues, values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);

  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const options = optionsArray;

  let isLoading = useSelector(state => {
    return isLoadingSelector ? isLoadingSelector(state) : false;
  }, shallowEqual);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const getItem = ids => {
    if (ids) {
      if (isMulti) {
        return options ? options.filter(element => ids.find(item => item[valueProp] === element[valueProp])) : null;
      } else {
        return options ? options.find(element => element[valueProp] === ids) : null;
      }
    } else return null;
  };

  const onChange = value => {
    let val = null;
    if (isSelectValueComplexObject) {
      val = value;
    } else {
      if (value) {
        if (isMulti) {
          val = value;
        } else {
          val = value[valueProp];
        }
      } else {
        val = null;
      }
    }
    setFieldValue(name, val);
    if (onChangeCalback) {
      onChangeCalback(val);
    }
  };

  const onBlur = () => {
    const touchedField = getValue(touched, name);
    if (typeof touchedField == "object") {
      setFieldTouched(`${name}.id`, true);
    } else setFieldTouched(name, true);
  };

  const inputProps = { ...field, ...props, onChange, onBlur };

  const selectStyles = {
    control: styles => ({
      ...styles,
      borderColor: withErrorFeedback
        ? getBorderColor(getTouched(touched, field.name), getError(errors, field.name))
        : styles.borderColor,
      boxShadow: "no",
    }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: "#E5EAEE" }),
    placeholder: styles => ({ ...styles, color: "#B5B5C3" }),
    menuPortal: (styles) => ({ ...styles, zIndex: 999999 })
  };

  const filterOptions = (candidate, input) => {
    if (!isAsync) {
      if (input && candidate) {
        return candidate.label.toLowerCase().includes(input.toLowerCase());
      }
    }
    return true;
  };

  const handleInputChange = newValue => {
    if (isAsync && inputValue.toLowerCase() !== newValue.toLowerCase()) {
      setInputValue(newValue);
      if (loadOptionsAction) {
        dispatch(
          loadOptionsAction({
            id: values.id,
            filter: newValue,
            countOfResults: countOfFetchedItems,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (loadOptionsAction) {
      dispatch(loadOptionsAction());
    }
  }, [values.id, loadOptionsAction, countOfFetchedItems, dispatch]);

  const getSelectValue = () => {
    if (isMulti) {
      return getItem(field.value);
    }
    if (isSelectValueComplexObject) {
      if (field.value && field.value.id) {
        return field.value;
      }
      return null;
    }
    return getItem(field.value);
  };

  const select = document.getElementById(name);
  const windowHorizon = window.innerHeight / 2;
  let menuPlacement = "auto";
  
  if (select) {
    const top = select.getBoundingClientRect()?.top;
    if (top > windowHorizon) {
      menuPlacement = "top";
    }
  }

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          {label && <label htmlFor={name}>Select {label}</label>}
          <Select
            menuPlacement={menuPlacement}
            name={name}
            id={name}
            inputId={name}
            onInputChange={handleInputChange}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            className={customClass}
            styles={selectStyles}
            menuPortalTarget={document.body}
            {...inputProps}
            isDisabled={disabled || (isDisabled && isDisabled(name))}
            value={getSelectValue()}
            defaultValue={getSelectValue()}
            options={options}
            getOptionLabel={option =>
              Array.isArray(nameProp)
                ? getValue(option, nameProp[0]) + namePropDevider + getValue(option, nameProp[1])
                : getValue(option, nameProp)
            }
            getOptionValue={option => option[valueProp]}
            isClearable={hasNoneOption}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary75: "#69b3ff",
                primary: "#3699FF",
                neutral5: "#F3F6F9",
              },
            })}
            filterOption={filterOptions}
            noOptionsMessage={() => "No items"}
            isLoading={isMenuOpen && isLoading}
            isMulti={isMulti}
            {...props}
          ></Select>
          {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
        </>
      )}
    </>
  );
}
