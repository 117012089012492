import { articleCategoriesSlice } from "./articleCategoriesSlice";
import * as defaultActions from "../../../../../redux/_helpers";

export const QPARAMS_CHANGED = "articleCategories/QParamsChanged";
const { actions } = articleCategoriesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "ArticleCategories_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("ArticleCategories_Get", actions);
export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "ArticleCategories_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("ArticleCategories_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("ArticleCategories_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "ArticleCategories_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "description", "isForeign"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("ArticleCategories_Put", actions);

export const clearError = () => defaultActions.clearError(actions);
