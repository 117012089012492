import { RootState } from "../../../../../redux/store";

export const stocksLoading = (state: RootState) => state.stockAvailability.itemsLoading;
export const stocksActionLoading = (state: RootState) => state.stockAvailability.actionsLoading;

export const stockAvailabilities = (state: RootState) => state.stockAvailability.items;
export const stocksCount = (state: RootState) => state.stockAvailability.itemsCount;
export const stockAvailabilityForEdit = (state: RootState) => state.stockAvailability.itemForEdit;

export const error = (state: RootState) => state.stockAvailability.error;
export const errorAction = (state: RootState) => state.stockAvailability.errorAction;
export const validationErrors = (state: RootState) => state.stockAvailability.validationErrors;


// Details page
export const articlesListLoading = (state:RootState) => state.stockAvailability.articlesListLoading;
export const planogramDetailsLoading = (state:RootState) => state.stockAvailability.planogramDetailsLoading;
export const realogramDetailsLoading = (state:RootState) => state.stockAvailability.realogramDetailsLoading;

export const articlesList = (state:RootState) => state.stockAvailability.articlesList;
export const planogramArticlesList = (state:RootState) => state.stockAvailability.planogramArticlesList;
export const realogramArticlesList = (state:RootState) => state.stockAvailability.realogramArticlesList;