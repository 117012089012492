export const isEnterKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13 || e.code?.includes('Enter');
}

export const isEscKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.keyCode === 27 || e.key === 'Escape';
}

export const isShiftKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'Shift';
}

export const isShiftDelete = (e:React.KeyboardEvent | KeyboardEvent) => {
    if (e.shiftKey) {
        return e.key === 'Delete' || e.code === 'Delete' || e.which === 46;
    }
    return false;
}

export const isDeleteKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    if (!e.shiftKey) {
        return e.key === 'Delete' || e.code === 'Delete' || e.which === 46;
    }
    return false;
}

export const isControlKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'Control';
}

export const isSpaceKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === ' ' || e.code === 'Space' || e.which === 32;
}

export const isArrowRightKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'ArrowRight' || e.code === 'ArrowRight' || e.which === 39
}

export const isArrowLeftKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'ArrowLeft' || e.code === 'ArrowLeft' || e.which === 37
}

export const isArrowUpKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'ArrowUp' || e.code === 'ArrowUp' || e.which === 38
}

export const isArrowDownKey = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'ArrowDown' || e.code === 'ArrowDown' || e.which === 40
}

export const isTab = (e:React.KeyboardEvent | KeyboardEvent) => {
    return e.key === 'Tab' || e.code === 'Tab' || e.which === 9
}

export const isNumber = (e:React.KeyboardEvent | KeyboardEvent) => {
    return (/^\d$/i).test(e.key);
}