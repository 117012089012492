import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEditUserItem, IUserEntities } from "../../_interfaces/reduxState";

const initialState: IDefaultState<IUserEntities, any, IEditUserItem> = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers,
});