import { customerDivisionsSlice } from "./customerDivisionsSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import { Dispatch } from "redux";
import { get } from "../../../../../api/requester";

const { actions } = customerDivisionsSlice;

export const QPARAMS_CHANGED = "customerDivisions/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
    "CustomerDivisions_Get",
    actions,
    QPARAMS_CHANGED,
  );

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerDivisions_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
    "CustomerDivisions_GetSelect",
    actions,
    "entitiesSelect",
    "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerDivisions_GetById", actions);
export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerDivisions_Delete", actions);
export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerDivisions_Put", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
    "CustomerDivisions_Post",
    actions,
    defaultActions.convertCreateItemParams(["name"]),
);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);