import { Dispatch } from "redux";
import { onboardingSlice } from "./onboardingSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get } from "../../../../../api/requester";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { getErrorMessage } from "../../../../../redux/_helpers";
import * as fields from "../../fields";
import { IArticlesDetailsList, IArticleSearchParams, IImageForSave, IItemForSave } from "../../_interface/Onboarding";
import { IFilter } from "../../../RealTimeRecognition/_interfaces/reduxState";

const moduleUrl = '/api/onboarding';

const { actions } = onboardingSlice;
export const setValidationErrors = actions.setValidationErrors;

export const getItems = (params: any) => {
  if (params.Sort.indexOf('onboardPercentage') >= 0) {
    params.Sort = params.Sort.replace('onboardPercentage', 'status');
  }

  return defaultActions.getItems(`${moduleUrl}/irProductsList`, actions, params)
};
export const getItem = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);
export const saveItem = (onboardItem:IItemForSave, id: string | null) =>
  defaultActions.saveItem(
    moduleUrl,
    actions,
    onboardItem,
    id,
    refineDataForSaving,
    'multipart/form-data;boundary="boundary"',
  );
  

export const clearAll = () => defaultActions.clearAll(actions);
export const clearOnboardingItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);
export const getFilters = () => async (dispatch:Dispatch) => {
    try {
        const onboardingFilters:any[] = await get(`/api/appmasterdata/articles/complexSearch`);
        const onboardingStatusFilter:IFilter[] = await get(`${moduleUrl}/articleStatuses/complexSearch`);
        const articleCategoryFilter:IFilter[] = await get(`${moduleUrl}/articleCategories/complexSearch`);

        dispatch(actions.setFilter(
            constructInitialFilters([
              ...(onboardingFilters || []),
              ...(onboardingStatusFilter || []),
              ...(articleCategoryFilter || []),
            ])
        ));
    } catch (error) {
        const errors = getErrorMessage(error, "Filters could not be loaded!");
        dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "errorAction" }));
    }
}

export const getArticles = (params:IArticleSearchParams) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setArticlesLoading(true));
    const articles:IArticlesDetailsList[] = await get(`${moduleUrl}/irProductsDetails`, {
      ...params,
      ...(params.filter ? {Filter: params.filter} : {}),
    });

    dispatch(actions.setArticles(articles));
  } catch (errors) {
    dispatch(actions.setArticlesLoading(false));
    const error = getErrorMessage(errors, "Error occured during fetching articles!");
    dispatch(actions.catchError({ error, callType: "actionsLoading", errorProp: "error" }));
  }
}

/////////////////////////////
const refineDataForSaving = (data:IItemForSave) => {
  const files: File[] = [];

  const createdArticleImages:{fileName:string}[] = [];

  data[fields.IMAGES].forEach((image:IImageForSave) => {
    files.push(image.file);

    createdArticleImages.push({ fileName: image.file.name })
  });

  const form = {
    [fields.ARTICLE_ID]: data[fields.GENERAL][fields.ONBOARDING_PRODUCT_ID],
    [fields.CODE]: data[fields.GENERAL][fields.CODE],
    [fields.NAME]: data[fields.GENERAL][fields.NAME],
    [fields.START_ONBOARDING]: data[fields.GENERAL][fields.START_ONBOARDING] || false,
    [fields.ONBOARD_PERCENTAGE]: 0,
    [fields.IMAGES]: createdArticleImages,
  };
  
  const formData = new FormData();
  formData.append('command', JSON.stringify(form));
  
  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData;
};

/////////////////////////////
const refineDataForTabs = (data:IArticlesDetailsList) => {
  return {
    [fields.GENERAL]: {
      [fields.ONBOARDING_PRODUCT_ID]: data[fields.ONBOARDING_PRODUCT_ID],
      [fields.BRAND]: data[fields.BRAND],
      [fields.CATEGORY]: data[fields.CATEGORY],
      [fields.CODE]: data[fields.CODE],
      [fields.FLAVOR]: data[fields.FLAVOR],
      [fields.NAME]: data[fields.NAME],
      [fields.PACK_TYPE]: data[fields.PACK_TYPE],
      [fields.PACK_SIZE]: data[fields.PACK_SIZE],
      [fields.START_ONBOARDING]: false,
      [fields.DEPTH]: data[fields.DEPTH],
      [fields.WIDTH]: data[fields.WIDTH],
      [fields.HEIGHT]: data[fields.HEIGHT],
    },
    [fields.IMAGES]: data[fields.ARTICLE_IMAGES].map((imageUrl:string, index:number) => ({
      imageUrl,
      // imageThumbnail: image, //
      isDefault: index === 0,
      id: index
    })),
  };
};