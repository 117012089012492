import { getDefaultSlice } from "../../../../../redux/_helpers";
import * as constants from "./constants";

const customReducers = {};

const initiaState = {
  [constants.TASK_TYPE_SURVEYS]: [],
  [constants.TASK_TYPE_SURVEYS_LOADING]: false,
};

export const taskTypesSlice = getDefaultSlice("taskTypes", customReducers, initiaState);
