import * as Yup from "yup";

export const generateValidations = (requiredFields:string[], field:string, validations: any, arrayFieldName: string = "", forceRequired = false) => {
  const swaggerValidations = validations[field];
  let schema: any = Yup.mixed();
  let isArray = false;
  let message = "";

  // Field type
  switch (swaggerValidations !== undefined && swaggerValidations["type"]) {
    case "string":
      schema = Yup.string();
      break;
    case "number":
      schema = Yup.number().typeError('Value must be a valid number');
      break;
    case "integer":
      schema = Yup.number().integer("Must be an integer");
      break;
    case "date":
      schema = Yup.date();
      break;
    case "boolean":
      schema = Yup.boolean();
      break;
    case "array":
      isArray = true;
      schema = Yup.array();
      break;
    case "object":
      schema = Yup.object();
      break;
    default:
      schema = Yup.mixed();
  }

  for (const validation in swaggerValidations) {
    // Required
    if ((validation === "nullable" && !swaggerValidations[validation]) || forceRequired || requiredFields.indexOf(field) >= 0) {
      schema = schema.required("Field is required");
    } else {
      schema = schema.optional().nullable(true);
    }

    // String
    if (validation === "maxLength") {
      schema = schema.max(swaggerValidations[validation], `Maximum ${swaggerValidations[validation]} characters`);
    }

    if (validation === "minLength" || validation === 'minItems') {
      if (isArray) message = `At least ${swaggerValidations[validation]} ${arrayFieldName} is required`;
      else message = `Minimum ${swaggerValidations[validation]} characters`;
      schema = schema.min(swaggerValidations[validation], message);
    }

    // Number
    if (validation === "maximum") {
      schema = schema.max(Number(swaggerValidations[validation]), `Must be ≤ ${swaggerValidations[validation]}`);
    }

    if (validation === "minimum") {
      schema = schema.min(Number(swaggerValidations[validation]), `Must be ≥ ${swaggerValidations[validation]}`);
    }

    // Regex
    if (validation === "pattern") {
      schema = schema.matches(swaggerValidations[validation], `Invalid format`);
    }
    // Allow null values for all fields to prevent Yup`s type error
    if (validation === "nullable") {
      schema = schema.nullable(true);
    }
  }

  return schema;
};

// childValidations a helper function that is used as dynamic selector for nested schemas in objects
export const childValidations = (validations: any, parent: string, child: string, childIndex: number) => {
  return validations[parent]
    ? validations[parent].oneOf
      ? validations[parent].oneOf[childIndex].properties
      : validations[parent].properties || "n/a"
    : "n/a";
};

export const childValidationsRequired = (validations: any, parent: string, child: string, childIndex: number):string[] => {
  return validations[parent]
    ? validations[parent].oneOf
      ? validations[parent].oneOf[childIndex].required || []
      : validations[parent].required || []
    : []
}

// childValidationsInArray a helper function that is used as dynamic selector for nested schemas in arrays
export const childValidationsInArray = (validations: any, parent: string, child: string) => {
  return validations && validations[parent] ? validations[parent].items.properties : {};
};

export const childValidationsInArrayRequired = (validations: any, parent: string, child: string):string[] => {
  return validations && validations[parent]
    ? validations[parent].items.required || []
    : []
}

export const setNaNValueToNull = (_:any, val:any) => val === Number(val) ? val : null;