import { usersSlice } from "./usersSlice";
import * as defaultActions from "../../../../../redux/_common";

const { actions } = usersSlice;
export const moduleUrl = "/api/usersmanagement/users";

export const setValidationErrors = actions.setValidationErrors;

export const getUsers = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const saveUser = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const getUser = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const deleteUser = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearUser = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
