import { createSlice } from "@reduxjs/toolkit";
import { Access } from "../usePageAccessHook";
import * as constants from "./constants";

export const slice = createSlice({
  name: "userAccess",
  initialState: {
    [constants.MENU_ITEMS]: [],
    [constants.MENU_ITEMS_LOADING]: false,
    [constants.ORG_UNITS]: [],
    [constants.ORG_UNITS_LOADING]: false,
    [constants.TAB_NAV]: [],
    access: {
      path: "/dashboard",
      rights: Access.WRITE,
    },
  },
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state[action.payload.callType] = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === constants.MENU_ITEMS_LOADING) {
        state[constants.MENU_ITEMS] = [];
        state[constants.MENU_ITEMS_LOADING] = true;
      } else if (action.payload.callType === constants.SUB_MENU_ITEMS_LOADING) {
        state[constants.MENU_ITEMS_LOADING] = true;
      }
    },
    startTabNavCall: (state, action) => {
      state.error = null;
      state[constants.TAB_NAV] = [];
    },
    setTabMenuItems: (state, action) => {
      const { items } = action.payload;
      state[constants.TAB_NAV] = items;

    },
    setUserAccess: (state, action) => {
      state.access = action.payload;
    },
    setUserOrgUnits: (state, action) => {
      state.organizationUnits = action.payload;
    },
    setUserMenus: (state, action) => {
      state.menu = action.payload;
    },
    customItemsFetched: (state, action) => {
      const { entities, entitiesProp, loadingProp } = action.payload;

      state[entitiesProp] = entities;
      state[loadingProp] = false;
      state.error = null;
    },
    fetchSubMenuItems: (state, action) => {
      const { menuID, items } = action.payload;
      state[constants.MENU_ITEMS] = LoadItems(menuID, state[constants.MENU_ITEMS], items);
      state[constants.MENU_ITEMS_LOADING] = false;
      state.error = null;
    },
  },
});

function LoadItems(menuID, menuItems, subMenuItems) {
  return menuItems.map(menuItem => {
    if (menuItem.id === menuID) {
      return { ...menuItem, items: subMenuItems };
    } else {
      if (menuItem.items && menuItem.items.length > 0)
        return {
          ...menuItem,
          items: LoadItems(menuID, menuItem.items, subMenuItems),
        };
      else return menuItem;
    }
  });
}
