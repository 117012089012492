import { assetModelBrandsSlice } from "./assetModelBrandsSlice";
import * as defaultActions from "../../../../../redux/_common";

const { actions } = assetModelBrandsSlice;
export const moduleUrl = "/api/assetmanagement/assetmodelbrands"

// Default actions currying
export const setValidationErrors = actions.setValidationErrors;

export const getAssetModelBrands = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const getAssetModelBrand = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const saveAssetModelBrand = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const deleteAssetModelBrand = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearAssetModelBrand = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

export const getFilters = () => defaultActions.getFilters(moduleUrl, actions);
export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);
