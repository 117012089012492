export const IS_FOREIGN = 'isForeign';
export const ID = 'id';
export const ONBOARDING_PRODUCT_ID = 'onboardProductId';
export const IMAGE_URL = 'imageUrl';
export const IMAGE_THUMBNAIL = 'imageThumbnail';
export const CODE = 'code';
export const NAME = 'name';
export const CATEGORY = 'category';
export const ASSET_TYPE = 'assetType';
export const STATUS = 'status';

export const GENERAL = 'general';
export const FLAVOR = 'flavor';
export const DIMENSIONS = 'dimensions';
export const BRAND = 'brand';
export const PACK_TYPE = 'packType';
export const PACK_SIZE = 'packSize';
export const IMAGES = 'createArticleImage';
export const ARTICLE_IMAGES = 'articleImages';
export const START_ONBOARDING = 'isSendForOnboarding';
export const ARTICLE_ID = 'articleId';
export const ONBOARD_PERCENTAGE = 'onboardPercentage';
export const DEPTH = 'depth';
export const HEIGHT = 'height';
export const WIDTH = 'width';