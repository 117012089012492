import { MouseEvent, useState } from "react";
import ImageMagnifier from "./ImageMagnifier";
import ImageModal from "./ImageModal";

interface IPropsImage {
  imagesData: {
    title: string;
    url: string;
    thumbnail: string;
    isPlain?: boolean
  }[];
  imageIndex: number;
  height?: string;
  width?: string
}

export const TableImage: React.FC<IPropsImage> = ({ imagesData, imageIndex, height = '5rem', width = '5rem' }) => {
  const [showModal, setShowModal] = useState(false);
  const [showImage, setShowImage] = useState(false);

  return (
    <>
      {showModal && (
        <ImageModal
          show={showModal}
          onHide={(e?:MouseEvent) => {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            setShowModal(false)
          }}
          imagesData={imagesData}
          imageIndex={imageIndex}
        />
      )}
      <span
        onClick={(e:MouseEvent) => {
          if (!imagesData[0].url) return;
          e.stopPropagation();
          e.preventDefault();
          setShowModal(true);
        }}
        onMouseEnter={() => setShowImage(true)}
      >
        <ImageMagnifier src={imagesData[0].thumbnail || imagesData[0].url} height={height} width={width} />
        {/* {imagesData[0].thumbnail && !showImage ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: "relative",
              overflow: "hidden",
              border: "1px solid #E5EAEE",
              borderRadius: "0.5rem",
              height: "5rem",
              width: "5rem",
            }}
          >
            <img
              src={imagesData[0].thumbnail}
              alt={"stitched_image"}
              style={{ height: "auto", maxHeight: "100%", maxWidth: "100%" }}
            />
          </div>
        ) : (
          <ImageMagnifier src={imagesData[0].url} height="5rem" width="5rem" />
        )} */}
      </span>
    </>
  );
};
