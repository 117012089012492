/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useEffect } from "react";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "./common/_customHooks/redux";
import { Routes as DOMRoutes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { InteractionType, EventType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { getTaskCategories } from "../app/modules/Dashboard/_redux/dashboardActions";
import { useTaskCategories } from "../app/modules/Dashboard/_redux/dashboardSelectors";
import { Layout } from "../_metronic/layout";
import { authProvider } from "./auth/authProvider";
import { ErrorPage } from "./pages/ErrorPage";
import BasePage from "./BasePage";
import Login from "./pages/Login";

export function Routes() {
  //the idea of ErrorComponent is to clear errors from application console log
  function ErrorComponent({ error }) {
    return null;
  }

  // taken from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md , section "How do I handle the forgot password flow in a react app?"
  // i.e. AADB2C90118 code is received when "Forgot your password" is clicked, however AADB2C90091 code is received when cancel button from "Forgot your password" window is clicked
  const MainContent = () => {
    const { instance } = useMsal();

    useEffect(() => {
      const callbackId = instance.addEventCallback(message => {
        if (message.eventType === EventType.LOGIN_FAILURE && message.interactionType === InteractionType.Redirect) {
          if (message.error.errorMessage && message.error.errorMessage.indexOf("AADB2C90091") > -1) {
            instance.loginRedirect({
              authority: process.env.REACT_APP_AUTH_AUTHORITY,
            });
          }
        }
      });

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    }, [instance]);


    const dispatch = useDispatch();
    const taskCategories = useSelector(useTaskCategories);

    useEffect(() => {
      if (taskCategories.length === 0) dispatch(getTaskCategories());
    }, []);

    const routes = BasePage();
    
    return (
      <>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent}>
          {
            <DOMRoutes>
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/*" element={<Layout />}>
                {routes}
              </Route>
            </DOMRoutes>
          }
        </MsalAuthenticationTemplate>

        <UnauthenticatedTemplate>
          <div>
            <h3>Authenticating...</h3>
          </div>
        </UnauthenticatedTemplate>
      </>
    );
  };
  return (
    <MsalProvider instance={authProvider}>
      <MainContent />
    </MsalProvider>
  );
}
