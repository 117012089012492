import React from "react";
import { StatusCssClasses, StatusTitles } from "../helpers"

export const TemplatesStatusColumnFormatter = (cellValue:boolean) => {
  const css = StatusCssClasses[+cellValue] || StatusCssClasses[StatusCssClasses.indexOf(StatusTitles[+cellValue].toLowerCase())];
  const label = StatusTitles[+cellValue] || StatusTitles[StatusCssClasses.indexOf(StatusTitles[+cellValue].toLowerCase())];

  return (
    <span className={`label label-lg label-light-${css} label-inline`}>
      {label}
    </span>
  );
};
