import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { IAssortmentArticles, IState } from "./_interfaces";

const initialState: IState = {
  ...defaultInitialState,
  articlesLoading: false,
  articlesError: null,
  assortmentArticlesError: null,
  searchArticles: [],
  itemForEdit: {
    id: "",
    assortmentTab: {
      code: "",
      description: "",
    },
    articlesTab: {
      assortmentArticles: [],
      totalCount: 0,
    },
    createAssortmentArticles: [],
    deleteAssortmentArticles: [],
  },
};

const reducers = {
  ...defaultReducers,
  clearAll: (state: IState, action: PayloadAction<any>) => {
    state.itemsLoading = false;
    state.articlesLoading = false;
    state.actionsLoading = false;
    state.itemsCount = 0;
    state.items = [];
    state.dropDownItems = [];
    state.error = null;
    state.errorAction = null;
    state.articlesError = null;
    state.dropDownError = null;
    state.assortmentArticlesError = null;
    state.itemForEdit = {
      id: "",
      assortmentTab: {
        code: "",
        description: "",
      },
      articlesTab: {
        assortmentArticles: [],
        totalCount: 0,
      },
      createAssortmentArticles: [],
      deleteAssortmentArticles: [],
    };
  },
  clearItem: (state: any) => {
    state.itemsLoading = false;
    state.actionsLoading = false;
    state.dropDownItems = [];
    state.assortmentArticlesError = null;
    state.itemForEdit = {
      id: "",
      assortmentTab: {
        code: "",
        description: "",
      },
      articlesTab: {
        assortmentArticles: [],
        totalCount: 0,
      },
    };
  },
  clearAssortmentArticles: (state: any) => {
    state.error = null;
    state.errorAction = null;
    state.articlesError = null;
    state.dropDownError = null;
    state.assortmentArticlesError = null;
    state.itemForEdit = {
      id: "",
      assortmentTab: {
        code: "",
        description: "",
      },
      articlesTab: {
        assortmentArticles: [],
        totalCount: 0,
      },
    };
  },
  clearAssortmentArticlesError: (state: any) => {
    state.assortmentArticlesError = null;
  },
  startArticlesCall: (state: any, action: PayloadAction<any>) => {
    state.articlesError = null;
    state[action.payload.callType] = true;
  },
  setAssortmentArticles: (state: any, action: PayloadAction<any>) => {
    state.articlesLoading = false;
    state.itemForEdit = {
      ...state.itemForEdit,
      articlesTab: {
        assortmentArticles: action.payload.items,
        totalCount: action.payload.itemsCount,
      },
    };

    state.error = null;
    state.errorAction = null;
    state.dropDownError = null;
  },
  deleteAssortmentArticles: (state: IState, action: PayloadAction<{newArticles: IAssortmentArticles[], deletedArticleIds: IAssortmentArticles[], addedArticles: IAssortmentArticles[]}>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      articlesTab: {
        assortmentArticles: action.payload.newArticles,
        totalCount: state.itemForEdit.articlesTab.totalCount - 1,
      },
      createAssortmentArticles: action.payload.addedArticles,
      deleteAssortmentArticles: action.payload.deletedArticleIds,
    };
  },
  addAssortmentArticles: (state: IState, action: PayloadAction<{newArticles: IAssortmentArticles[], newArticleIds: IAssortmentArticles[]}>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      articlesTab: {
        assortmentArticles: action.payload.newArticles,
        totalCount: state.itemForEdit.articlesTab.totalCount + 1,
      },
      createAssortmentArticles: action.payload.newArticleIds,
    };
  },
  updateAssortmentCode: (state: IState, action: PayloadAction<string>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      assortmentTab: {
        ...state.itemForEdit.assortmentTab,
        code: action.payload,
      },
    };
  },
  updateAssortmentDesc: (state: IState, action: PayloadAction<string>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      assortmentTab: {
        ...state.itemForEdit.assortmentTab,
        description: action.payload,
      },
    };
  },
  setSearchArticles: (state: IState, action: PayloadAction<IAssortmentArticles[]>) => {
    state.searchArticles = action.payload;
  },
  resetSearchedItems: (state: IState) => {
    state.searchArticles = [];
  },
};

export const assortmentSlice = createSlice({
  name: "assortment",
  initialState,
  reducers,
});
