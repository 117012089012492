
export const GENERAL = 'general';
export const TASK_ID = 'taskId';
export const TASK_IDENTIFIER = 'taskIdentifier';
export const TASK_EXTERNAL_ID = 'taskExternalId';
export const DESCRIPTION = 'description';
export const TYPE = 'type';
export const CUSTOMER_ID = 'customerId';
export const TASK_CUSTOMER_ID = 'taskCustomerId';
export const EQUIPMENT = 'equipment';
export const CUSTOMER_NAME = 'customerName';
export const CUSTOMER_LONG_NAME = 'customerLongName';
export const STATUS_NAME = 'statusName';
export const PLANNED_FROM = 'plannedFrom';
export const PLANNED_TO = 'plannedTo';
export const CREATED_BY = 'createdBy';
export const CREATED_ON = 'createdOn';
export const LAST_CHANGED_BY = 'lastChangedBy';
export const LAST_CHANGE_DATE = 'lastChangeDate';
export const TASK_IMAGES_URL = 'taskImagesUrl';
export const URL = 'url';
export const THUMBNAIL = 'thumbnail';
export const TASK_NOTES = 'taskNotes';
export const TASK_MAINTENANCES = 'taskMaintenances';
export const MALFUNCTION = 'malfunction';
export const RESOLVED = 'resolved';
export const COMMENT = 'comment';
export const SURVEYS = 'surveys';
export const TASK_FILES = 'taskFiles';
export const DELETE_TASK_FILES = 'deleteTaskFiles';
export const SURVEY_SUBMISSION = 'surveySubmission';