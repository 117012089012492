import { malfunctionsSlice } from "./malfunctionsSlice";
import * as defaultActions from "../../../../../redux/_common";

const { actions } = malfunctionsSlice;
export const moduleUrl = "/api/assetmanagement/malfunction";

// Default actions currying
export const setValidationErrors = actions.setValidationErrors;

export const getMalfunctions = (params: {}) => defaultActions.getItems(moduleUrl, actions, params, (items:any[]) => {
    return items.map((item) => ({ ...item, inactive: !item.inactive }));
});

export const getMalfunction = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const saveMalfunction = (user: {}, id: string | null) => defaultActions.saveItem(moduleUrl, actions, user, id);

export const deleteMalfunction = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearMalfunction = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);