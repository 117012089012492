import { useEffect } from "react";

export const useImageArrowNavigation = (
    index:number,
    prevImage:(val:number) => void,
    nextImage:(val:number) => void,
) => {
    useEffect(() => {
        function handleKeyUp(event: KeyboardEvent): void {
            switch (event.key) {
                case "ArrowLeft":
                    prevImage(index);
                    break;
                case "ArrowRight":
                    nextImage(index);
                    break;
                case "ArrowDown":
                    prevImage(index);
                    break;
                case "ArrowUp":
                    nextImage(index);
                    break;
                case "PageDown":
                    prevImage(index);
                    break;
                case "PageUp":
                    nextImage(index);
                    break;
                default:
                    return;
            }
        }
    
        window.addEventListener("keyup", handleKeyUp);
        return () => window.removeEventListener("keyup", handleKeyUp);
    }, [index]);
}