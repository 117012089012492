import React from "react";

export const EventFormatter = (cellValue:string) => {
    const css = cellValue.toLowerCase() === 'out' ? 'danger' : 'success'

    return (
        <span className={`label label-lg label-light-${css} label-inline fs-12`}>
            {cellValue.charAt(0).toUpperCase() + cellValue.slice(1)}
        </span>
    );
}