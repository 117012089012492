import { useEffect } from "react";
/**
 * Hide standard Metronic theme elements:
 * 
 * @Header 
 * @Aside
 * @Toolbar
 */
export const useHideThemeElements = ( removeFooter = false, hideAll = true ) => {
    // Hide some of the standard UI elements only for this module
    const header = document.getElementById("kt_header");
    const mobileButtons = document.getElementById("header-mobile-buttons");
    const aside = document.getElementById("kt_aside");
    const wrapper = document.getElementById("kt_wrapper");
    const content = document.getElementById("kt_content");
    const stickyToolbar = document.querySelector(".sticky-toolbar");
    const footer = document.getElementById("kt_footer");
  
    useEffect(() => {
      if (hideAll) {
        if (header) header.classList.add("d-none");
        if (mobileButtons) mobileButtons.classList.add("d-none");
        if (mobileButtons) mobileButtons.classList.remove("d-flex");
  
        if (removeFooter && footer) {
          footer.classList.remove("d-flex");
          footer.classList.add("d-none");
        }
    
        if (aside) {
          aside.classList.add("d-none");
          aside.classList.remove("d-flex");
        }
    
        if (wrapper) wrapper.classList.add("p-0");
        if (content) content.classList.add("pt-0");
        if (stickyToolbar) stickyToolbar.classList.add("d-none");
        if (footer) footer.classList.add("left-0");
        // Show the standard UI elements on module exit
      }
      
      return () => {
        if (header) header.classList.remove("d-none");
        if (mobileButtons) mobileButtons.classList.add("d-flex");
        if (mobileButtons) mobileButtons.classList.remove("d-none");
        
        if (aside) {
          aside.classList.add("d-flex");
          aside.classList.remove("d-none");
        }
        if (wrapper) wrapper.classList.remove("p-0");
        if (content) content.classList.remove("pt-0");
        if (stickyToolbar) stickyToolbar.classList.remove("d-none");
        if (footer) {
          footer.classList.remove("left-0");
          footer.classList.add("d-flex");
          footer.classList.remove("d-none");
        }
      };
    }, [header, aside, wrapper, content, stickyToolbar, footer]);
  };