/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen } from "../_metronic/layout";
import i18n from "./lang/i18n";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

function App({ store, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* Render routes with provided `Layout`. */}
            <Routes />
        </BrowserRouter>
      </React.Suspense>
  
    </Provider>
  );
}

export default withAITracking(reactPlugin, App);
