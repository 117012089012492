import React, { useEffect, useState } from "react";
import { BootstrapTableExtended } from "../../../table";
import { tableDefaultSizePerPageList, tableDefaultKeyColumn } from "../../../table/helpers";
import * as columnFormatters from "../../../table/column-formatters";
import { DeleteItemDialog } from "./dialogs/DeleteItemDialog";
import { AddItemsDialog } from "./dialogs/AddItemsDialog";

import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";

export function Table({
  viewOnly,
  label,
  name,
  disabled,
  modelName,
  columns,
  keyField = tableDefaultKeyColumn,
  sizePerPageList = tableDefaultSizePerPageList,
  customModalClass,
  onChangeCalback,
  customClass,
  customStyle,
  onInit,
  withErrorFeedback = true,

  addDialogColumns = columns,
  addDialogKeyField = keyField,
  addDialogGetItemsAction,
  addDialogGetItemsSelector,
  addDialogIsItemsLoadingSelector,

  onChange,
  onDelete,

  addDialogFilters,
  addDialogFiltersIntialValues,
}) {
  const { initialValues, setFieldValue } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field, meta] = useField(name);
  const { value } = field;

  const [id, setId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const onDefaultChange = value => {
    setFieldValue(name, value);
    if (onChangeCalback) {
      onChangeCalback(value);
    }
  };

  const addItemsHandler = onChange
    ? onChange
    : vals => {
        onDefaultChange([...(value ?? []), ...vals]);
      };

  const onDeleteHandler = onDelete
    ? onDelete
    : () => {
        onDefaultChange(value.filter(x => x[keyField] !== id));
      };

  const isTableDisabled = disabled || (isDisabled && isDisabled(name));
  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          <DeleteItemDialog
            modelName={modelName}
            id={id}
            show={deleting}
            onHide={() => setDeleting(false)}
            onDeleteHandler={onDeleteHandler}
          />
          <AddItemsDialog
            modelName={modelName}
            tableColumns={addDialogColumns}
            keyField={addDialogKeyField}
            sizePerPageList={sizePerPageList}
            customClass={customModalClass}
            show={adding}
            disabled={viewOnly}
            onHide={() => setAdding(false)}
            addItemsHandler={addItemsHandler}
            getItemsAction={addDialogGetItemsAction}
            getItemsSelector={addDialogGetItemsSelector}
            isItemsLoadingSelector={addDialogIsItemsLoadingSelector}
            filters={addDialogFilters}
            filtersIntialValues={addDialogFiltersIntialValues}
            excludedIds={value ? value.map(x => x[keyField]) : []}
            excludeSelf={initialValues.id}
          />
          <fieldset>
            {label && <legend>{label}</legend>}
            <div className="table-header">
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() => setAdding(true)}
                disabled={isTableDisabled || viewOnly}
              >
                {`Add`}
              </button>
              {withErrorFeedback && meta.touched && (
                <div className="table-label invalid-feedback d-block">{meta.error}</div>
              )}
            </div>
            <BootstrapTableExtended
              columns={[
                ...columns,
                columnFormatters.deleteColumn(
                  id => {
                    setId(id);
                    setDeleting(true);
                  },
                  keyField,
                  isTableDisabled || viewOnly,
                ),
              ]}
              entities={value}
              keyField={keyField}
            />
          </fieldset>
        </>
      )}
    </>
  );
}
