import { assetModelsSlice } from "./assetModelsSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get } from "../../../../../api/requester";
import * as fields from "../../pages/assetModels/edit/constants";
import { IEditItem, IValues } from "./_interfaces";
import { Dispatch } from "@reduxjs/toolkit";
import { constructInitialFilters } from "../../../../components/common/filters/utils";

const { actions } = assetModelsSlice;
export const moduleUrl = "/api/assetmanagement/assetmodels";

export const setValidationErrors = actions.setValidationErrors;

export const getAssets = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const saveAsset = (asset: {}, id: string | null) =>
  defaultActions.saveItem(
    moduleUrl,
    actions,
    asset,
    id,
    refineDataForSaving,
    'multipart/form-data;boundary="boundary"',
  );

export const getAsset = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);

export const deleteAsset = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearArticle = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

/////////////////////////////
const refineDataForTabs = (data: IEditItem) => {
  return {
    id: data.id,
    [fields.GENERAL]: {
      [fields.GENERAL_CODE]: data[fields.GENERAL_CODE],
      [fields.GENERAL_DESCRIPTION]: data[fields.GENERAL_DESCRIPTION],
      [fields.GENERAL_MODEL]: data[fields.GENERAL_MODEL],
      [fields.GENERAL_TYPE_ID]: data[fields.GENERAL_TYPE_ID],
      [fields.GENERAL_SUB_TYPE_ID]: data[fields.GENERAL_SUB_TYPE_ID],
      [fields.GENERAL_BRAND_ID]: data[fields.GENERAL_BRAND_ID],
      [fields.GENERAL_EXT_WIDTH]: data[fields.GENERAL_EXT_WIDTH],
      [fields.GENERAL_EXT_DEPTH]: data[fields.GENERAL_EXT_DEPTH],
      [fields.GENERAL_EXT_HEIGHT]: data[fields.GENERAL_EXT_HEIGHT],
      [fields.GENERAL_INT_WIDTH]: data[fields.GENERAL_INT_WIDTH],
      [fields.GENERAL_INT_DEPTH]: data[fields.GENERAL_INT_DEPTH],
      [fields.GENERAL_INT_HEIGHT]: data[fields.GENERAL_INT_HEIGHT],
      [fields.GENERAL_SHELF_WIDTH]: data[fields.GENERAL_SHELF_WIDTH],
      [fields.GENERAL_SHELF_DEPTH]: data[fields.GENERAL_SHELF_DEPTH],
      [fields.GENERAL_DOORS]: data[fields.GENERAL_DOORS],
      [fields.GENERAL_SHELVES]: data[fields.GENERAL_SHELVES],
      [fields.GENERAL_POWER]: data[fields.GENERAL_POWER],
      [fields.GENERAL_PRICE]: data[fields.GENERAL_PRICE],
      [fields.VISUALIZATION_TYPE_CODE]: data[fields.VISUALIZATION_TYPE_CODE],
      [fields.INCLUDE_IN_DIAGRAM]: Boolean(data[fields.VISUALIZATION_TYPE_CODE] === fields.ASSET_MODEL_NONE),
    },
    [fields.IMAGES]: data[fields.IMAGES],
  };
};

/////////////////////////////
const refineDataForSaving = (data: IValues) => {
  const files: File[] = [];

  const createAssetModelImages: IValues["createAssetModelImages"] = [];
  const updateAssetModelImages: IValues["updateAssetModelImages"] = [];
  if (data.assetModelImages) {
    data.assetModelImages.forEach(a => {
      if (a && a.action === "create") {
        a.file && files.push(a.file)

        createAssetModelImages.push({
          fileName: a.file?.name || "n/a",
          isDefault: a.isDefault,
          [fields.IS_FOR_DIAGRAM_IMAGE]: a[fields.IS_FOR_DIAGRAM_IMAGE]

        });
      }
    });
    data.assetModelImages.forEach(a => {
      if (a && a.action === "update") {
        updateAssetModelImages.push({
          id: a.id,
          isDefault: a.isDefault,
          [fields.IS_FOR_DIAGRAM_IMAGE]: a[fields.IS_FOR_DIAGRAM_IMAGE]
        });
      }
    });
  }

  const form = {
    ...(data.id && { id: data.id }),
    [fields.GENERAL_CODE]: data.general.code,
    [fields.GENERAL_DESCRIPTION]: data.general.description,
    [fields.GENERAL_MODEL]: data.general.model,
    [fields.GENERAL_TYPE_ID]: data.general.assetModelTypeId,
    [fields.GENERAL_SUB_TYPE_ID]: data.general.assetModelSubTypeId,
    [fields.GENERAL_BRAND_ID]: data.general.assetModelBrandId,
    [fields.GENERAL_EXT_WIDTH]: data.general.exteriorWidth,
    [fields.GENERAL_EXT_DEPTH]: data.general.exteriorDepth,
    [fields.GENERAL_EXT_HEIGHT]: data.general.exteriorHeight,
    [fields.GENERAL_INT_WIDTH]: data.general.interiorWidth,
    [fields.GENERAL_INT_DEPTH]: data.general.interiorDepth,
    [fields.GENERAL_INT_HEIGHT]: data.general.interiorHeight,
    [fields.GENERAL_SHELF_WIDTH]: data.general.shelfWidth,
    [fields.GENERAL_SHELF_DEPTH]: data.general.shelfDepth,
    [fields.GENERAL_DOORS]: data.general.numberOfDoors,
    [fields.GENERAL_SHELVES]: data.general.numberOfShelves,
    [fields.GENERAL_POWER]: data.general.power,
    [fields.GENERAL_PRICE]: data.general.price,
    createAssetModelImages,
    updateAssetModelImages,
    deleteAssetModelImages: data.deleteAssetModelImages,
  };

  const formData = new FormData();
  formData.append('command', JSON.stringify(form));

  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData
};

export const getFilters = () => async (dispatch:Dispatch) => {
  try {
    const assetmodeltypes:any[] = await get("api/assetmanagement/assetmodeltypes/complexSearch");
    const assetmodelbrands:any[] = await get("api/assetmanagement/assetmodelbrands/complexSearch");

    dispatch(actions.setFilter(
      constructInitialFilters([
        ...(assetmodeltypes || []),
        ...(assetmodelbrands || []),
      ])
    ));

    return true
  } catch (error) {

  }

  return false
}
export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);
