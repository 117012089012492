import React, { FC, useEffect, useState } from "react";
import { Portal } from "react-portal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { useHideThemeElements } from "../../../app/common/_customHooks/useHideThemeElements";

const animationStartClass = "dialog-shown";
const animationEndClass = "dialog-hidden";

type IProps = {
  isLoading: boolean;
  text: string;
  customClasses?: string[];
  hideUI?: boolean;
}

export const LoadingDialog:FC<IProps> = ({ isLoading, text, customClasses = [], hideUI = false }) => {
  useHideThemeElements(false, hideUI)

  const initCssClasses = [
    "dialog",
    "dialog-default",
    "dialog-loader",
    "dialog-top-center",
    ...customClasses,
  ];

  const [cssClasses, setCssClasses] = useState<string[]>(initCssClasses);

  useEffect(() => {
    // for animation start should toggle 'dialog-hidden' css class
    setCssClasses(initCssClasses);
      const cssClassForAdding = isLoading
        ? animationStartClass
        : animationEndClass;
      setCssClasses([...initCssClasses, cssClassForAdding]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    <>
      {isLoading && (
        <>
          <Portal node={document && document.getElementById("layout-portal")}>
            <div className={cssClasses.join(" ")}>{text}</div>
          </Portal>
          <Portal node={document && document.getElementById("kt_content")}>
            <div className="fade content-backdrop show">
            </div>
            <span className="content-backdrop-loader">
              <SVG
                src={toAbsoluteUrl("/media/loading/loading.svg")}
              />
            </span>
          </Portal>
        </>
      )}
    </>
  );
}
