import { RootState } from "../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.webShop.itemsLoading;
export const isItemActionLoading = (state:RootState) => state.webShop.actionsLoading;
export const getItems = (state:RootState) => state.webShop.items;
export const getItemsSelect = (state:RootState) => state.webShop.itemForEdit;
export const getItemsCount = (state:RootState) => state.webShop.itemsCount;
export const getItemForEdit = (state:RootState) => state.webShop.itemForEdit;

export const errorAction = (state: RootState) => state.webShop.errorAction;
export const getError = (state:RootState) => state.webShop.error;
export const validationErrors = (state: RootState) => state.webShop.validationErrors;

export const getFilters = (state: RootState) => state.webShop.filters;

export const getCustomersLoading = (state:RootState) => state.webShop.customersLoading;
export const getCustomers = (state:RootState) => state.webShop.customers;

export const getWholesalersLoading = (state:RootState) => state.webShop.wholesalersLoading;
export const getWholesalers = (state:RootState) => state.webShop.wholesalers;

export const getOrderTypesLoading = (state:RootState) => state.webShop.orderTypesLoading;
export const getOrderTypes = (state:RootState) => state.webShop.orderTypes;

export const getArticlesLoading = (state:RootState) => state.webShop.articlesLoading;
export const getArticles = (state:RootState) => state.webShop.articles;

export const getOrderItemsLoading = (state:RootState) => state.webShop.orderItemsLoading;
export const getPrevCartItems = (state:RootState) => state.webShop.prevCartItems;

export const getSelectedCustomerID = (state:RootState) => state.webShop.selectedCustomerId;
