import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import { Error } from "./Error";
import { RadioButton } from "./RadioButton";

export function RadioButtonGroup({
  name,
  label="",
  disabled=false,
  items,
  onChangeCalback,
  withErrorFeedback = true,
  customClass="",
  customFieldsetClass="",
  customStyle={},
  itemColSize = 2,
  isSet = true,
  onInit=() => {},
  ...props
}) {
  const { initialValues, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);
  const { value } = field;

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const handleChange = event => {
    setFieldValue(field.name, event.target.id);
    if (onChangeCalback) {
      onChangeCalback(event.target.id);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const renderItems = () =>
    items.map(item => {
      const itemsField = {
        onBlur: handleBlur,
        value: value,
      };
      return (
        <div className={`col-lg-${itemColSize}`} key={item.value}>
          <RadioButton
            name={item.value}
            id={item.value}
            key={item.value}
            onChange={handleChange}
            label={item.label}
            {...itemsField}
            disabled={disabled || (isDisabled && isDisabled(name))}
          />
        </div>
      );
    });

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <div className={customClass} style={customStyle} data-testid="radio-wrapper">
          {isSet ? (
            <fieldset className={customFieldsetClass}>
              {label && <legend>{label}</legend>}
              <div className="row">{renderItems()}</div>
              {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
            </fieldset>
          ) : (
            <>
              {label && <legend>{label}</legend>}
              <div className="row">{renderItems()}</div>
              {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
            </>
          )}
        </div>
      )}
    </>
  );
}
