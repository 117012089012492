import { territoryAssignmentsSlice } from "./territoryAssignmentsSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

export const QPARAMS_CHANGED = "territoryAssignments/QParamsChanged";
const { actions } = territoryAssignmentsSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "TerritoryAssignments_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("TerritoryAssignments_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "TerritoryAssignments_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("TerritoryAssignments_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("TerritoryAssignments_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "TerritoryAssignments_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "description"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("TerritoryAssignments_Put", actions);

export const fetchTerritoryAssignmentTerritoriesSwagger = defaultActions.fetchCustomItemsSwagger(
  "TerritoryAssignments_GetTerritoryAssignmentTerritories",
  actions,
  constants.TERRITORY_ASSIGNMENT_TERRITORIES,
  constants.TERRITORY_ASSIGNMENT_TERRITORIES_LOADING,
);

export const fetchTerritoryAssignmentUsersSwagger = defaultActions.fetchCustomItemsSwagger(
  "TerritoryAssignments_GetTerritoryAssignmentUsers",
  actions,
  constants.TERRITORY_ASSIGNMENT_USERS,
  constants.TERRITORY_ASSIGNMENT_USERS_LOADING,
);

export const clearErrors = () => defaultActions.clearError(actions);
