import { getIn } from "formik";

export const getFieldCSSClasses = (touched, errors, name, customClass) => {
  let touchedField = getTouched(touched, name);
  let errorField = getError(errors, name);

  const classes = ["form-control", customClass];
  if (touchedField && errorField) {
    classes.push("is-invalid");
  }
  if (touchedField && !errorField) {
    classes.push("is-valid");
  }
  return classes.join(" ");
};

export const getValue = (nestedObj, propName, propDevider) => {
  return propName.split(propDevider).reduce((obj, key) => (obj && obj[key] ? obj[key] : undefined), nestedObj);
};

export const getTouched = (nestedObj, propName) => {
  const touched = getIn(nestedObj, propName);
  if (typeof touched === "object") return "id" in touched ? touched.id : false;
  else return touched;
};

export const getError = (nestedObj, propName) => {
  const error = getIn(nestedObj, propName);
  return typeof error === "object" && "id" in error ? error.id : error;
};
