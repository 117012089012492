/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { shallowEqual } from "react-redux";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { EditTabForm } from "./EditTabForm";
import { ErrorDialog } from "../ErrorDialog";
import stubFalse from "lodash/stubFalse";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import CustomModal from "../../../../../app/components/common/CustomModal";
import { useAppDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";

export function EditItem({
  id,
  title,
  fetchItemAction,
  createItemAction,
  updateItemAction,
  getErrorSelector,
  getItemForEditSelector,
  isItemActionLoadingSelector,
  intialValues,
  validationSchema,
  initialTouched,
  isWizard,
  selectedTabIndex,
  children,
  onReset,
  setValidationErrors,
  getValidationErrors,
  tabsWithValidations,
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dirty, setDirty] = useState(false);
  const [showError, setShowError] = useState(stubFalse);
  const [showModal, setShowModal] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [validationErrorTabIndex, setValidationErrorTabIndex] = useState(false);
  const validationErrors = useSelector(getValidationErrors || (() => ""));
  const hasValidationErrors = Object.keys(validationErrors).length;
  const { itemLoading, itemForEdit, error } = useSelector(state => {
    return {
      itemLoading: isItemActionLoadingSelector(state),
      itemForEdit: getItemForEditSelector(state),
      error: getErrorSelector(state),
    };
  }, shallowEqual);
  const item = id ? itemForEdit || intialValues : intialValues;

  useEffect(() => {
    if (id) dispatch(fetchItemAction(id));
  }, [fetchItemAction, id, dispatch]);

  useEffect(() => {
    if (error && error.length > 0) setShowError(true);
  }, [error]);

  useEffect(() => {
    const tabsWithErrors = Object.keys(validationErrors);

    if (saveClicked && tabsWithValidations) {
      tabsWithValidations.every((tab, index) => {
        if (tabsWithErrors.includes(tab)) {
          setValidationErrorTabIndex(index);
          return false;
        } else {
          return true;
        }
      });
    }

    if (!hasValidationErrors) {
      setSaveClicked(false);
    }
  }, [validationErrors, hasValidationErrors, saveClicked, tabsWithValidations]);

  const saveItem = values => {
    if (!id) {
      dispatch(createItemAction(values)).then(res => {
        if (res) backToItemsList();
        else setShowError(true);
      });
    } else {
      dispatch(updateItemAction(id, values)).then(res => {
        if (res) backToItemsList();
        else setShowError(true);
      });
    }
  };

  const btnRef = useRef();
  const saveItemClick = () => {
    if (btnRef && btnRef.current) {
      setSaveClicked(true);
      btnRef.current.click();
    }
  };

  const btnResetRef = useRef();
  const resetClick = () => {
    if (btnResetRef && btnResetRef.current) {
      if (onReset) onReset(item);
      btnResetRef.current.click();
    }
  };

  const backToItemsList = () => {
    resetClick();
    navigate(-1)
  };

  return (
    <>
      <Card className={`mb-0 ${itemLoading ? "not-allowed" : null}`}>
        {itemLoading && <ModalProgressBar />}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={() => (dirty ? setShowModal(true) : backToItemsList())}
              className="btn btn-light"
              disabled={itemLoading}
            >
              <i className="fa fa-arrow-left"></i>
              Back
            </button>
            {!id && (
              <button className="btn btn-light ml-2" onClick={resetClick} disabled={itemLoading}>
                <i className="fa fa-redo"></i>
                Reset
              </button>
            )}
            {Object.keys(validationErrors).length ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="save-form-tooltip">
                    <p className="required-field-marker mb-0">Mandatory fields missing</p>
                  </Tooltip>
                }
              >
                <button type="submit" className="btn ml-2 btn-outline-warning" onClick={saveItemClick}>
                  Save <i className="flaticon-exclamation-1" style={{ fontSize: "1.1em", paddingRight: 0 }}></i>
                </button>
              </OverlayTrigger>
            ) : (
              <button
                type="submit"
                className={`btn btn-primary ml-2 d-flex justify-content-center align-items-center ${
                  itemLoading ? "pointer-events-none" : ""
                }`}
                onClick={saveItemClick}
                disabled={!dirty || itemLoading}
              >
                {itemLoading ? (
                  <>
                    <div
                      className="spinner spinner-white"
                      style={{ margin: "0 0.5rem 0 -0.3rem", width: "1.5rem" }}
                    ></div>
                    Processing
                  </>
                ) : (
                  <>Save</>
                )}
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={itemLoading ? "bg-white opacity-50 pointer-events-none" : null}>
          {error && <ErrorDialog error={error} show={showError} onHide={() => setShowError(false)} />}
          <EditTabForm
            item={item}
            btnResetRef={btnResetRef}
            btnRef={btnRef}
            saveItem={saveItem}
            validationSchema={validationSchema}
            initialTouched={initialTouched}
            isEdit={itemForEdit && id}
            isWizard={isWizard}
            selectedTabIndex={selectedTabIndex}
            setValidationErrors={setValidationErrors}
            validationErrorTabIndex={validationErrorTabIndex}
            saveClicked={saveClicked}
            setDirty={setDirty}
          >
            {children}
          </EditTabForm>
        </CardBody>
      </Card>

      <CustomModal
        centered
        showModal={showModal}
        title={"Are you sure?"}
        body={
          <h6 className="align-content-center mb-0">
            <span className="svg-icon svg-icon-3x svg-icon-primary mr-5">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
            </span>
            <span>All unsaved changes will be lost!</span>
          </h6>
        }
        buttons={[
          { variant: "secondary", onClick: () => setShowModal(false), text: "Close" },
          { variant: "primary", onClick: backToItemsList, text: "Yes" },
        ]}
      />
    </>
  );
}
