import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IEntities, IArticleDetails, IListArticle, IValues } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, IValues> {
  articlesListLoading: boolean;
  planogramDetailsLoading: boolean;
  realogramDetailsLoading: boolean;

  realogramArticlesList: IArticleDetails[];
  planogramArticlesList: IArticleDetails[];
  articlesList: IListArticle[];
}

const initialState: IState = {
  ...defaultInitialState,
  articlesListLoading: false,
  planogramDetailsLoading: false,
  realogramDetailsLoading: false,

  realogramArticlesList: [],
  planogramArticlesList: [],
  articlesList: [],
};

const reducers = {
  ...defaultReducers,
  setArticlesLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.articlesListLoading = action.payload;
  },
  setPlanogramDetailsLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.planogramDetailsLoading = action.payload;
  },
  setRealogramDetailsLoading: (state:IState, action:PayloadAction<boolean>) => {
    state.realogramDetailsLoading = action.payload;
  },

  setArticles: (state:IState, action:PayloadAction<IListArticle[]>) => {
    state.articlesList = action.payload;
  },
  setPlanogramArticles: (state:IState, action:PayloadAction<IArticleDetails[]>) => {
    state.planogramArticlesList = action.payload;
  },
  setRealogramArticles: (state:IState, action:PayloadAction<IArticleDetails[]>) => {
    state.realogramArticlesList = action.payload;
  },
  clearArticles: (state:IState, action:PayloadAction) => {
    state.realogramArticlesList = initialState.realogramArticlesList;
    state.planogramArticlesList = initialState.planogramArticlesList;
    state.articlesList = initialState.articlesList;
    state.articlesListLoading = initialState.articlesListLoading;
    state.planogramDetailsLoading = initialState.planogramDetailsLoading;
    state.realogramDetailsLoading = initialState.realogramDetailsLoading;
  }
};

export const stockAvailabilitySlice = createSlice({
  name: "stockAvailability",
  initialState,
  reducers,
});
