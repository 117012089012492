import { documentationSlice } from "./documentationSlice";
import * as defaultActions from "../../../../../redux/_common";
import { Dispatch } from "redux";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { get } from "../../../../../api/requester";
import { IEditItem, IValues } from "./_interfaces";
import * as fields from '../../pages/documentation/edit/constants'

const { actions } = documentationSlice;
export const moduleUrl = "/api/assetmanagement/assetmodeldocument";

export const setValidationErrors = actions.setValidationErrors;

export const getDocuments = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);
export const getDocument = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);

export const saveDocument = (document: {}, id: string | null) =>
    defaultActions.saveItem(
        moduleUrl,
        actions,
        document,
        id,
        (val:IValues) => refineDataForSave(val, id),
        'multipart/form-data;boundary="boundary"',
    );
export const deleteDocument = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearDocument = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

export const getFilters = () => async (dispatch:Dispatch) => {
    try {
        const assetmodels:any[] = await get("api/assetmanagement/assetmodels/complexSearch");
        const isAssigned:any[] = await get('/api/planogram/planograms/complexSearch');
  
        dispatch(actions.setFilter(
            constructInitialFilters([
                ...(isAssigned || []),
                ...(assetmodels || []),
            ])
        ));
  
    } catch (error) {
  
    }
}

export const setFilter = (filters: any) => defaultActions.setFilters(filters, actions);

const refineDataForTabs = (data:IEditItem):IValues => {
    return {
        [fields.ID]: data[fields.ID],
        [fields.GENERAL]: {
            [fields.TITLE]: data[fields.TITLE],
            [fields.FILE_LINK]: data[fields.FILE_LINK],
            [fields.FILE]: null,
            [fields.SIZE]: data[fields.SIZE],
            [fields.ASSIGNED_TO]: data.assetModels.map((d) => ({
                [fields.ID]: d[fields.ID],
                [fields.NAME]: d[fields.NAME],
                active: true,
                [fields.ASSET_DOCUMENT_ID]: d[fields.ASSET_DOCUMENT_ID]
            })), 
        },
    };
};

const refineDataForSave = (values:IValues, id:string | null) => {
    const formData = new FormData();
    let data:any = {
        [fields.TITLE]: values[fields.GENERAL][fields.TITLE],
        assetModelAssetModelDocuments: values[fields.GENERAL][fields.ASSIGNED_TO].map(({id}) => ({
            assetModelId: id
        })),
        ...(values[fields.GENERAL][fields.FILE]
            ? {
                createAssetModelDocumentImage: {
                    fileName: values[fields.GENERAL][fields.FILE]?.name,
                },
            }
            : {}),
    };

    if (id) {
        data = {
            ...data,
            [fields.ID]: id,
            [fields.DELETE_ASSIGNMENTS]: values[fields.DELETE_ASSIGNMENTS]?.map(({assetModelAssetModelDocumentId}) => ({
                id: assetModelAssetModelDocumentId
            })),
        }
    }

    formData.append('command', JSON.stringify(data));
    if (values[fields.GENERAL][fields.FILE]) {
        formData.append('file', values[fields.GENERAL][fields.FILE] as File);
    }

    return formData;
}