import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.customerTypes.listLoading;
export const isItemActionLoading = (state:RootState) => state.customerTypes.actionsLoading;
export const getItems = (state:RootState) => state.customerTypes.entities;
export const getItemsSelect = (state:RootState) => state.customerTypes.entitiesSelect;
export const getItemsCount = (state:RootState) => state.customerTypes.totalCount;
export const getItemForEdit = (state:RootState) => state.customerTypes.itemForEdit;
export const getError = (state:RootState) => state.customerTypes.error;
export const validationErrors = (state: RootState) => state.customerTypes.validationErrors;
