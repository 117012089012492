import SwaggerClient from "swagger-client";
import { getCurrentAccessToken, getCurrentUserOrganizationUnit } from "../app/user-access/organizationUnitStorage";

SwaggerClient.http.withCredentials = true;
const specUrl = process.env.REACT_APP_SWAGGER_SPEC_URL || 'https://api.development.amep-eviden.com/swagger/v1/swagger.json';

export let swaggerClient = specUrl
  // Fetch Swagger Spec
  ? new SwaggerClient(specUrl)
  // Mock Swagger Fetch when Tests are running
  : new Promise((resolve, reject) => {
    resolve({ client: { Swagger: {} } });
  });

/**
 *  API calls template
 * @params operationId - swagger operation name _ method: "RtrMonitoring_Get"
 * @params requestData - body, query params, id etc.
 * @returns Server response
 */
export const apiCall = async (operationId, requestData = {}) => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  const { body, ...parameters } = requestData;
  parameters["OrganizationUnit"] = userOrgUnit;

  const client = await swaggerClient;
  return client.execute({
    spec: client.spec,
    operationId,
    parameters,
    ...(body && { requestBody: body }),
    securities: {
      authorized: {
        bearerAuth: response.accessToken,
      },
    },
    responseContentType: "application/json",
  });
};
