import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEntities, IValues } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, IValues> {}

const initialState: IState = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const documentationSlice = createSlice({
  name: "Documentation",
  initialState,
  reducers,
});
