export const SURVEYS_QPARAMS_CHANGED = "surveys/QParamsChanged";
export const SURVEY_ANSWER_TYPES_CHANGED = "surveys/SurveyAnswerTypesChanged";

export const SURVEY_ANSWER_TYPES_ENTITY_TYPE = "surveyAnswerTypes";
export const SURVEY_ANSWER_TYPES_LOADING_TYPE = "surveyAnswerTypesLoading";

export const SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE = "surveyAnswerSubTypes";
export const SURVEY_ANSWER_SUB_TYPES_LOADING_TYPE = "surveyAnswerSubTypesLoading";

export const SURVEY_ARTICLES = "surveyArtiles";
export const SURVEY_ARTICLES_LOADING = "surveyArticlesLoading";

export const SURVEY_SECTION_TYPES_CHANGED = "surveys/SurveySectionTypesChanged";
export const SURVEY_SECTION_TYPES_ENTITY_TYPE = "surveySectionTypes";
export const SURVEY_SECTION_TYPES_LOADING_TYPE = "surveySectionTypesLoading";
export const SURVEY_DYNAMIC_OPERATORS_TYPES_CHANGED = "surveys/SurveyDynamicOperatorsChanged";
export const SURVEY_DYNAMIC_OPERATORS_ENTITY_TYPE = "surveyDynamicOperators";
export const SURVEY_DYNAMIC_OPERATORS_TYPES_LOADING_TYPE = "surveyDynamicOperatorsLoading";
export const SURVEY_VALIDATION_ERRORS = "validationErrors";
