/* eslint-disable no-restricted-imports */
import { MessageDialog } from "./MessageDialog";

export function ErrorDialog({ error, show, onHide }) {

  return (
    <MessageDialog
      title="Error"
      message={error}
      colorMessage="#F64E60"
      show={show}
      onHide={onHide}
    />
  );
}
