import * as constants from "./constants";

export const isItemsLoading = state => state.territoryAssignments.listLoading;
export const isItemActionLoading = state => state.territoryAssignments.actionsLoading;
export const getItems = state => state.territoryAssignments.entities;
export const getItemsSelect = state => state.territoryAssignments.entitiesSelect;
export const getItemsCount = state => state.territoryAssignments.totalCount;
export const getItemForEdit = state => state.territoryAssignments.itemForEdit;
export const getError = state => state.territoryAssignments.error;
export const validationErrors = (state) => state.territoryAssignments.validationErrors;

export const getTerritoryAssignmentTerritories = state =>
  state.territoryAssignments[constants.TERRITORY_ASSIGNMENT_TERRITORIES];
export const getTerritoryAssignmentTerritoriesLoading = state =>
  state.territoryAssignments[constants.TERRITORY_ASSIGNMENT_TERRITORIES_LOADING];

export const getTerritoryAssignmentUsers = state => state.territoryAssignments[constants.TERRITORY_ASSIGNMENT_USERS];
export const getTerritoryAssignmentUsersLoading = state =>
  state.territoryAssignments[constants.TERRITORY_ASSIGNMENT_USERS_LOADING];
