import React, { useEffect } from "react";
import { Error } from "./Error";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import RequiredFieldMarker from "./RequiredFieldMarker";

export function Checkbox({
  name,
  label="",
  withErrorFeedback = true,
  onChangeCalback=(val) => {},
  customClass="",
  customStyle={},
  labelStyle = {},
  labelClass = "",
  disabled = false,
  onInit=() => {},
  value = false,
  isRequired = false,
  addToRefs = (el) => {},
  ...props
}) {
  const { initialValues, errors, touched, setFieldTouched } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  const onChange = e => {
    field.onChange(e);
    setTimeout(() => setFieldTouched(field.name, true), 0)
    if (onChangeCalback) {
      onChangeCalback(e.target.checked);

    }
  };

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          <div className={customClass} style={customStyle}>
            <label className={`checkbox checkbox-lg checkbox-single ${disabled ? "opacity-50 not-allowed": ""} ${labelClass}`} style={{ top: "7px", ...labelStyle }}>
              <input
                data-testid={name}
                id={field.name}
                type="checkbox"
                {...{ ...field, onChange, ...props }}
                ref={addToRefs}
                checked={field.value || value}
                disabled={disabled || (isDisabled && isDisabled(name))}
              />
              <span />
            </label>
            {label && (
              <label htmlFor={field.name} style={{ paddingLeft: "7px" }} className={disabled ? "opacity-70 not-allowed": ""}>
                {label}
                <RequiredFieldMarker name={name} isRequired={isRequired} />
              </label>
            )}
          </div>
          {withErrorFeedback && <Error name={field.name} errors={errors} touched={touched} />}
        </>
      )}
    </>
  );
}
