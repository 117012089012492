import { RootState } from "../../../../../redux/store";

export const assetModelBrandsLoading = (state: RootState) => state.assetModelBrands.itemsLoading;
export const assetModelBrandActionLoading = (state: RootState) => state.assetModelBrands.actionsLoading;
export const dropDownLoading = (state: RootState) => state.assetModelBrands.dropDownLoading;

export const assetModelBrands = (state: RootState) => state.assetModelBrands.items;
export const assetModelBrandsCount = (state: RootState) => state.assetModelBrands.itemsCount;
export const assetModelBrandForEdit = (state: RootState) => state.assetModelBrands.itemForEdit;
export const dropDownValues = (state: RootState) => state.assetModelBrands.dropDownItems;

export const error = (state: RootState) => state.assetModelBrands.error;
export const errorAction = (state: RootState) => state.assetModelBrands.errorAction;
export const dropDownError = (state: RootState) => state.assetModelBrands.dropDownError;

export const validationErrors = (state: RootState) => state.assetModelBrands.validationErrors;

export const filters = (state: RootState) => state.assetModelBrands.filters;