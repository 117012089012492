/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import SvgIcon from "../../../../app/components/common/SvgIcon";

export function MessageDialog({ title, message, colorMessage = "black", show, onHide }) {
  // if no message text we should close modal
  useEffect(() => {
    if (!message) {
      onHide();
    }
  }, [onHide, message]);

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <div
          data-testid="close-modal"
          className="btn btn-icon btn-sm btn-hover-primary ml-4"
          onClick={onHide}
        >
          <SvgIcon
            iconLocation="/media/svg/icons/Navigation/Close-2.svg"
            size="4"
            bootstrapColor="grey"
            title="Close"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(message) ? (
          message.map((msg, index) => (
            <p key={"message" + index}>
              <span style={{ color: colorMessage }}>{msg}</span>
            </p>
          ))
        ) : (
          <span style={{ color: colorMessage }}>{message}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
