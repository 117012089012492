import { slice } from "./slice";
import * as defaultActions from "../../../redux/_helpers";
import * as constants from "./constants";
import { get } from "../../../api/requester";
import { Access } from "../usePageAccessHook";

const { actions } = slice;

export const fetchMenuItemsSwagger = defaultActions.fetchCustomItemsSwagger(
  "User_GetCurrentUserMenuItems",
  actions,
  constants.MENU_ITEMS,
  constants.MENU_ITEMS_LOADING,
);

export const getUserOrgUnits = () => async dispatch => {
  try {
    const res = await get(constants.API.getOrgUnits);
    dispatch(actions.setUserOrgUnits(res));
    return res;
  } catch (errors) {
    const message = defaultActions.getResponseErrorMessage(errors, "Error occurred!");
    console.error(message);
  }
};

export const getUserMenu = () => async dispatch => {
  try {
    const res = await get(constants.API.getUserMenu);
    dispatch(actions.setUserMenus(res));
    return res;
  } catch (errors) {
    const message = defaultActions.getResponseErrorMessage(errors, "Error occurred!");
    console.error(message);
  }
};

export const fetchSubMenuItemsSwagger = menuID => async dispatch => {
  try {
    const items = await get(constants.API.getUserMenu, { ParentId: menuID });
    dispatch(actions.fetchSubMenuItems({ items, menuID }));
    return items;
  } catch (errors) {
    const message = defaultActions.getResponseErrorMessage(errors, "Error occurred!");
    console.error(message);
  }

  /*const operationID = "User_GetCurrentUserMenuItems";
  debugger;
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  const params = {
    ParentId: menuID,
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: constants.SUB_MENU_ITEMS_LOADING }));
  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(data => {
        const items = data.body;
        dispatch(actions.fetchSubMenuItems({ items, menuID }));
      })
      .catch(errors => {
        debugger;
        console.log(errors);
        const error = defaultActions.requestErrorHandling(errors, "Error occurred during fetching the item!");
        dispatch(actions.catchError({ error, callType: constants.SUB_MENU_ITEMS_LOADING }));
      }),
  );*/
};

export const setTabMenuItems = items => dispatch => {
  dispatch(actions.setTabMenuItems({ items }));
};

export const fetchOrganizationUnitsSwagger = defaultActions.fetchCustomItemsSwagger(
  "User_GetCurrentUserOrganizationUnits",
  actions,
  constants.ORG_UNITS,
  constants.ORG_UNITS_LOADING,
);

export const getUserAccess = async pageUrl => {
  try {
    const res = await get(constants.API.getPageAccess, { Url: pageUrl });
    if (typeof res === "number") {
      return res === 0 ? Access.WRITE : Access.READ;
    } else {
      return null;
    }
  } catch (errors) {
    const message = defaultActions.getResponseErrorMessage(errors, "Error occurred!");
    console.error(message);
  }
};

export const setUserAccess = access => dispatch => {
  dispatch(actions.setUserAccess(access));
};
