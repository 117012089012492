import { IProducts, ISection, IShelf, IUpdateAssignment, IUpdatedProduct } from "../../_interfaces/builder";
import { IAssignment } from "../../_interfaces/state";
import * as htmlToImage from 'html-to-image';
import {
    assetModelId,
    customerId,
    customerSegmentId,
    equipmentId,
    planogramAssignmentId,
    MIN_DIMENSION,
    SCALE_FACTOR,
    IMAGE_MAX_WIDTH,
    SHELF_DEFAULT_COLOR,
    SHELF_DEFAULT_ALIGNMENTS,
    SECTION_DEFAULT_COLOR
} from "../../constants";
import { IDiagramEquipment } from "../../../AssetManagement/_redux/equipment/_interfaces";

export const scaleValueForBE = (value:number):number => {
    return (Math.round((value / SCALE_FACTOR + Number.EPSILON) * 100) / 100)
}

export const resetPlanogramObject = (planogram:any) => {
    return {
        ...planogram,
        is3D: planogram.is3D ?? false,
        dimensions: {
            width: Math.max(Number(planogram.dimensions.width), MIN_DIMENSION) * SCALE_FACTOR,
            height: Math.max(Number(planogram.dimensions.height), MIN_DIMENSION) * SCALE_FACTOR,
            depth: Math.max(Number(planogram.dimensions.depth), MIN_DIMENSION) * SCALE_FACTOR
        },
        sections: planogram.sections.map((section:ISection, order:number) => {
            return {
                ...section,
                original: true,
                order: order+1,
                updated: false,
                color: section.color && section.color !== null ? section.color : SECTION_DEFAULT_COLOR,
                thickness: section.thickness * SCALE_FACTOR,
                innerDimensions: {
                  width: Math.max(Number(section.dimensions.width), MIN_DIMENSION) * SCALE_FACTOR,
                  height: Math.max(Number(section.dimensions.height), MIN_DIMENSION) * SCALE_FACTOR,
                  depth: Math.max(Number(section.dimensions.depth), MIN_DIMENSION) * SCALE_FACTOR
                },
                dimensions: {
                  width: Math.max(Number(section.dimensions.width), MIN_DIMENSION) * SCALE_FACTOR + section.thickness * 2 * SCALE_FACTOR,
                  height: Math.max(Number(section.dimensions.height), MIN_DIMENSION) * SCALE_FACTOR + section.thickness * 2 * SCALE_FACTOR,
                  depth: Math.max(Number(section.dimensions.depth), MIN_DIMENSION) * SCALE_FACTOR + section.thickness * SCALE_FACTOR
                },
                shelves: section.shelves.map((shelf:any, shelfOrder:number) => ({
                    ...shelf,
                    order: shelfOrder+1,
                    sectionId: shelf.planogramSectionId,
                    color: shelf.color || SHELF_DEFAULT_COLOR,
                    alignment: shelf.alignment || SHELF_DEFAULT_ALIGNMENTS[0],
                    original: true,
                    updated: false,
                    thickness: shelf.thickness * SCALE_FACTOR,
                    dimensions: {
                      width: Math.max(Number(shelf.dimensions.width), section.dimensions.width) * SCALE_FACTOR,
                      height: Math.max(Number(Number(shelf.dimensions.height).toFixed(2)) * SCALE_FACTOR + shelf.thickness * SCALE_FACTOR, MIN_DIMENSION),
                      depth: Math.max(Number(shelf.dimensions.depth), section.dimensions.depth) * SCALE_FACTOR,
                    },
                    products: (shelf.products || []).map((product:any, productOrder:number) => ({
                        ...product,
                        order: productOrder+1,
                        articleId: product.productId,
                        articleCode: product.code || 'Empty',
                        articleName: product.name || 'Empty',
                        quantity: product.quantity ? Number(product.quantity) : null,
                        original: true,
                        top: product.top * SCALE_FACTOR,
                        down: product.down * SCALE_FACTOR,
                        left: product.left * SCALE_FACTOR,
                        right: product.right * SCALE_FACTOR,
                        marginRight: (product.marginRight || 0) * SCALE_FACTOR,
                        marginLeft: (product.marginLeft || 0) * SCALE_FACTOR,
                        dimensions: {
                            width: Number(product.width) * SCALE_FACTOR,
                            height: Number(product.height) * SCALE_FACTOR,
                            depth: Number(product.depth || MIN_DIMENSION) * SCALE_FACTOR,
                        }
                    }))
                }))
            }
        })
    }
}

const setAssignmentForCreation = (key:keyof IAssignment, field:string, assignment:IAssignment) => {
    if (key !== planogramAssignmentId) {
        return assignment[key].reduce((a:any[], e:any) => [
            ...a,
            { [field]: e.id }
        ], []);
    }

    return []
}

export const refineAssignment = (assignment:IAssignment):IUpdateAssignment => ({
    createPlanogramAssetModelAssignment: setAssignmentForCreation(assetModelId, 'assetModelId', assignment),
    createPlanogramEquipmentAssignment: setAssignmentForCreation(equipmentId, 'equipmentId', assignment),
    createPlanogramCustomerAssignment: setAssignmentForCreation(customerId, 'customerId', assignment),
    createPlanogramCustomerSegmentAssignment: setAssignmentForCreation(customerSegmentId, 'customerSegmentId', assignment),
});

export const createSections = (sections:ISection[], scale:number) => {
    let sectionX:number = 0;

    return sections.map((section, i) => {
        sectionX += i > 0 ? sections[i - 1].dimensions.width : 0;
        return createSection(section, i, sectionX, scale)
    });
}

export const createSection = (section:ISection, i:number, sectionX:number, scale:number) => {
    const { shelves, thickness } = section;
    let shelfY = thickness;
    
    return {
        order: i+1,
        color: section.color,
        dimensions: {
            width: scaleValueForBE(section.innerDimensions.width),
            depth: scaleValueForBE(section.innerDimensions.depth),
            height: scaleValueForBE(section.innerDimensions.height),
        },
        thickness: section.thickness / SCALE_FACTOR,
        createShelves: shelves.map((shelf, j) => {
            let shelfX = sectionX + thickness;
            shelfY += j > 0 ? shelves[j - 1].dimensions.height : 0;
            const {
                products,
                alignment,
                dimensions,
                thickness:shelfThickness
            } = shelf;
            const { width, height } = dimensions;
            const down = shelfY + (height - shelfThickness)

            if (alignment !== SHELF_DEFAULT_ALIGNMENTS[0]) {
                const productsTotalWidth = products?.reduce((a, e) => a + e.dimensions.width + e.marginLeft + e.marginRight, 0) || 0;
                if (alignment === SHELF_DEFAULT_ALIGNMENTS[1]) {
                    shelfX += (width - productsTotalWidth) / 2;
                } else if (alignment === SHELF_DEFAULT_ALIGNMENTS[2]) {
                    shelfX += width - productsTotalWidth;
                }
            }

            return createNewShelf(shelf, j, shelfX, down, scale)
        })
    }
};

const createNewShelf = (shelf:IShelf, i:number, shelfX:number, down: number, scale:number) => {
    let productX:number = shelfX;
    return {
        order: i+1,
        thickness: shelf.thickness / SCALE_FACTOR,
        color: shelf.color,
        alignment: shelf.alignment || SHELF_DEFAULT_ALIGNMENTS[0],
        dimensions: {
            width: scaleValueForBE(shelf.dimensions.width),
            depth: scaleValueForBE(shelf.dimensions.depth),
            height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
        },
        createProducts: (shelf.products || []).map((product:IProducts, j:number) => {
            let prevProductX = 0
            if (j > 0) {
                prevProductX =
                    shelf.products[j - 1].dimensions.width
                        + shelf.products[j - 1].marginRight
            }
            productX += product.marginLeft + prevProductX;
            return refineProductForCreation(product, j, down, productX, scale);
        })
    }
}

export const refineProductForCreation = (product:IProducts, i:number, down: number, productX:number, scale:number):IUpdatedProduct => {
    return {
        width: Math.max(Number(product.dimensions.width), MIN_DIMENSION) / SCALE_FACTOR,
        height: Math.max(Number(product.dimensions.height), MIN_DIMENSION) / SCALE_FACTOR,
        depth: Math.max(Number(product.dimensions.depth), MIN_DIMENSION) / SCALE_FACTOR,
        productId: product.articleId === '' ? null : product.articleId,
        order: i+1,
        quantity: product.quantity,
        top: scaleValueForBE((down - product.dimensions.height) * scale),
        down: scaleValueForBE(down * scale),
        left: scaleValueForBE(productX) * scale,
        right: scaleValueForBE((productX + product.dimensions.width) * scale),
        marginLeft: scaleValueForBE(product.marginLeft),
        marginRight: scaleValueForBE(product.marginRight),
    };
};

export const isItemUpdated = (item:IShelf | IProducts | IDiagramEquipment) => {
    return Boolean(item.updated || !item.original)
}


export const refineProductForUpdate = (product:IProducts, shelf:IShelf, down: number, productX:number, scale:number):IUpdatedProduct[] => {
    return isItemUpdated(product)
        ? [{
            ...(product.original ? {id: product.id} : {}),
            width: Math.max(Number(product.dimensions.width), MIN_DIMENSION) / SCALE_FACTOR,
            height: Math.max(Number(product.dimensions.height), MIN_DIMENSION) / SCALE_FACTOR,
            depth: Math.max(Number(product.dimensions.depth), MIN_DIMENSION) / SCALE_FACTOR,
            ...(product.original ? {planogramSectionShelfId: shelf.id || ""} : {}),
            productId: product.productId || product.articleId || null,
            quantity: product.quantity,
            order: product.order,
            top: scaleValueForBE((down - product.dimensions.height) * scale),
            down: scaleValueForBE(down * scale),
            left: scaleValueForBE(productX * scale),
            right: scaleValueForBE((productX + product.dimensions.width) * scale),
    
            marginLeft: scaleValueForBE(product.marginLeft),
            marginRight: scaleValueForBE(product.marginRight),
        }]
        : [];
}

export const getScaleFactor = (width:number):number => {
    const imageMaxConstant = Math.max(Math.floor(width / IMAGE_MAX_WIDTH), 1);
    let actualScaleFactor = (Math.round(((IMAGE_MAX_WIDTH / width) * imageMaxConstant * Math.max((1 - 0.1 * imageMaxConstant), 0.4) + Number.EPSILON) * 100) / 100);

    return actualScaleFactor;
} 

export const createPlanogramImage = async (title:string, width:number) => {
    let node = document.getElementById('equipment');

    if (node) {
        const filter = (node: HTMLElement) => {
            const exclusionClasses = [
                'down-resizer',
                'top-resizer-section',
                'down-resizer-section',
                'left-resizer-section',
                'right-resizer-section',
                'disabled-resizers',
                'depth-counter',
                'article-margin-right',
                'margin-preview-element',
            ];
            return !exclusionClasses.some((classname) => node.classList?.contains(classname));
        }

        const dataUrl = await htmlToImage.toJpeg(node, {
            pixelRatio: getScaleFactor(width),
            cacheBust: true,
            skipFonts: true,
            skipAutoScale: true,
            filter,
            width,
            canvasWidth: width,
            style: { background: "white" },
        });

        

        let byteStr = window.atob(dataUrl.split(',')[1])
        let mimeStr = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        let ab = new ArrayBuffer(byteStr.length)
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteStr.length; i++) {
            ia[i] = byteStr.charCodeAt(i);
        }

        const blob = new Blob([ab], {type: mimeStr})
        const imgFile = new File([blob], `${title.replaceAll(' ', '_')}_img.jpeg`, {type: blob.type})




        // const imageURL = URL.createObjectURL(new Blob([ab], {type: mimeStr}));
        // const link = document.createElement('a')
        // link.href = imageURL
        // link.download = 'image file name here'
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link);


        return imgFile;
    }


    return new File([new Blob()], `${title.replaceAll(' ', '_')}_img.jpeg`, {type: 'image/jpeg'});
}