/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useState } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { languages } from "../../../../../app/lang/languages";
import { useTranslation } from "react-i18next";
import SvgIcon from "../../../../../app/components/common/SvgIcon";

export function LanguageSelectorDropdown() {
  const { i18n } = useTranslation();
  const currentLanguage = languages.find(x => x.lang === i18n.language) || languages[0];
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      show={show}
      align="start"
      onClick={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-2">
          <SvgIcon
            iconLocation="/media/svg/icons/Home/Earth.svg"
            size="4"
            customClasses="mr-2"
            title="User Details"
            bootstrapColor="primary"
          />
          <span className="symbol symbol-35 symbol-light-light">
            <span className="symbol-label fw-400 w-100 p-3 text-uppercase text-primary">
              {currentLanguage.lang}
            </span>
          </span>
          {/* <img className="h-25px w-25px rounded" src={currentLanguage.flag} alt={currentLanguage.name} /> */}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        style={{ marginTop: "-1rem" }}
      >
        <ul className="navi navi-hover py-4">
          <li className="px-6 pointer-events-none text-muted">Language</li>
          <div className="dropdown-divider"/>
          {languages.map(language => (
            <li
              key={language.lang}
              className={clsx("navi-item", {
                active: language.lang === currentLanguage.lang,
              })}
            >
              <a
                href="#"
                onClick={() => {
                  i18n.changeLanguage(language.lang);
                  setShow(false);
                }}
                className="navi-link"
              >
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
