import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEditMalfunction, IMalfunction } from "../../_interfaces/reduxState";

const initialState: IDefaultState<IMalfunction, any, IEditMalfunction> = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const malfunctionsSlice = createSlice({
  name: "malfunctions",
  initialState,
  reducers,
});
