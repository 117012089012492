interface IProps {
  isRequired: any;
  name: string
}

const RequiredFieldMarker: React.FC<IProps> = ({isRequired, name}) => {

  if(!isRequired) return <></>

  const isFieldRequired = (isRequired: any, name: string) => {
    if (isRequired === true) return true // if no schema - set marker manually
    if(name && name.includes('.')) { // if the schema has nested schemas
      const names = name.split('.');
     
//TODO: refactor with recursive solution

        if(names[0].includes('[')) { // if the schema has nested array of schemas
          const parentName = names[0].split('[')[0]
          return !isRequired[parentName]?._subType?.fields[names[1]]?.spec.optional
        }

        if (names[1].includes('[')) {
          const childName = names[1].split('[')[0];
          return !isRequired[names[0]]?.fields[childName]?.spec?.optional
        }

        if(isRequired[names[0]]?.fields[names[1]]?.fields) {
          return !isRequired[names[0]]?.fields[names[1]]?.fields[names[2]]?.spec?.optional
        }

        return !isRequired[names[0]]?.fields[names[1]]?.spec.optional

    } else {
      return !isRequired[name]?.spec?.optional // if standard flat schema 
    }
  }

  return (
    <>
      {isFieldRequired(isRequired, name) && <span className='required'>{' *'}</span>}
    </>
  )
}

export default RequiredFieldMarker
