// General Info TAB
export const ID = 'id';
export const ASSORTMENT = 'assortmentTab';
export const ASSORTMENT_CODE = 'code';
export const ASSORTMENT_DESCRIPTION = 'description';
export const ASSORTMENT_ARTICLES = 'assortmentArticles';

// Articles TAB
export const ARTICLES ='articlesTab';
export const ARTICLES_ASSORTMENT ='assortmentArticles';
export const CREATE_ASSORTMENT_ARTICLES = 'createAssortmentArticles';



// ---------------------------------
export const TABS = [ASSORTMENT, ARTICLES];
