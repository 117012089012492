import React from "react";
import { /*useField,*/ useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import { InputGroup } from "react-bootstrap";
import { Input } from "./Input";

export function SearchField({
  name = "filter",
  placeholder = "Search in all fields...",
  withErrorFeedback = true,
  disabled = false,
  onChangeCalback,
  inputGroupClassName = 'mb-3',
  ...props
}) {
  const { submitForm } = useFormikContext();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  /*const [field] = useField(name);

  const onChange = e => {
    field.onChange(e);
    if (onChangeCalback) {
      onChangeCalback(e.target.value);
    }
  };*/

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          {props.label && (
            <label style={{ minHeight: '1rem' }}>
              {props.label}
            </label>
          )}
          <InputGroup className={inputGroupClassName}>
            <InputGroup.Text id="basic-addon1" className="rounded-none-bottom-right rounded-none-top-right">
              <i className="fa fa-search"></i>
            </InputGroup.Text>
            <Input
              customClass="rounded-none-bottom-left rounded-none-top-left"
              name={name}
              customStyle={props.customStyle}
              containerStyle={props.containerStyle}
              placeholder={placeholder}
              withErrorFeedback={false}
              disabled={disabled || (isDisabled && isDisabled(name))}
              onChangeCalback={value => {
                if (onChangeCalback) onChangeCalback(value);
                else submitForm();
              }}
            />
          </InputGroup>
        </>
      )}
    </>
  );
}
