import { format } from "date-fns";
import React, { FC } from "react";

export const DateTimeFromToColumnsFormatter:FC = (cell:any, row:any) => {
  if (cell) {
    const dateFrom = new Date(row.plannedFrom);
    const dateTo = new Date(row.plannedTo);

    return (
      <>
        {format(new Date(dateFrom), 'dd/MM/yy HH:mm')}
        -
        {format(new Date(dateTo), 'dd/MM/yy HH:mm')}
      </>
    );
  } else {
    return <></>;
  }
};
