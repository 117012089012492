import { useEffect, useState, useRef, useCallback } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import { useField, useFormikContext } from "formik";
import { useFormFieldsContext } from "./FormFieldsContext";
import { Error } from "./Error";
import Select from "react-select";
import { getValue, getTouched, getError } from "./_helper";
import RequiredFieldMarker from "./RequiredFieldMarker";

// @ts-ignore
import debounce from "lodash.debounce";
import { keyboardUtils } from "../../../../../app/common/_utils";

const getBorderColor = (touched: boolean, errors: any) => {
  let color = "#E5EAEE";
  if (touched && errors) {
    color = "#F64E60  !important";
  }
  if (touched && !errors) {
    color = "#1BC5BD  !important";
  }
  return color;
};

interface IProps {
  name?: string;
  label?: string;
  disabled?: boolean;
  loadOptionsAction?: any;
  isLoadingSelector?: any;
  optionsSelector?: any;
  nameProp?: string | string[];
  valueProp?: string;
  namePropDevider?: string;
  onChangeCalback?: any;
  customClass?: string;
  customStyle?: {};
  withErrorFeedback?: boolean;
  hasNoneOption?: boolean;
  isSelectValueComplexObject?: boolean;
  isMulti?: boolean;
  isAsync?: boolean;
  isRequired?: boolean;
  isLoadedOnFocus?:boolean;
  countOfFetchedItems?: number;
  onInit?: any;
  reactSelectProps?: any;
  modifiedOptions?: string;
  prependLabel?: string;
  [key:string]: any;
  tabIndex?:number;
  iref?:any;
  loadOptionsParams?:any;
  menuPortalTarget?:HTMLElement | null | undefined;
  dataTestid?:string;
}

export const SearchableSelect: React.FC<IProps> = ({
  name = "",
  label = "",
  disabled = false,
  loadOptionsAction,
  isLoadingSelector,
  optionsSelector,
  nameProp = "name",
  valueProp = "id",
  namePropDevider = "-",
  onChangeCalback,
  customClass = "",
  customStyle= {},
  withErrorFeedback = true,
  hasNoneOption = true,
  isSelectValueComplexObject = true,
  isMulti = false,
  isAsync = false,
  isRequired = false,
  isLoadedOnFocus = false,
  countOfFetchedItems = 0,
  onInit,
  modifiedOptions = "",
  prependLabel = "Select ",
  reactSelectProps,
  iref,
  loadOptionsParams = {},
  dataTestid,
  ...props
}) => {

  const inputFiled = useRef<any>(null);

  const { initialValues, values, setFieldValue, setFieldTouched, errors, touched, validateField } = useFormikContext<any>();
  const { isDisabled, isHidden } = useFormFieldsContext() || {};
  const [field] = useField(name);

  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  let options = useSelector(state => {
    let optionsFromState = optionsSelector(state);

    const filterItems = (item:any) => props.itemsToRemove.indexOf(item[valueProp]) < 0

    if (props.selectorIndex > -1) {
      if(Array.isArray(optionsFromState) && optionsFromState.length) {
        if(optionsFromState[props.selectorIndex]?.[0]?.hasOwnProperty('isActive')) {
          const tempArray = optionsFromState[props.selectorIndex].filter((o:any) => o?.isActive === true);
          return props.itemsToRemove ? tempArray.filter(filterItems) : tempArray;
        }
      }
  
      return optionsFromState[props.selectorIndex];
    }

    if(Array.isArray(optionsFromState) && optionsFromState.length) {
      if(optionsFromState[0].hasOwnProperty('isActive')) {
        const tempArray = optionsFromState.filter((o:any) => o?.isActive === true);
        return props.itemsToRemove ? tempArray.filter(filterItems) : tempArray
      }
    } 

    return optionsFromState;
  }, shallowEqual);

  if(modifiedOptions) {
    options = modifiedOptions
  }

  let isLoading = useSelector(state => {
    let value = isLoadingSelector ? isLoadingSelector(state) : false;
    if (props.selectorIndex > -1) {
      return value[props.selectorIndex] 
    }
    return value
  }, shallowEqual);

  useEffect(() => {
    if (onInit) onInit();
  }, [initialValues]);

  // useEffect(() => {
  //   let initialObject = null
  //   initialObject = options?.find((option:any) => option[valueProp] === field.value)
  //   if (props.onOptionsChange) props.onOptionsChange(initialObject, options)
  // }, [options])

  const getItem = (ids: []) => {
    if (ids) {
      if (isMulti) {
        if (isSelectValueComplexObject) {
          if (isAsync) {
            const newOptions = (options || []).filter((element: any) => ids.find(item => item[valueProp] === element[valueProp]));

            return [...ids, ...newOptions].filter((obj, index) => ids.findIndex((item:any) => item.id === obj.id) === index);
          } else {
            return options ? options.filter((element: any) => ids.find(item => item[valueProp] === element[valueProp])) : null;
          }
        } else {
          return options ? options.filter((element: any) => ids.find(item => item === element[valueProp])) : null;
        }
      } else {
        return options ? options.find((element: any) => element[valueProp] === ids) : null;
      }
    } else return null;
  };

  const onChange = (value: any) => {
    let val:any = null;
    if (isSelectValueComplexObject) {
      val = value;
    } else {
      if (value) {
        if (isMulti) {
          val = value.map((x: any) => x[valueProp]);
        } else {
          val = value[valueProp];
        }
      } else {
        val = null;
      }
    }
    
    setFieldValue(name, val, true);
    setTimeout(() => {
      touchField()
      validateField(name);
    }, 0)

    if (onChangeCalback) {
      onChangeCalback(props.returnObject ? value : val);
    }
  };

  const onBlur = () => {
    touchField();
  };

  const onKeyDown = (e:React.KeyboardEvent) => {
    props.onKeyDown && props.onKeyDown()
    if (keyboardUtils.isEscKey(e)) {
      e.stopPropagation();
      e.preventDefault();
      if (iref?.current || inputFiled?.current) {
        inputFiled?.current?.blur()
        iref?.current?.blur()
      }
    }
    
    touchField();
  }

  const inputProps = { ...field, ...props, ref: iref || inputFiled, onChange, onBlur, onKeyDown };

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor: withErrorFeedback
        ? getBorderColor(getTouched(touched, field.name), getError(errors, field.name))
        : styles.borderColor,
      boxShadow: "no",
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, backgroundColor: "#E5EAEE" }),
    placeholder: (styles: any) => ({ ...styles, color: "#B5B5C3" }),
    menuPortal: (styles:any) => ({ ...styles, zIndex: 999999 })
  };

  const filterOptions = (candidate: any, input: string) => {
    if (!isAsync) {
      if (input && candidate) {
        return candidate.label.toLowerCase().includes(input.toLowerCase());
      }
    }
    return true;
  };

  
  const updateValue = useCallback(
    debounce((newValue:string) => {
      setInputValue(newValue);
      if (loadOptionsAction) {
        dispatch(
          loadOptionsAction({
            id: values.id,
            filter: newValue,
            countOfResults: countOfFetchedItems,
            ...loadOptionsParams,
          }),
        );
      }
    }, 800),
    []
  )

  const touchField = () => {
    const touchedField = getValue(touched, name);
    if (typeof touchedField == "object") {
      setFieldTouched(`${name}.id`, true);
    } else setFieldTouched(name, true);
  }

  const handleInputChange = (newValue: string) => {
    if (isAsync && inputValue.toLowerCase() !== newValue.toLowerCase()) {
      updateValue(newValue);
    }

    touchField();
  };

  const loadOptions = () => {
    if (loadOptionsAction) {
      dispatch(loadOptionsAction(loadOptionsParams));
    }
  }

  useEffect(() => {
    loadOptions();
  }, [values.id, loadOptionsAction, countOfFetchedItems]);

  const getSelectValue = () => {
    if (isMulti) {
      return getItem(field.value);
    }
    if (isSelectValueComplexObject) {
      if (field.value && field.value[valueProp]) {
        return field.value;
      }
      return null;
    }
    return getItem(field.value);
  };

  const select = document.getElementById(name);
  const windowHorizon = window.innerHeight / 2;
  let menuPlacement: 'auto' | 'top' = "auto";
  let menuPortalTarget = props.menuPortalTarget || document.body
  
  if (select) {
    const top = select.getBoundingClientRect()?.top;
    if (top > windowHorizon) {
      menuPlacement = "top";
    }
  }

  return (
    <>
      {(!isHidden || !isHidden(name)) && (
        <>
          {label && (
            <label htmlFor={name} className={props.labelClass}>
              {`${prependLabel}${label}`}
              <RequiredFieldMarker name={name} isRequired={isRequired} />
            </label>
          )}

          <Select
            menuPortalTarget={menuPortalTarget}
            menuPlacement={menuPlacement}
            aria-label={`${prependLabel}${label}`} //for tests
            id={name}
            inputId={name}
            onInputChange={handleInputChange}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            className={customClass}
            styles={selectStyles}
            {...{...inputProps, ['data-testid']: dataTestid}}
            isDisabled={disabled || (isDisabled && isDisabled(name))}
            value={getSelectValue()}
            defaultValue={getSelectValue()}
            options={options}
            getOptionLabel={(option: any) => {
              return Array.isArray(nameProp)
                ? getValue(option, nameProp[0]) + namePropDevider + getValue(option, nameProp[1])
                : getValue(option, nameProp)
            }}
            onFocus={isLoadedOnFocus ? loadOptions : undefined}
            getOptionValue={(option: any) => option[valueProp]}
            isClearable={hasNoneOption}
            theme={(theme: any) => { 
              return {
              ...theme,
              colors: {
                ...theme.colors,
                primary75: "#69b3ff",
                primary: "#3699FF",
                neutral5: "#F3F6F9",
                neutral40: "#545d70",
              },
            }}}
            filterOption={filterOptions}
            noOptionsMessage={() => "No items"}
            isLoading={isLoading}
            isMulti={isMulti}
            {...props}
          />
          {withErrorFeedback && <Error name={name} errors={errors} touched={touched} />}
        </>
      )}
    </>
  );
}
