import React, { FC, useEffect, useState } from "react";
// import { useNavigate, Route, Routes, useLocation, Link } from "react-router-dom";
// import { filterModalRoute, groupModalRoute } from "../../../../_metronic/_partials/table/dialogs/modalRoutes";
import { Button, Modal } from "react-bootstrap";
import SvgIcon from "../SvgIcon";
import './PopupPicker.scss'
import { CheckboxGroup } from "../../../../_metronic/_partials/controls/forms/formFields";
import { Formik } from "formik";


interface IProps {
    label?:string;
    title?:string;
    icon?:string;
    itemsLabel?:string;
    toggleClassname?:string;
    items:PickerItem[];
    onSave?:Function;
    group:string;
    key?:string;
    selectable?:boolean;
}

const PopupPicker:FC<IProps> = ({
    label,
    icon,
    itemsLabel,
    items,
    title,
    toggleClassname,
    onSave,
    group,
    selectable = true,
}) => {
    const [options, setOptions] = useState<PickerItem[]>(items);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showList, setShowList] = useState<boolean>(false);

    const onClose = () => {
        selectable
            ? setShowFilters(false)
            : setShowList(false)
    }

    useEffect(() => {
        setOptions(items);
    }, [items]);

    return (
        <div className="flex flex-center">
            {label && <>
                <button
                    className={`pill popup-btn ${toggleClassname} pb-0 mb-0 pt-0 mt-0`}
                    data-testid="filter-pills-more-button"
                    // state={{ previousLocation: location }}
                    // to={selectable ? filterModalRoute('/', group) : groupModalRoute('/', group)}
                    onClick={() => {
                        selectable
                            ? setShowFilters(!showFilters)
                            : setShowList(!showList)
                    }}
                >
                    {icon && <SvgIcon
                        iconLocation={icon}
                        size="4"
                        customClasses="mr-2"
                        title={title || ''}
                        bootstrapColor="primary"
                    />}
                    {label}
                </button>
                <FiltersModal
                    show={showFilters}
                    onClose={onClose}
                    onSave={onSave}
                    items={options}
                    group={group}
                />
                <ListModal
                    show={showList}
                    onClose={onClose}
                    items={items}
                />
                </>}
        </div>
    );
}

interface IListModal {
    onClose:Function;
    items:PickerItem[];
    show:boolean;
}

const ListModal:FC<IListModal> = ({onClose, items, show}) => {
    return (
        <Modal
            show={show}
            onHide={() => onClose()}
            size="xl"
            data-testid="crud-modal"
            dialogClassName="crud-modal filters-modal"
        >
            <Modal.Header>
                <Modal.Title>
                    Assignment
                </Modal.Title>
                <section>
                    <div
                        onClick={() => onClose()}
                        data-testid="close-modal"
                        className="btn btn-icon btn-sm btn-hover-primary ml-4"
                    >
                        <SvgIcon
                            iconLocation="/media/svg/icons/Navigation/Close-2.svg"
                            size="4"
                            bootstrapColor="grey"
                            title="Close"
                        />
                    </div>
                </section>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <span className="Filters-Select-Group flex flex-column">
                    {items.map((item, i) => 
                        <label className="fs-13 text-dark mb-5" key={item.label}>{`${i+1}. ${item.label}`}</label>
                    )}
                </span>
            </Modal.Body>
        </Modal>
    );
}


interface IFiltersModal {
    onSave?:Function;
    onClose:Function;
    items:any;
    group:string;
    show: boolean;
}

const FiltersModal:FC<IFiltersModal> = ({show, onSave, onClose, items, group}) => {
    let setCheckboxes:any;
    const [activeItems, setactiveItems] = useState(items.filter((item:any) => item.active));
    const initialValues = items.reduce((a:any[], e:any) => ([
        ...a,
        ...(e.active ? [e.label] : []),
    ]), []);

    const selectFilter = (changedItems:any) => {
        const newItems = items.filter((item:any) => changedItems.includes(item.label));
        setactiveItems(newItems);
    }

    return (
        <Modal
            show={show}
            onHide={() => onClose()}
            size="xl"
            data-testid="crud-modal"
            dialogClassName="crud-modal filters-modal"
        >
            <Modal.Header>
                <Modal.Title>
                    {group.charAt(0).toUpperCase() + group.slice(1)}
                </Modal.Title>
                <section>
                    <div
                        onClick={() => onClose()}
                        data-testid="close-modal"
                        className="btn btn-icon btn-sm btn-hover-primary ml-4"
                    >
                        <SvgIcon
                            iconLocation="/media/svg/icons/Navigation/Close-2.svg"
                            size="4"
                            bootstrapColor="grey"
                            title="Close"
                        />
                    </div>
                </section>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Formik
                    initialValues={{[`filters-${group}`]: initialValues}}
                    onSubmit={() => {}}
                >
                    {({ values, setFieldValue }) => {
                        setCheckboxes = setFieldValue;
                        
                        return (
                            <CheckboxGroup
                                name={`filters-${group}`}
                                items={items.map((item:any) => ({ ...item, value: item.label }))}
                                label=""
                                itemColSize={11}
                                customClass="Filters-Select-Group d-flex flex-column"
                                onChangeCalback={selectFilter}
                                onInit={() => {}}
                            />
                        )
                    }}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                {setCheckboxes && <Button
                    variant="secondary"
                    onClick={() => {
                        selectFilter([]);
                        setCheckboxes && setCheckboxes(`filters-${group}`, [])
                    }}
                >
                    Clear selected
                </Button>}
                {onSave && <Button
                    type="submit"
                    onClick={() => {
                        onSave && onSave(activeItems.map((f:any) => ({...f, active: true})));
                        onClose();
                    }}
                >
                    Save
                </Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default PopupPicker;
