import * as constants from "./constants";

export const isItemsLoading = state => state.taskCategories.listLoading;
export const isItemActionLoading = state => state.taskCategories.actionsLoading;
export const getItems = state => state.taskCategories.entities;
export const getItemsSelect = state => state.taskCategories.entitiesSelect;
export const getItemsCount = state => state.taskCategories.totalCount;
export const getItemForEdit = state => state.taskCategories.itemForEdit;
export const getError = state => state.taskCategories.error;
export const validationErrors = (state) => state.taskCategories.validationErrors;

export const getTaskCategoryTypes = state => state.taskCategories[constants.TASKCATEGORY_TYPES];
export const getTaskCategoryTypesCount = state => state.taskCategories[constants.TASKCATEGORY_TYPES_COUNT];
export const getTaskCategoryTypesLoading = state => state.taskCategories[constants.TASKCATEGORY_TYPES_LOADING];
