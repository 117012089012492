import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";

interface IProps {
  title: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  newItemLink?: string;
  type?: string;
  hoverType?: string;
  disabled?: boolean;
}

export const AccessButton: React.FC<IProps> = ({
  title,
  newItemLink,
  onClick,
  disabled = false,
  type = "btn-white", 
  hoverType = "btn-hover-primary",
}) => {
  const location = useLocation();

  return (
    <OverlayTrigger placement="left" overlay={<Tooltip id="add-button">{title}</Tooltip>}>
      {newItemLink
        ? (
          <Link
            state={{ previousLocation: location }}
            to={newItemLink}
            data-testid="new-item"
            data-link={newItemLink}
            className={`btn btn-icon ${type} ${hoverType} ${disabled ? "not-allowed" : "pointer"}`}
            onClick={(e) => {
              e.currentTarget.blur();
              onClick && onClick(e)
            }}
          >
            <span className={`svg-icon svg-icon-size-5 svg-icon-primary`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} title=" " />
            </span>
          </Link>
        ) : (
          <Button
            data-testid="new-item"
            data-link={newItemLink}
            className={`btn btn-icon ${type} ${hoverType} ${disabled ? "not-allowed" : "pointer"}`}
            onClick={onClick}
            disabled={disabled}
          >
            <span className={`svg-icon svg-icon-size-5 svg-icon-primary`}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} title=" " />
            </span>
          </Button>
        )}
    </OverlayTrigger>
  );
};
