import { irSubScenesSlice } from "./irSubScenesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as fields from "../../pages/IRData/tabs/irSubScenes/edit/constants";

const { actions } = irSubScenesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

// CRUD
export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "SubSceneTypes_Get",
  actions,
  "SubSceneTypes/QParamsChanged",
);

export const createItemSwagger = defaultActions.createItemSwagger(
  "SubSceneTypes_Post",
  actions,
  defaultActions.convertCreateItemParams([
    fields.SUB_SCENE_NAME,
    fields.SUB_SCENE_LOCAL_NAME,
    fields.SUB_SCENE_ORDER,
    fields.SUB_SCENE_IS_MANDATORY,
    fields.SCENE_TYPE_ID,
  ]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("SubSceneTypes_Put", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("SubSceneTypes_Delete", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("SubSceneTypes_GetById", actions);

// Select
export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "SubSceneTypes_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchComplexSearch = defaultActions.fetchItemsSwagger(
  "SubSceneTypes_GetSubSceneComplexSearch",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemsSelect = defaultActions.fetchItemsSwagger(
  'SubSceneTypes_GetSelect',
  actions,
  'subSceneSelect',
  'selectError'
)

//Reset state
export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
