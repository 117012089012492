import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEditStatus, IStatuses } from "../../_interfaces/reduxState";

const initialState: IDefaultState<IStatuses, any, IEditStatus> = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const equipmentStatusesSlice = createSlice({
  name: "equipmentStatuses",
  initialState,
  reducers,
});
