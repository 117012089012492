import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { IFeatures, IMenuItems, IPermissions, IState } from "../../_interfaces/reduxState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IState = {
  ...defaultInitialState,
  menuItems: [],
  menuItemsCount: 0,
  menuItemsLoading: false,
  features: [],
};

const reducers = {
  ...defaultReducers,
  setAccess: (state: IState, action: PayloadAction<IMenuItems[]>) => {
    state.menuItems = action.payload;
    state.menuItemsLoading = false;
  },
  setFeatures: (state: IState, action: PayloadAction<IFeatures[]>) => {
    state.features = action.payload;
    state.actionsLoading = false;
  },
  setPermissions: (state: IState, action: PayloadAction<IPermissions[]>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      permissions: action.payload
    };
  },
  setMobilePermissions: (state: IState, action: PayloadAction<IPermissions[]>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      mobilePermissions: action.payload
    };
  },
  setIsMobile: (state: IState, action: PayloadAction<string>) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      type: {
        ...state.itemForEdit?.type,
        name: action.payload,
      }
    };
  },
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers,
});
