import React, { createContext, useContext } from "react";

const FormFieldsContext = createContext();

export function useFormFieldsContext() {
  return useContext(FormFieldsContext);
}

export function FormFieldsProvider({ formProps, children }) {

  return (
    <FormFieldsContext.Provider value={formProps}>
      {children}
    </FormFieldsContext.Provider>
  );
}