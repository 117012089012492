import { RootState } from "../../../../../redux/store";

export const rolesLoading = (state: RootState) => state.roles.itemsLoading;
export const roleActionLoading = (state: RootState) => state.roles.actionsLoading;
export const dropDownLoading = (state: RootState) => state.roles.dropDownLoading;

export const roles = (state: RootState) => state.roles.items;
export const rolesCount = (state: RootState) => state.roles.itemsCount;
export const roleForEdit = (state: RootState) => state.roles.itemForEdit;
export const dropDownValues = (state: RootState) => state.roles.dropDownItems;

export const error = (state: RootState) => state.roles.error;
export const errorAction = (state: RootState) => state.roles.errorAction;
export const dropDownError = (state: RootState) => state.roles.dropDownError;

export const getMenus = (state: RootState) => state.roles.menuItems;
export const getMenusCount = (state: RootState) => state.roles.menuItemsCount;
export const getMenusLoading = (state: RootState) => state.roles.menuItemsLoading;
export const useFeatures = (state: RootState) => state.roles.features;

export const validationErrors = (state: RootState) => state.roles.validationErrors;