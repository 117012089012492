import { articleUnitsOfMeasureSlice } from "./articleUnitsOfMeasureSlice";
import * as defaultActions from "../../../../../redux/_helpers";

export const QPARAMS_CHANGED = "articleUnitsOfMeasure/QParamsChanged";
const { actions } = articleUnitsOfMeasureSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "ArticleUnitsOfMeasure_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("ArticleUnitsOfMeasure_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "ArticleUnitsOfMeasure_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("ArticleUnitsOfMeasure_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("ArticleUnitsOfMeasure_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "ArticleUnitsOfMeasure_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "description"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("ArticleUnitsOfMeasure_Put", actions);

export const setActiveMeasures = measures => dispatch => {
  dispatch(actions.setActiveMeasures(measures));
}

export const clearError = () => defaultActions.clearError(actions);