import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.articlePackTypes.listLoading;
export const isItemActionLoading = (state:RootState) => state.articlePackTypes.actionsLoading;
export const getItems = (state:RootState) => state.articlePackTypes.entities;
export const getItemsSelect = (state:RootState) => state.articlePackTypes.entitiesSelect;
export const getItemsCount = (state:RootState) => state.articlePackTypes.totalCount;
export const getItemForEdit = (state:RootState) => state.articlePackTypes.itemForEdit;
export const getError = (state:RootState) => state.articlePackTypes.error;
export const validationErrors = (state: RootState) => state.articlePackTypes.validationErrors;
