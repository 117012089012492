type Statuses = {
    'done': string;
    'rejected': string;
    'planned': string;
    'in_progress': string;
    'deleted': string;
}

export const TaskStatusFormatter:any = (cell:string) => {
    const key = cell.toLowerCase().replace(' ', '_') as keyof Statuses

    const statuses = {
        'done': 'done',
        'rejected': 'danger',
        'planned': 'planned',
        'in_progress': 'warning',
        'deleted': 'muted'
    };
    
    const css = statuses[key];

    return (
        <div className="flex jc-flex-end">
          <span className={`label label-lg label-light-${css} label-inline`} style={{ lineBreak: 'strict', whiteSpace: 'nowrap' }}>
            {cell}
          </span>
        </div>
    );
}