import { articlesSlice } from "./articlesSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get } from "../../../../../api/requester";
import { Dispatch } from "redux";
// import { Dispatch } from "@reduxjs/toolkit";
import * as fields from "../../pages/articles/edit/constants";
import { IValues } from "../../pages/articles/edit/EditPage";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { getErrorMessage } from "../../../../../redux/_helpers";

const { actions } = articlesSlice;
export const moduleUrl = "/api/appmasterdata/articles";

export const setValidationErrors = actions.setValidationErrors;

export const getArticles = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const saveArticle = (article: {}, id: string | null) =>
  defaultActions.saveItem(
    moduleUrl,
    actions,
    article,
    id,
    refineDataForSaving,
    'multipart/form-data;boundary="boundary"',
  );

export const getArticle = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);

export const deleteArticle = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearArticle = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

/////////////////////////////
const refineDataForTabs = (data: any) => {
  return {
    id: data.id,
    [fields.ARTICLE]: {
      [fields.ARTICLE_CODE]: data[fields.ARTICLE_CODE],
      [fields.ARTICLE_NAME]: data[fields.ARTICLE_NAME],
      [fields.ARTICLE_BARCODE]: data[fields.ARTICLE_BARCODE],
      [fields.ARTICLE_EAN]: data[fields.ARTICLE_EAN],
      [fields.ARTICLE_CATEGORY_ID]: data[fields.ARTICLE_CATEGORY_ID],
      [fields.ARTICLE_HIERARCHY]: data[fields.ARTICLE_HIERARCHY],
      [fields.ARTICLE_BRAND_ID]: data[fields.ARTICLE_BRAND_ID],
      [fields.ARTICLE_FLAVOR_ID]: data[fields.ARTICLE_FLAVOR_ID],
      [fields.ARTICLE_PACK_SIZE_ID]: data[fields.ARTICLE_PACK_SIZE_ID],
      [fields.ARTICLE_PACK_TYPE_ID]: data[fields.ARTICLE_PACK_TYPE_ID],
      [fields.ARTICLE_INACTIVE]: !data[fields.ARTICLE_INACTIVE], //BE expects inactive while FE needs to show active
      [fields.IS_FOREIGN]: data[fields.IS_FOREIGN],
      [fields.WIDTH]: data[fields.WIDTH],
      [fields.HEIGHT]: data[fields.HEIGHT],
      [fields.DEPTH]: data[fields.DEPTH],
      [fields.INCLUDE_IN_PLANOGRAM]: Boolean(data[fields.INCLUDE_IN_PLANOGRAM]),
    },
    [fields.CONVERSATIONS]: data["articleUnitConversions"]
      .sort((a: any, b: any) => b[fields.CONVERSATIONS_IS_BASE_UNIT] - a[fields.CONVERSATIONS_IS_BASE_UNIT])
      .map((uc: any) => ({
        [fields.CONVERSATIONS_ARTICLE_ID]: uc[fields.CONVERSATIONS_ARTICLE_ID],
        [fields.CONVERSATIONS_IS_BASE_UNIT]: uc[fields.CONVERSATIONS_IS_BASE_UNIT],
        [fields.CONVERSATIONS_MEASURE_ID]: uc[fields.CONVERSATIONS_MEASURE_ID],
        [fields.CONVERSATIONS_NUMERATOR]: uc[fields.CONVERSATIONS_NUMERATOR],
        [fields.CONVERSATIONS_DOMINATOR]: uc[fields.CONVERSATIONS_DOMINATOR],
        [fields.CONVERSATIONS_PRICE]: uc[fields.CONVERSATIONS_PRICE],
        [fields.CONVERSATIONS_WEIGHT]: uc[fields.CONVERSATIONS_WEIGHT],
      })),
    [fields.IMAGES]: data[fields.IMAGES].sort((a:any, b:any) => b.isForPlanogram - a.isForPlanogram),
  };
};

/////////////////////////////
const refineDataForSaving = (data: IValues) => {
  const files: File[] = [];

  const createArticleImage: IValues["createArticleImage"] = [];
  const updateArticleImages: IValues["updateArticleImages"] = [];
  
  data.articleImages.forEach(a => {
    if (a?.action === "create") {
      a.file && files.push(a.file)

      createArticleImage.push({
        fileName: a.file?.name || "n/a",
        isDefault: a.isDefault,
        isForPlanogram: Boolean(a.isForPlanogram),
      });
    } else if (a?.action === "update") {
      updateArticleImages.push({
        id: a.id,
        isDefault: a.isDefault,
        isForPlanogram: Boolean(a.isForPlanogram),
      });
    }
  });
  
  const form = {
    ...(data.id && { id: data.id }),
    [fields.ARTICLE_CODE]: data.articleTab.code,
    [fields.ARTICLE_NAME]: data.articleTab.name,
    [fields.ARTICLE_BARCODE]: data.articleTab.barcode,
    [fields.ARTICLE_EAN]: data.articleTab.ean,
    [fields.ARTICLE_CATEGORY_ID]: data.articleTab.articleCategoryId,
    [fields.ARTICLE_HIERARCHY]: data.articleTab.articleHierarchy,
    [fields.ARTICLE_BRAND_ID]: data.articleTab.articleBrandId,
    [fields.ARTICLE_FLAVOR_ID]: data.articleTab.articleFlavorId,
    [fields.ARTICLE_PACK_SIZE_ID]: data.articleTab.articlePackSizeId,
    [fields.ARTICLE_PACK_TYPE_ID]: data.articleTab.articlePackTypeId,
    [fields.ARTICLE_INACTIVE]: !data.articleTab.inactive, //BE expects inactive while FE needs to show active
    [fields.INCLUDE_IN_PLANOGRAM]: data.articleTab.isInPlanogram,
    [fields.IS_FOREIGN]: data.articleTab.isForeign,
    [fields.WIDTH]: data.articleTab[fields.WIDTH],
    [fields.HEIGHT]: data.articleTab[fields.HEIGHT],
    [fields.DEPTH]: data.articleTab[fields.DEPTH],
    [fields.CONVERSATIONS]: data.articleTab.isForeign ? [] : data.articleUnitConversions.map(uc => ({
      [fields.CONVERSATIONS_ARTICLE_ID]: uc.articleId,
      [fields.CONVERSATIONS_IS_BASE_UNIT]: uc.isBaseUnit,
      [fields.CONVERSATIONS_MEASURE_ID]: uc.articleUnitOfMeasureId,
      [fields.CONVERSATIONS_NUMERATOR]: uc.numerator,
      [fields.CONVERSATIONS_DOMINATOR]: uc.denominator,
      [fields.CONVERSATIONS_PRICE]: uc.price,
      [fields.CONVERSATIONS_WEIGHT]: uc.weight,
    })),
    createArticleImage,
    updateArticleImages,
    deleteArticleImages: data.deleteArticleImages,
  };

  const formData = new FormData();
  formData.append('command', JSON.stringify(form));

  files.forEach((file) => {
    formData.append('files', file);
  });

  return formData
};


export const getFilters = (isForeign:boolean) => async (dispatch:Dispatch) => {
  try {
    const articlebrands:any[] = await get("api/appmasterdata/articlebrands/complexSearch", {isForeign});
    const articlecategories:any[] = await get("api/appmasterdata/articlecategories/complexSearch", {isForeign});
    const articleflavours:any[] = await get("api/appmasterdata/articleflavours/complexSearch", {isForeign});
    const articlepacksizes:any[] = await get("api/appmasterdata/articlepacksizes/complexSearch", {isForeign});
    const articlepacktypes:any[] = await get("api/appmasterdata/articlepacktypes/complexSearch", {isForeign});

    dispatch(actions.setFilter(
        constructInitialFilters([
          ...(articlebrands || []),
          ...(articlecategories || []),
          ...(articleflavours || []),
          ...(articlepacksizes || []),
          ...(articlepacktypes || []),
        ])
    ));
    
  } catch (errors) {
    const message = getErrorMessage(errors, "Error occurred!");
    dispatch(actions.catchError({ message, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};
export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);