/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../../app/common/_customHooks/redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_partials/controls";
import { EditTabForm } from "../../../../table/dialogs/edit/EditTabForm";
import { BootstrapTableExtendedPaged } from "../../../../table";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_helpers";
import isEqual from "lodash/isEqual";

import { initialParams } from "../../../../../../_metronic/_partials/table";
import SvgIcon from "../../../../../../app/components/common/SvgIcon";

export function AddItemsDialog({
  modelName,
  keyField,
  tableColumns,
  sizePerPageList,
  queryParams,
  fetchItemsAction,
  customClass,
  show,
  onHide,
  addItemsHandler,
  getItemsAction,
  getItemsSelector,
  isItemsLoadingSelector,
  filters,
  filtersIntialValues = {},
  excludedIds = [],
  excludeSelf,
}) {
  const dispatch = useDispatch();

  const initFilters = { filter: "", ...filtersIntialValues };

  const [ids, setIds] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilterBase] = useState(initFilters);
  const setFilter = useCallback(nextFilter => {
    setFilterBase(prevFilter => {
      if (isEqual(prevFilter, nextFilter)) {
        return prevFilter;
      }
      return nextFilter;
    });
  }, []);

  // Redux state
  let allItems = useSelector(state => getItemsSelector(state));
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (excludedIds.length > 0) {
      setEntities(allItems.filter(x => !excludedIds.includes(x[keyField]) && x.id !== excludeSelf));
    } else if (excludeSelf && excludeSelf.length > 0) {
      setEntities(allItems.filter(x => x.id !== excludeSelf));
    } else {
      setEntities(allItems);
    }
  }, [allItems]);

  const listLoading = useSelector(state => isItemsLoadingSelector(state), shallowEqual);

  useEffect(() => {
    setPage(1);
    setIds([]);
    if (show) dispatch(getItemsAction({ ...filter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, show]);

  const clear = () => {
    setIds([]);
    setPage(1);
    setFilter(initFilters);
    onHide();
  };

  const handleCancel = () => {
    clear();
  };

  const handleSubmit = () => {
    addItemsHandler(entities.filter(x => ids.includes(x[keyField])));
    clear();
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: ids,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <input type="checkbox" defaultChecked={ids.length > 0 && ids.length === entities.length} />
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setIds([...ids, row.id]);
      } else {
        setIds(ids.filter(x => x !== row.id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setIds(entities.map(x => x.id));
      } else {
        setIds([]);
      }
    },
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName={`grid-modal-dialog ${customClass}`}
    >
      {listLoading && <ModalProgressBar />}
      {listLoading && (
        <>
          <div className="modal-content-backdrop"></div>
          <span className="modal-content-backdrop-loader">
            <SVG src={toAbsoluteUrl("/media/loading/loading.svg")} />
          </span>
        </>
      )}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{`Add ${modelName}`}</Modal.Title>
        <div
          data-testid="close-modal"
          className="btn btn-icon btn-sm btn-hover-primary ml-4"
          onClick={onHide}
        >
          <SvgIcon
            iconLocation="/media/svg/icons/Navigation/Close-2.svg"
            size="4"
            bootstrapColor="grey"
            title="Close"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <EditTabForm item={filter} saveItem={values => setFilter(values)}>
            <>
              {/* <SearchField /> */}
              {filters && React.createElement(filters)}
            </>
          </EditTabForm>
          {
            <BootstrapTableExtendedPaged
              columns={tableColumns}
              entities={entities}
              isLoading={listLoading}
              keyField={keyField}
              remote={false}
              paginationOptions={{
                custom: true,
                totalSize: entities === null ? 0 : entities.length,
                sizePerPage: initialParams.Size,
                sizePerPageList: sizePerPageList,
                Page: page,
                onPageChange: (page, sizePerPage) => setPage(page),
              }}
              selectRowHandler={selectRow}
            />
          }
        </>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={() => handleCancel()} className="btn btn-light btn-elevate">
          Cancel
        </button>
        <> </>
        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary btn-elevate">
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}
