import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=e934983f-ed9c-4efd-bcd9-03367c435d13;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true,
        // instrumentationKey: 'd81d5a8e-f811-439d-9f1d-434de799bc58',
        extensions: [reactPlugin],
        // extensionConfig: {
        //   [reactPlugin.identifier]: { history: browserHistory }
        // }
    }
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
