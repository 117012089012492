import React from "react";

export const OnboardStatusFormatter = (cellValue:any) => {
  let label = 'Not Started';
  let css = 'planned';

  if (cellValue && cellValue !== '0') {
    label = `In Progress | ${cellValue}%`;
    css = 'warning'

    if (cellValue === '100') {
      label = `Onboarded`;
      css = 'done'
    }
  } else if (cellValue && cellValue === '0') {
    label = `Sent for onboarding`;
    css = 'warning'
  }
  
  
  return (
    <div className="flex jc-flex-end">
      <span className={`label label-lg label-light-${css} label-inline`}>
        {label}
      </span>
    </div>
  );
};

export const OnboardHeaderStatusFormatter = (column:any, colIndex:number, { sortElement }:any) => {
  return (
    <div className="flex jc-flex-end">
      { column.text }
      { sortElement }
    </div>
  );
}
