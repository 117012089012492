import {
  SURVEY_ANSWER_TYPES_ENTITY_TYPE,
  SURVEY_ANSWER_TYPES_LOADING_TYPE,
  SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE,
  SURVEY_ARTICLES,
  SURVEY_ARTICLES_LOADING,
  SURVEY_ANSWER_SUB_TYPES_LOADING_TYPE,
  SURVEY_SECTION_TYPES_ENTITY_TYPE,
  SURVEY_SECTION_TYPES_LOADING_TYPE,
  SURVEY_DYNAMIC_OPERATORS_ENTITY_TYPE,
  SURVEY_DYNAMIC_OPERATORS_TYPES_LOADING_TYPE,
  SURVEY_VALIDATION_ERRORS,
} from "./constants";

export const isItemsLoading = state => state.surveys.listLoading;
export const isItemActionLoading = state => state.surveys.actionsLoading;
export const getItems = state => state.surveys.entities;
export const getItemsCount = state => state.surveys.totalCount;
export const getItemForEdit = state => {
  return {
    ...state.surveys.itemForEdit,
    ...(state.surveys?.itemForEdit?.version ? {version: String(state.surveys.itemForEdit.version)} : {})
    
  }
};
export const getNewItemId = state => state.surveys.itemId;
export const getError = state => state.surveys.error;

export const getSurveySectionTypes = state => state.surveys[SURVEY_SECTION_TYPES_ENTITY_TYPE];
export const getSurveySectionTypesLoading = state => state.surveys[SURVEY_SECTION_TYPES_LOADING_TYPE];

export const getSurveyAnswerTypes = state => state.surveys[SURVEY_ANSWER_TYPES_ENTITY_TYPE];
export const getSurveyAnswerTypesLoading = state => state.surveys[SURVEY_ANSWER_TYPES_LOADING_TYPE];

export const getSurveyAnswerSubTypes = state => state.surveys[SURVEY_ANSWER_SUB_TYPES_ENTITY_TYPE];
export const getSurveyAnswerSubTypesLoading = state => state.surveys[SURVEY_ANSWER_SUB_TYPES_LOADING_TYPE];

export const getSurveyArticlesList = state => state.surveys[SURVEY_ARTICLES];
export const getSurveyArticlesLoading = state => state.surveys[SURVEY_ARTICLES_LOADING]

export const getTaskTypes = state => state.surveys.taskTypes;

export const getSurveyDynamicOperators = state => state.surveys[SURVEY_DYNAMIC_OPERATORS_ENTITY_TYPE];
export const getSurveyDynamicOperatorsLoading = state => state.surveys[SURVEY_DYNAMIC_OPERATORS_TYPES_LOADING_TYPE];

export const getSurveyDynamicQuestionsList = state => state.surveys.surveyQuestions;

export const getSurveyVersions = state => state.surveys.surveysVersions;

export const validationErrors = state => state.surveys[SURVEY_VALIDATION_ERRORS];
