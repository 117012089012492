import { irMappingSlice } from "./irMappingSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as fields from '../../pages/IRData/tabs/irMapping/edit/constants';

const { actions } = irMappingSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
    'Mapping_Get',
    actions,
    'Mapping/QParamsChnaged'
)

export const createItemSwagger = defaultActions.createItemSwagger(
    'Mapping_Post',
    actions,
    defaultActions.convertCreateItemParams([
        fields.SUB_SCENE_TYPE_ID,
        fields.ASSET_MODEL_SUB_TYPE_ID,
    ]),
);


export const updateItemSwagger = defaultActions.updateItemSwagger("Mapping_Put", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Mapping_Delete", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Mapping_GetById", actions);

//Reset state
export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);