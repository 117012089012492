import { Modal, Button } from "react-bootstrap";
import SvgIcon from "./SvgIcon";

interface IProps {
  showModal: boolean;
  onHideModal?: () => void;
  customClasses?: string;
  customStyles?: {};
  body: JSX.Element;
  title?: string;
  buttons?: {
    text: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
    variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
    customClasses?: string;
    customStyles?: {};
    children?: any;
  }[];
  hasHeader?: boolean;
  hasClose?: boolean;
  hasBody?: boolean;
  hasFooter?: boolean;
  centered?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  customHeaderStyles?: {};
  customBodyStyles?: {};
  customFooterStyles?: {};
}

/**
 * 
 * @param title - Header text (string) 
 * @param body - Body elements (JSX) 
 * @param buttons - Footer buttons (Object {variant: string, onClick: function, disabled: boolean, customClasses: string, customStyles: {}}) 
 */
const CustomModal: React.FC<IProps> = ({
  showModal = false,
  onHideModal = () => {},
  customClasses = "",
  customStyles = {},
  title = "",
  body = <></>,
  buttons = [],
  hasHeader = true,
  hasClose = false,
  hasBody = true,
  hasFooter = true,
  centered = false,
  size = undefined,
  customHeaderStyles = {},
  customBodyStyles = {},
  customFooterStyles = {},
}) => {
  return (
    <Modal
      show={showModal}
      onHide={onHideModal}
      size={size}
      centered={centered}
      className={customClasses}
      style={customStyles}
    >
      {hasHeader && (
        <Modal.Header style={{ ...customHeaderStyles }}>
          <Modal.Title data-testid="custom_modal_title">{title}</Modal.Title>
          {hasClose && <div
              data-testid="close-modal"
              className="btn btn-icon btn-sm btn-hover-primary ml-4"
              onClick={onHideModal}
            >
              <SvgIcon
                iconLocation="/media/svg/icons/Navigation/Close-2.svg"
                size="4"
                bootstrapColor="grey"
                title={`Close ${title}`}
              />
            </div>}
        </Modal.Header>
      )}
      {hasBody && <Modal.Body style={{ ...customBodyStyles }}>{body}</Modal.Body>}
      {hasFooter && (
        <Modal.Footer style={{ ...customFooterStyles }}>
          {buttons.map((button, index) => (
            <Button
              key={index}
              variant={button.variant}
              disabled={button.disabled}
              onClick={button.onClick}
              className={button.customClasses}
              style={{ ...button.customStyles }}
            >
              {button.children}
              {button.text}
            </Button>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
