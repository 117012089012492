import { regionsSlice } from "./regionsSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

import { swaggerClient } from "../../../../../api/swaggerApiConnect";
import { getCurrentUserOrganizationUnit, getCurrentAccessToken } from "../../../../user-access/organizationUnitStorage";

const { actions } = regionsSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const clearErrors = () => actions.clearErrors(actions)
export const fetchItemsHelpSwagger = defaultActions.fetchItemsSwagger(
  "Countries_GetCountryRegions",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Regions_Get",
  actions,
  constants.QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Regions_Get", actions);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Regions_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Regions_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "Regions_Post",
  actions,
  defaultActions.convertCreateItemParams(["name", "nameLocal", "countryId"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("Regions_Put", actions);

export const getCountryRegionsSwagger = country => fetchItemsHelpSwagger({ Id: country });

export const fetchCountryRegionsSwagger = defaultActions.fetchCustomItemsSwagger(
  "Countries_GetCountryRegions",
  actions,
  "entitiesSelect",
  "actionsLoading",
  "totalCount",
  "selectError",
);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);

export const getCountryFromRegion = regionId => async dispatch => {
  const operationID = "Regions_GetByIdCountry";

  const userOrgUnit = getCurrentUserOrganizationUnit();
  const response = await getCurrentAccessToken();

  const params = {
    Id: regionId,
    OrganizationUnit: userOrgUnit,
  };

  dispatch(actions.startCall({ callType: defaultActions.callTypes.list }));

  return swaggerClient.then(client =>
    client
      .execute({
        spec: client.spec,
        operationId: operationID,
        parameters: params,
        securities: {
          authorized: {
            bearerAuth: response.accessToken,
          },
        },
        responseContentType: "application/json",
      })
      .then(response => {
        const { data } = response.body;
        dispatch(actions.countryFromRegionsFetched(data));
      })
      .catch(errors => {
        const error = defaultActions.requestErrorHandling(errors, "Error occurred during fetching the item!");
        dispatch(actions.catchError({ error, callType: defaultActions.callTypes.error }));
      }),
  );
};
