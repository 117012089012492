import { orgSlice } from "./organizationUnitsSlice";
import * as defaultActions from "../../../../../redux/_common";
import { Dispatch } from "redux";
import { get } from "../../../../../api/requester";

const { actions } = orgSlice;
const moduleUrl = "/api/Multitenancy/OrganizationUnits";

export const getDropDownItems = (params: {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const dropDownTerritoriesLoading = () => (dispatch: Dispatch) => {
  dispatch(actions.dropDownTerritoriesLoading());
};

export const getDropDownTerritories = (id: string) => async (dispatch: Dispatch) => {
  try {
    const res = await get(`${moduleUrl}/${id}/territories`);
    dispatch(actions.getDropDownTerritories(res));
  } catch (errors) {
    console.error(errors);
  }
};

export const clearOrgUnitTerritories = () => (dispatch: Dispatch) => {
  dispatch(actions.clearOrgUnitTerritories());
};
