import { languagesSlice } from "./languagesSlice";
import * as defaultActions from "../../../../../redux/_helpers";

export const QPARAMS_CHANGED = "languages/QParamsChanged";
const { actions } = languagesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "Languages_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("Languages_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "Languages_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("Languages_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("Languages_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "Languages_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "name", "nameLocal"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("Languages_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
