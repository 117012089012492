import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.articlePackSizes.listLoading;
export const isItemActionLoading = (state:RootState) => state.articlePackSizes.actionsLoading;
export const getItems = (state:RootState) => state.articlePackSizes.entities;
export const getItemsSelect = (state:RootState) => state.articlePackSizes.entitiesSelect;
export const getItemsCount = (state:RootState) => state.articlePackSizes.totalCount;
export const getItemForEdit = (state:RootState) => state.articlePackSizes.itemForEdit;
export const getError = (state:RootState) => state.articlePackSizes.error;
export const validationErrors = (state: RootState) => state.articlePackSizes.validationErrors;
