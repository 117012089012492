import { PublicClientApplication } from "@azure/msal-browser";

// Configuration object constructed.
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 10000,
    loadFrameTimeout: 0,
  },
};

// create PublicClientApplication instance
export const authProvider = new PublicClientApplication(msalConfig);
