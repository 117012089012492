/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export const ActionsColumnFormatter = (cellContent, row, rowIndex, { actions, keyField, disabled }) => {
  return (
    <div className="d-flex flex-row flex-nowrap justify-content-center">
      {actions &&
        actions.map((action, idx) => (
          <OverlayTrigger placement="left" key={idx} overlay={<Tooltip>{typeof action.tooltip === 'string' ? action.tooltip : action.tooltip(row)}</Tooltip>}>
            <button
              data-testid={action.testId}
              data-link={action.link ? action.link(row[keyField]) : "#"}
              className={`btn btn-icon btn-light btn-sm ${action.hover ? action.hover : "btn-hover-primary"} ${idx < actions.length - 1 ? "mx-3" : ""} ${
                disabled || (action.disabled && action.disabled(row)) ? "disabled" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation(); // prevent row click - view mode
                if (action.disabled) {
                  if (!action.disabled(row)) action.onClick(row[keyField], row);
                } else {
                  if (!disabled) action.onClick(row[keyField], row);
                }
                
                
              }}
            >
              <span className={`svg-icon svg-icon-md ${typeof action.iconClass === 'string' ? action.iconClass : action.iconClass(row)}`}>
                <SVG src={toAbsoluteUrl(typeof action.iconUrl === 'string' ? action.iconUrl : action.iconUrl(row))} title=" "/>
              </span>
            </button>
          </OverlayTrigger>
        ))}
    </div>
  );
};
