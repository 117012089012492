import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.customerHierarchy.listLoading;
export const isItemActionLoading = (state:RootState) => state.customerHierarchy.actionsLoading;
export const getItems = (state:RootState) => state.customerHierarchy.entities;
export const getItemsSelect = (state:RootState) => state.customerHierarchy.entitiesSelect;
export const getItemsCount = (state:RootState) => state.customerHierarchy.totalCount;
export const getItemForEdit = (state:RootState) => state.customerHierarchy.itemForEdit;
export const getError = (state:RootState) => state.customerHierarchy.error;
export const getValidationErrors = (state: RootState) => state.customerHierarchy.validationErrors;
