import { RootState } from "../../../../../redux/store";

export const usersLoading = (state: RootState) => state.users.itemsLoading;
export const userActionLoading = (state: RootState) => state.users.actionsLoading;

export const users = (state: RootState) => state.users.items;
export const usersCount = (state: RootState) => state.users.itemsCount;
export const userForEdit = (state: RootState) => state.users.itemForEdit;
export const dropDownValues = (state: RootState) => state.users.dropDownItems;

export const error = (state: RootState) => state.users.error;
export const errorAction = (state: RootState) => state.users.errorAction;
export const dropDownError = (state: RootState) => state.users.dropDownError;

export const validationErrors = (state: RootState) => state.users.validationErrors;
