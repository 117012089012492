import { RootState } from "../../../../../redux/store";

export const assetModelSubTypesLoading = (state: RootState) => state.assetModelSubTypes.itemsLoading;
export const assetModelSubTypeActionLoading = (state: RootState) => state.assetModelSubTypes.actionsLoading;
export const dropDownLoading = (state: RootState) => state.assetModelSubTypes.dropDownLoading;

export const assetModelSubTypes = (state: RootState) => state.assetModelSubTypes.items;
export const assetModelSubTypesCount = (state: RootState) => state.assetModelSubTypes.itemsCount;
export const assetModelSubTypeForEdit = (state: RootState) => state.assetModelSubTypes.itemForEdit;
export const dropDownValues = (state: RootState) => state.assetModelSubTypes.dropDownItems;

export const error = (state: RootState) => state.assetModelSubTypes.error;
export const errorAction = (state: RootState) => state.assetModelSubTypes.errorAction;
export const dropDownError = (state: RootState) => state.assetModelSubTypes.dropDownError;

export const validationErrors = (state: RootState) => state.assetModelSubTypes.validationErrors;