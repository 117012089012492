import React from "react";
import { authProvider } from "../auth/authProvider";
import { msalConfig } from "../auth/authProvider";
import { useMsal } from "@azure/msal-react";

function signOut() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useMsal();
  const logoutRequest = {
    //account: instance.getAccountByHomeId(homeAccountId),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  };
  instance.logoutRedirect(logoutRequest);
}

const setAccountValue = () => {
  let account;
  const currentAccounts = authProvider.getAllAccounts();

  if (currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    
    const accounts = currentAccounts.filter(
      account =>
        // account.homeAccountId.toUpperCase().includes(process.env.REACT_APP_AUTH_AUTHORITY.toUpperCase()) &&
        account.idTokenClaims.iss.toUpperCase().includes(process.env.REACT_APP_KNOWN_AUTHORITY.toUpperCase()) &&
        account.idTokenClaims.aud === process.env.REACT_APP_AUTH_CLIENT_ID,
    );

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
        // All accounts belong to the same user
        account = accounts[0];
      } else {
        // Multiple users detected. Logout all to be safe.
        signOut();
      }
    } else if (accounts.length === 1) {
      account = accounts[0];
    }
  } else if (currentAccounts.length === 1) {
    account = currentAccounts[0];
  }

  return account;
};

export const getAccountInfo = () => {
  const account = setAccountValue();

  if (!account) return { fullName: "", initials: "", email: "" };
  const accountName = account ? account.name : "";
  const accountFirstName = account ? account.idTokenClaims.given_name : "";
  const accountLastName = account ? account.idTokenClaims.family_name : "";
  let accountInitials =
    (accountFirstName ? accountFirstName.charAt(0) : "") + (accountLastName ? accountLastName.charAt(0) : "");
  let accountFullName = accountFirstName + " " + accountLastName;
  accountFullName = accountFullName !== "" ? accountFullName : accountName;
  accountInitials = accountInitials !== "" ? accountInitials : accountName.charAt(0);
  const email =
    account.idTokenClaims.emails && account.idTokenClaims.emails.length > 0 ? account.idTokenClaims.emails[0] : "";

  return { fullName: accountFullName, initials: accountInitials, email: email };
};
