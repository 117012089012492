import { RootState } from "../../../../redux/store";  

export const useTaskCategories = (state: RootState) => state.dashboard.taskCategories;

export const useSelectedPeriod = (state:RootState) => state.dashboard.selectedPeriod;
export const useTaskProgressReport = (state:RootState) => state.dashboard.taskProgressReport;
export const useTaskProgressReportLoading = (state:RootState) => state.dashboard.taskProgressReportLoading;
export const useDailyIncome = (state:RootState) => state.dashboard.dailyIncome;
export const useDailyIncomeReportLoading = (state:RootState) => state.dashboard.dailyIncomeReportLoading;
export const useOrderedArticles = (state:RootState) => state.dashboard.orderedArticles;
export const useOrderedArticlesLoading = (state:RootState) => state.dashboard.orderedArticlesLoading;
export const useAccuracy = (state:RootState) => state.dashboard.accuracyReport;
export const useAccuracyLoading = (state:RootState) => state.dashboard.accuracyReportLoading;
export const useAuditedScenes = (state:RootState) => state.dashboard.auditedScenes;
export const useAuditedScenesLoading = (state:RootState) => state.dashboard.auditedScenesLoading;
export const useOnboardedProducts = (state:RootState) => state.dashboard.onboardedProducts;
export const useOnboardedProductsLoading = (state:RootState) => state.dashboard.onboardingProductsLoading;
export const useRecentActivities = (state:RootState) => state.dashboard.recentActivities;
export const useRecentActivitiesLoading = (state:RootState) => state.dashboard.recentActivitiesLoading;
export const useLowAccuracy = (state:RootState) => state.dashboard.lowAccuracy;
export const useLowAccuracyLoading = (state:RootState) => state.dashboard.lowAccuracyLoading;
