import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { createSlice } from "@reduxjs/toolkit";
import { IEntities } from "./_interfaces";

interface IState extends IDefaultState<IEntities, any, any> {}

const initialState: IState = {
  ...defaultInitialState,
};

const reducers = {
  ...defaultReducers,
};

export const eventsSlice = createSlice({
  name: "Events",
  initialState,
  reducers,
});
