export const QPARAMS_CHANGED = "customers/QParamsChanged";
export const CREDIT_DATA_QPARAMS_CHANGED = "customers/CreditDataQParamsChanged";
export const CLASSIFICATION_QPARAMS_CHANGED = "customers/ClassificationQParamsChanged";
export const BLOCKDATA_QPARAMS_CHANGED = "customers/BlockDataQParamsChanged";
export const WHOLESALERS_QPARAMS_CHANGED = "customers/WholesalersQParamsChanged";

export const CUSTOMERSGENERALDATA_QPARAMS_CHANGED = "customers/CustomersGeneralDataQParamsChanged";


export const CREDIT_DATA = "creditData";
export const CREDIT_DATA_LOADING = "creditDataLoading";
export const CREDIT_DATA_ITEMS_COUNT = "creditDataCount";

export const CLASSIFICATION = "classification";
export const CLASSIFICATION_LOADING = "classificationLoading";
export const CLASSIFICATION_ITEMS_COUNT = "classificationCount";
export const CLASSIFICATION_FILTERS = "filters";

export const BLOCK_DATA = "blockData";
export const BLOCK_DATA_LOADING = "blockDataLoading";
export const BLOCK_DATA_ITEMS_COUNT = "cblockDataCount";

export const WHOLESALERS = "wholesalers";
export const WHOLESALERS_LOADING = "wholesalersLoading";
export const WHOLESALERS_ITEMS_COUNT = "wholesalersCount";

export const CUSTOMERSGENERALDATA = "CustomersGeneralData";
export const CUSTOMERSGENERALDATA_LOADING = "CustomersGeneralDataLoading";
export const CUSTOMERSGENERALDATA_ITEMS_COUNT = "CustomersGeneralDataCount";
