import * as constants from "./constants";

export const isItemsLoading = state => state.customers.listLoading;
export const isItemActionLoading = state => state.customers.actionsLoading;
export const getItems = state => state.customers.entities;
export const getItemsSelect = state => state.customers.entitiesSelect;
export const getItemsCount = state => state.customers.totalCount;
export const getItemForEdit = state => state.customers.itemForEdit;
export const getError = state => state.customers.error;

export const getCustomersGeneralData = state => state.customers[constants.CUSTOMERSGENERALDATA];
export const getCustomersGeneralDataCount = state => state.customers[constants.CUSTOMERSGENERALDATA_ITEMS_COUNT];
export const getCustomersGeneralDataLoading = state => state.customers[constants.CUSTOMERSGENERALDATA_LOADING];

export const getCustomersCreditData = state => state.customers[constants.CREDIT_DATA];
export const getCustomersCreditDataCount = state => state.customers[constants.CREDIT_DATA_ITEMS_COUNT];
export const getCustomersCreditDataLoading = state => state.customers[constants.CREDIT_DATA_LOADING];

export const getCustomersClassificationData = state => state.customers[constants.CLASSIFICATION];
export const getCustomersClassificationDataCount = state => state.customers[constants.CLASSIFICATION_ITEMS_COUNT];
export const getCustomersClassificationDataLoading = state => state.customers[constants.CLASSIFICATION_LOADING];
export const classificationFilters = state => state.customers[constants.CLASSIFICATION_FILTERS];

export const getCustomersBlockData = state => state.customers[constants.BLOCK_DATA];
export const getCustomersBlockDataCount = state => state.customers[constants.BLOCK_DATA_ITEMS_COUNT];
export const getCustomersBlockDataLoading = state => state.customers[constants.BLOCK_DATA_LOADING];

export const getWholesalers = state => state.customers[constants.WHOLESALERS];
export const geWholesalersCount = state => state.customers[constants.WHOLESALERS_ITEMS_COUNT];
export const getWholesalersLoading = state => state.customers[constants.WHOLESALERS_LOADING];

export const getShipToAddressRegionId = state => state.customers.itemForEdit.shipToAddress?.regionId;

export const getShipToAddressCountryId = state => state.customers.itemForEdit.shipToAddress?.countryId;

export const getBillToAddressRegionId = state => state.customers.itemForEdit.billToAddress?.regionId;

export const getBillToAddressCountryId = state => state.customers.itemForEdit.billToAddress?.countryId;

export const getValidationErrors = state => state.customers.validationErrors;




/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
 export const itemForEditId = (state) => state.customers.itemForEdit?.id;

 export const tableItemsForEdit = (state) => state.customers.itemForEdit?.equipmentTab?.customerEquipments || [];
 export const totalTableItemsCount = (state) => state.customers.itemForEdit?.equipmentTab?.totalCount || 0;
 
 export const tableItemsLoading = (state) => state.customers.equipmentLoading;
 export const tableItemsError = (state) => state.customers.equipmentError;
 
 export const addedTableItems = (state) => state.customers.itemForEdit?.createCustomerEquipments || [];
 export const removedTableItems = (state) => state.customers.itemForEdit?.deleteCustomerEquipments || [];

 export const searchTablesItems = (state) => state.customers.searchedEquipment;
 /**
  * !!!!!!!!!!!!!!!!!!!!!!!!!!
  */
 
 export const searchedCustomers = (state) => state.customers.searchedCustomers;
 export const isClientSearchLoading = (state) => state.customers.searchedCustomersLoading;
