import { Dispatch } from "redux";

import { assortmentSlice } from "./assortmentSlice";
import * as defaultActions from "../../../../../redux/_common";
import * as fields from "../../pages/assortment/edit/constants";
import { IEditItem, IEditItemRedux, IAssortmentArticles } from "../../_redux/assortment/_interfaces";
import { get } from "../../../../../api/requester";
import { getErrorMessage, getResponseErrorMessage } from "../../../../../redux/_helpers";
import { cloneDeep } from "lodash";

const { actions } = assortmentSlice;
export const moduleUrl = "/api/masterdata/assortment";
export const moduleArticlesUrl = "/api/masterdata/assortmentArticles/articlesList";

export const setValidationErrors = actions.setValidationErrors;

export const getAssortment = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);

export const deleteAssortment = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);

export const getAssortmentGeneral = (id: string) =>
  defaultActions.getItem(`${moduleUrl}/${id}`, actions, refineDataForTabs);


  
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const getTableItems = (
  params: {},
  data: { id: string; addedTableItems: IAssortmentArticles[]; removedTableItems: IAssortmentArticles[] },
) => async (dispatch: Dispatch) => {
  if (!data.id) {
    dispatch(actions.setAssortmentArticles({ items: [], itemsCount: 0 }));
    return;
  }

  dispatch(actions.startArticlesCall({ callType: "articlesLoading" }));

  try {
    const res = await get(`${moduleArticlesUrl}/${data.id}`, params);
    let items = res.items ? res.items : []; // Items Array
    const itemsCount = res.totalCount ? res.totalCount : 0; // Items Count
    // Аdd archived articles for adding
    if (data.addedTableItems && data.addedTableItems.length) {
      const archivedArticles: IAssortmentArticles[] = [];
      items.forEach((item: IAssortmentArticles) => {
        data.addedTableItems.forEach((article: IAssortmentArticles) => {
          if (item.id === article.id) {
            item.action = "created";
          } else {
            archivedArticles.push(article);
          }
        });
      });

      items = [...new Set([...items, ...archivedArticles])];
    }

    // Remove archived articles for deletion
    if (data.removedTableItems && data.removedTableItems.length) {
      items.forEach((item: IAssortmentArticles) => {
        data.removedTableItems.forEach((article: IAssortmentArticles) => {
          if (item.id === article.id) {
            item.action = "deleted";
          }
        });
      });
    }

    dispatch(actions.setAssortmentArticles({ items, itemsCount }));
  } catch (error) {
    const errors = getErrorMessage(error, "Oops.. something went wrong!");
    dispatch(actions.catchError({ errors, callType: "articlesLoading", errorProp: "assortmentArticlesError" }));
  }
};


/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const deleteTableItem = (
  id: string,
  data: {
    items: IAssortmentArticles[];
    removedTableItems: IAssortmentArticles[];
    addedTableItems: IAssortmentArticles[];
  },
) => (dispatch: Dispatch) => {
  const deletedArticleIds = cloneDeep(data.removedTableItems);
  let addedTableItems = data.addedTableItems;
  let newArticles: IAssortmentArticles[] = cloneDeep(data.items);
  newArticles = newArticles.map(a => {
    if (a.id === id) {
      a.action = "deleted";
      const isNew = Boolean(addedTableItems.find(item => item.id === a.id));
      if (isNew) {
        addedTableItems = addedTableItems.filter(item => item.id !== a.id);
      } else {
        deletedArticleIds.push(a);
      }
      return a;
    }
    return a;
  });

  dispatch(actions.deleteAssortmentArticles({ newArticles, deletedArticleIds, addedArticles: addedTableItems}));
  return deletedArticleIds;
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const addTableItem = (newArticles: IAssortmentArticles[], newArticleIds: IAssortmentArticles[]) => (
  dispatch: Dispatch,
) => {
  dispatch(actions.addAssortmentArticles({ newArticles, newArticleIds }));
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
// Products from search
export const getSearchedItems = (filter: string, assortmentId?: string, customParams:any = {}) => async (dispatch: Dispatch) => {
  try {
    const res = await get("/api/masterdata/assortmentArticles/articlesListExcept", {
      Filter: filter,
      AssortmentId: assortmentId ? assortmentId : "00000000-0000-0000-0000-000000000000",
      ...customParams,
    });
    dispatch(actions.setSearchArticles(res));
  } catch (errors) {
    const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
  }
};

// Reset searched items
export const resetSearchedItems = () => async (dispatch: Dispatch) => {
  dispatch(actions.resetSearchedItems());
};




export const updateAssortmentCode = (code: string) => (dispatch: Dispatch) => {
  dispatch(actions.updateAssortmentCode(code));
};
export const updateAssortmentDesc = (description: string) => (dispatch: Dispatch) => {
  dispatch(actions.updateAssortmentDesc(description));
};



export const saveAssortment = (article: {}, id: string | null) => {
  return defaultActions.saveItem(moduleUrl, actions, article, id, refineDataForSaving);
};

export const clearAssortmentArticles = () => (dispatch: Dispatch) => {
  dispatch(actions.clearAssortmentArticles());
};

export const getDropDownItems = (params = {}) => defaultActions.getDropDownItems(`${moduleUrl}/select`, actions, params);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearArticle = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const clearTableItemsError = () => (dispatch: Dispatch) => {
  dispatch(actions.clearAssortmentArticlesError());
};

/////////////////////////////
const refineDataForTabs = (data: IEditItem) => {
  return {
    id: data.id,
    [fields.ASSORTMENT]: {
      code: data.code,
      description: data.description,
    },
    [fields.ARTICLES]: {
      assortmentArticles: [],
      totalCount: 0,
    },
  };
};

/////////////////////////////
const refineDataForSaving = (data: IEditItemRedux) => {
  if (data.id) {
    return {
      id: data.id,
      code: data.assortmentTab.code,
      description: data.assortmentTab.description,
      createAssortmentArticles: data.createAssortmentArticles?.map(({ articleId }) => ({id: articleId})),
      deleteAssortmentArticles: data.deleteAssortmentArticles?.map(({ id }) => ({ id })),
    };
  } else {
    return {
      code: data.assortmentTab.code,
      description: data.assortmentTab.description,
      assortmentArticles: data.createAssortmentArticles?.map(({ articleId }) => ({id: articleId})),
    };
  }
};
