import { RootState } from "../../../../../redux/store";  

export const useTasks = (state: RootState) => state.rtrTasks.tasks.list;
export const useScene = (state: RootState) => state.rtrTasks.tasks.selectedScene;
export const useSceneArticlesLoading = (state: RootState) => state.rtrTasks.tasks.selectedScene.articlesLoading;
export const useTaskDetails = (state: RootState) => state.rtrTasks.tasks.taskDetails;
export const useLiveTaskChanges = (state: RootState) => state.rtrTasks.tasks.liveTaskChanges;
export const useTaskFilters = (state: RootState) => state.rtrTasks.tasks.filters;
export const useTasksListFilters = (state: RootState) => state.rtrTasks.filters;
export const useInfinityScroll = (state: RootState) => state.rtrTasks.tasks.infinityScroll;
export const useTasksCount = (state: RootState) => state.rtrTasks.tasks.totalTasks;

export const useSceneStatus = (state: RootState) => state.rtrTasks.tasks.sceneStatus;

export const useUnlockSceneValidation = (state: RootState) => state.rtrTasks.tasks.unlockSceneValidation;

export const useScenesCount = (state: RootState) => state.rtrTasks.tasks.totalScenes;

export const useAuditStatusData = (state: RootState) => state.rtrTasks.auditStatusData;

export const useLoading = (state: RootState) => state.rtrTasks.loading;
export const useError = (state: RootState) => state.rtrTasks.error;

export const useConfirmedProducts = (state: RootState) => state.rtrTasks.tasks.confirmedProducts;
export const useCorrectedProducts = (state: RootState) => state.rtrTasks.tasks.correctedProducts;

export const useAvailableProducts = (state: RootState) => state.rtrTasks.tasks.availableProducts;
