import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultReducers, defaultInitialState, IDefaultState } from "../../../../../redux/_common";
import { IArticlesDetailsList } from "../../_interface/Onboarding";

interface IState extends IDefaultState<any, any, any> {
  articlesLoading: boolean;
  articles: IArticlesDetailsList[];
}

const initialState:IState = {
    ...defaultInitialState,
    articlesLoading: false,
    articles: [],
}

const reducers = {
  ...defaultReducers,
  setArticles: (state:IState, action:PayloadAction<IArticlesDetailsList[]>) => {
    state.articles = action.payload;
    state.articlesLoading = false;
  },
  setArticlesLoading: (state: IState, action: PayloadAction<boolean>) => {
    state.articlesLoading = action.payload;
  },
}

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers,
});