import { getDefaultSlice } from "../../../../../redux/_helpers";
import * as constants from "./constants";

const customInitialState = {
  [constants.CREDIT_DATA]: [],
  [constants.CREDIT_DATA_LOADING]: false,
  [constants.CREDIT_DATA_ITEMS_COUNT]: 0,
  [constants.CLASSIFICATION]: [],
  [constants.CLASSIFICATION_LOADING]: false,
  [constants.CLASSIFICATION_ITEMS_COUNT]: 0,
  [constants.BLOCK_DATA]: [],
  [constants.BLOCK_DATA_LOADING]: false,
  [constants.BLOCK_DATA_ITEMS_COUNT]: 0,
  [constants.WHOLESALERS]: [],
  [constants.WHOLESALERS_LOADING]: false,
  [constants.WHOLESALERS_ITEMS_COUNT]: 0,
  itemForEdit: {
    equipmentTab: {
      customerEquipment: [],
      totalCount: 0,
    },
    createCustomerEquipments: [],
    deleteCustomerEquipments: [],
  },
  searchedEquipment: [],
  searchedCustomers: [],
  searchedCustomersLoading: false,
};

const customReducers = {
  countryFromRegionsFetchedShipToAddress: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.shipToAddress = { ...state.itemForEdit.shipToAddress, countryId: action.payload };
  },
  regionFromCityFetchedShipToAddress: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.shipToAddress = { ...state.itemForEdit.shipToAddress, regionId: action.payload };
  },
  countryFromRegionsFetchedBillToAddress: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.billToAddress = { ...state.itemForEdit.billToAddress, countryId: action.payload };
  },
  regionFromCityFetchedBillToAddress: (state, action) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit.billToAddress = { ...state.itemForEdit.billToAddress, regionId: action.payload };
  },
  startArticlesCall: (state, action) => {
    state.articlesError = null;
    state[action.payload.callType] = true;
  },
  setAssortmentArticles: (state, action) => {
    state.equipmentLoading = false;
    state.itemForEdit = {
      ...state.itemForEdit,
      equipmentTab: {
        customerEquipments: action.payload.items,
        totalCount: action.payload.itemsCount,
      },
    };
  },
  resetCustomerEquipment: (state) => {
    state.equipmentLoading = false;
    state.itemForEdit = {
      ...state.itemForEdit,
      equipmentTab: {
        customerEquipment: [],
        totalCount: 0,
      },
    };
  },
  deleteAssortmentArticles: (state, action) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      equipmentTab: {
        customerEquipments: action.payload.newArticles,
        totalCount: state.itemForEdit.equipmentTab.totalCount - 1,
      },
      createCustomerEquipments: action.payload.addedArticles,
      deleteCustomerEquipments: action.payload.deletedArticleIds,
    };
  },
  addAssortmentArticles: (state, action) => {
    state.itemForEdit = {
      ...state.itemForEdit,
      equipmentTab: {
        customerEquipments: action.payload.newArticles,
        totalCount: state.itemForEdit.equipmentTab.totalCount + 1,
      },
      createCustomerEquipments: action.payload.newArticleIds,
    };
  },
  setSearchArticles: (state, action) => {
    state.searchedEquipment = action.payload;
  },
  resetSearchedItems: (state, action) => {
    state.searchedEquipment = [];
  },
  setSearchCustomers: (state, action) => {
    state.searchedCustomers = action.payload;
  },
  setSearchCustomersLoading: (state, action) => {
    state.searchedCustomersLoading = action.payload;
  }
};

export const customersSlice = getDefaultSlice("customers", customReducers, customInitialState);
