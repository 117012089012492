import { RootState } from "../../../../../redux/store";
import * as constants from "./constants";

export const isItemsLoading = (state:RootState) => state.countries.listLoading;
export const isItemActionLoading = (state:RootState) => state.countries.actionsLoading;
export const getItems = (state:RootState) => state.countries.entities;
export const getItemsSelect = (state:RootState) => state.countries.entitiesSelect;
export const getItemsCount = (state:RootState) => state.countries.totalCount;
export const getItemForEdit = (state:RootState) => state.countries.itemForEdit;
export const getError = (state:RootState) => state.countries.error;
export const validationErrors = (state: RootState) => state.countries.validationErrors;

export const getCountryRegions = (state:RootState) => state.countries[constants.COUNTRY_REGIONS];
export const getCountryRegionsCount = (state:RootState) => state.countries[constants.COUNTRY_REGIONS_COUNT];
export const getCountryRegionsLoading = (state:RootState) => state.countries[constants.COUNTRY_REGIONS_LOADING];
