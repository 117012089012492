import { TableImage } from "../../../../app/components/common/images/TableImage";

export const ImageFormatter = (cell: string, row: any) => {
  const imagesData = [
    {
      title: row.name,
      url: cell ? cell : "",
      thumbnail: row.imageThumbnail ? row.imageThumbnail : "",
    },
  ];
  return (
    <span>
      <TableImage imagesData={imagesData} imageIndex={0} />
    </span>
  );
};
