import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state: RootState) => state.organizationUnits.itemsLoading;
export const isDropDownLoading = (state: RootState) => state.roles.dropDownLoading;
export const getItems = (state: RootState) => state.organizationUnits.items;
export const dropDownValues = (state: RootState) => state.organizationUnits.dropDownItems;
export const getItemsCount = (state: RootState) => state.organizationUnits.itemsCount;
export const getError = (state: RootState) => state.organizationUnits.error;

export const useOrganizationUnitTerritories = (state: RootState) =>
  state.organizationUnits.orgUnitTerritories;
export const isOrganizationUnitTerritoriesLoading = (state: RootState) =>
  state.organizationUnits.orgUnitTerritoriesLoading;
