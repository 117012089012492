import { useNavigate } from "react-router-dom";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../common/_customHooks/redux";
import { actions, selectors } from "./_redux";

export enum Access {
  PENDING = "pending",
  WRITE = "write",
  READ = "read",
}

/**
 * @Back-End response map:
 *
 * @response 0 (Read & Write Access)
 * @response 1 (Read Only Access)
 * @response Forbidden 403 (No Access) - User is redirected to /dashboard
 *
 * @return "pending" || "read" || "write"
 */
export const usePageAccessHook = (pageUrl: string): Access => {
  const dispatch = useDispatch();
  const { path, rights } = useSelector(selectors.userAccess);

  const navigate = useNavigate();

  const checkPageAccess = async () => {
    const access = await actions.getUserAccess(pageUrl);

    if (access) {
      dispatch(
        actions.setUserAccess({
          path: pageUrl,
          rights: access,
        }),
      );
    } else {
      dispatch(
        actions.setUserAccess({
          path: "/dashboard",
          rights: Access.WRITE,
        }),
      );

      navigate("/dashboard", {replace:true});
    }
  };

  if (path !== pageUrl) {
    if(pageUrl.includes(path)) return rights // Prevent check for CRUD routes
    checkPageAccess();
    return Access.PENDING;
  } else {
    return rights;
  }
};
