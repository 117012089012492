export const MENU_ITEMS = "menu";
export const MENU_ITEMS_LOADING = 'menuLoading';
export const SUB_MENU_ITEMS_LOADING = 'subMenuLoading';

export const TAB_NAV = 'tab_nav';

export const ORG_UNITS = "organizationUnits";
export const ORG_UNITS_LOADING = 'organizationUnitsLoading';


export const API = {
    getUserMenu: '/api/User/MenuItems',
    getOrgUnits: '/api/User/OrganizationUnits',
    getPageAccess: '/api/User/PageAccess',
}