import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.irSubScenes.listLoading;
export const isItemActionLoading = (state:RootState) => state.irSubScenes.actionsLoading;
export const getItems = (state:RootState) => state.irSubScenes.entities;
export const getItemsSelect = (state:RootState) => state.irSubScenes.entitiesSelect;
export const getItemsCount = (state:RootState) => state.irSubScenes.totalCount;
export const getItemForEdit = (state:RootState) => state.irSubScenes.itemForEdit;
export const getError = (state:RootState) => state.irSubScenes.error;
export const validationErrors = (state: RootState) => state.irSubScenes.validationErrors;

export const subSceneSelect = (state:RootState) => state.irSubScenes.subSceneSelect;
