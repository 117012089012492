import { stockAvailabilitySlice } from "./stockAvailabilitySlice";
import * as defaultActions from "../../../../../redux/_common";
import { Dispatch } from "redux";
import { get } from "../../../../../api/requester";
import { getErrorMessage } from "../../../../../redux/_helpers";

const { actions } = stockAvailabilitySlice;
export const moduleUrl = "/api/assetmanagement/stockavailability";
export const setValidationErrors = actions.setValidationErrors;

export const getStockAvailabilities = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);
export const getStockAvailability = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);

export const clearAll = () => defaultActions.clearAll(actions);
export const clearDocument = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);



export const getRealogramArticlesDetails = (id:string) => async (dispatch:Dispatch) => {
    dispatch(actions.setRealogramDetailsLoading(true));
    try {
        const res = await get(`${moduleUrl}/RealogramProductsDetails/${id}`);
        const asd = res.map((pArticle:any) => {
            //Realogram: top: 1260, left: 1125, width: 1224 - 1125, height: 1559 - 1260
            //Planogram: 
            // {
            //     top: 780.0000,
            //     left : 169.2000,
            //     down: 894.0000,
            //     right: 213.6000
            // }

            //Should be:  [ left, top, right, down ]

            const productCoordinates: number[] = typeof pArticle.rect === 'string'
                ? pArticle.rect.split(",").map(Number)
                : pArticle.rect; // Initial calculation

            return {
                ...pArticle,
                rect: [
                    productCoordinates[1],
                    productCoordinates[0],
                    productCoordinates[1] + productCoordinates[2],
                    productCoordinates[0] + productCoordinates[3],
                ],
            }
        })

        dispatch(actions.setRealogramArticles(asd));

    } catch (errors:any) {
        dispatch(actions.setRealogramArticles([]));
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    } finally {
        dispatch(actions.setRealogramDetailsLoading(false));
    }
}

export const getPlanogramArticlesDetails = (id:string) => async (dispatch:Dispatch) => {
    dispatch(actions.setPlanogramDetailsLoading(true));
    try {
        const res = await get(`${moduleUrl}/PlanogramProductsDetails/${id}`);
        const asd = res.map((pArticle:any) => {
            const productCoordinates: number[] = typeof pArticle.rect === 'string'
                ? pArticle.rect.split(",").map(Number)
                : pArticle.rect; // Initial calculation

            return {
                ...pArticle,
                rect: [
                    productCoordinates[1],
                    productCoordinates[0],
                    productCoordinates[3],
                    productCoordinates[2],
                ]
            }
        })

        dispatch(actions.setPlanogramArticles(asd));

    } catch (errors:any) {
        dispatch(actions.setPlanogramArticles([]));
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    } finally {
        dispatch(actions.setPlanogramDetailsLoading(false));
    }
}

export const getArticlesList = (id:string) => async (dispatch:Dispatch) => {
    dispatch(actions.setArticlesLoading(true));
    try {
        const res = await get(`${moduleUrl}/ProductsList/${id}`);
        dispatch(actions.setArticles(res));
        
    } catch (errors:any) {
        dispatch(actions.catchError({
            errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
            callType: "actionsLoading", errorProp: "error"
        }));
    } finally {
        dispatch(actions.setArticlesLoading(false));
    }
}

export const clearArticles = () => (dispatch:Dispatch) => {
    dispatch(actions.clearArticles())
}