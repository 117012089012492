import { PayloadAction } from "@reduxjs/toolkit";
import { getDefaultSlice } from "../../../../../redux/_helpers";

const customReducers = {
  coutriesFetched: (state:any, action:PayloadAction) => {
    state.listLoading = false;
    state.countries = action.payload;
  },
  setValidationErrors: (state: any, action: PayloadAction<any>) => {
    state.validationErrors = action.payload;
  },
};

export const territoriesSlice = getDefaultSlice("territories", customReducers);
