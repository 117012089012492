import { RootState } from "../../../../../redux/store";


export const onboardingsLoading = (state: RootState) => state.onboarding.itemsLoading;
export const getItems = (state: RootState) => state.onboarding.items;
export const getItemsCount = (state: RootState) => state.onboarding.itemsCount;
export const getError = (state: RootState) => state.onboarding.error;

export const getItemForEdit = (state: RootState) => state.onboarding.itemForEdit;
export const getItemLoading = (state: RootState) => state.onboarding.actionsLoading;
export const getArticlesOptions = (state:RootState) => state.onboarding.articles;
export const getArticlesLoading = (state:RootState) => state.onboarding.articlesLoading;

export const errorAction = (state: RootState) => state.onboarding.errorAction;
export const getValidationErrors = (state: RootState) => state.onboarding.validationErrors;

export const getFilters = (state: RootState) => state.onboarding.filters;
