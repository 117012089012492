import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { tableDefaultKeyColumn } from "../helpers";

const editAction = (onClickHandler: any, link="#") => ({
  tooltip: "Edit",
  link,
  testId: "edit-item",
  iconClass: "svg-icon-primary",
  iconUrl: "/media/svg/icons/Design/Edit.svg",
  onClick: onClickHandler,
});

const readonlyAction = (onClickHandler: any, link="#") => ({
  tooltip: "Readonly",
  link,
  testId: "read-item",
  iconClass: "svg-icon-primary",
  iconUrl: "/media/svg/icons/General/Visible.svg",
  onClick: onClickHandler,
});

const deleteAction = (onClickHandler: any, link="#") => ({
  tooltip: "Archive",
  link,
  testId: "delete-item",
  hover: "btn-hover-warning",
  iconClass: "svg-icon-warning",
  iconUrl: "/media/svg/icons/Communication/Archive.svg",
  onClick: onClickHandler,
});

const copyAction = (onClickHandler: any, link="#") => ({
  tooltip: "Copy",
  link,
  testId: "copy-item",
  iconClass: "svg-icon-primary",
  iconUrl: "/media/svg/icons/General/Duplicate.svg",
  onClick: onClickHandler,
});

export const actionButtons = (
  actions: any,
  dataField = "action",
  title = "Actions",
  keyField = tableDefaultKeyColumn,
  disabled = false,
) => {
  return {
    dataField: dataField,
    text: title,
    formatter: ActionsColumnFormatter,
    formatExtraData: {
      keyField: keyField,
      actions: actions,
      disabled: disabled,
    },
    classes: "text-center",
    headerClasses: "text-center",
    style: { width: "5%" },
  };
};

export const editButton = (onClickHandler:any, link:any, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionButtons([editAction(onClickHandler, link)], "edit", "", keyField, disabled);
};

export const readonlyButton = (onClickHandler:any, link:any, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionButtons([readonlyAction(onClickHandler, link)], "readonly", "", keyField, disabled);
};

export const deleteButton = (onClickHandler:any, link:any, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionButtons([deleteAction(onClickHandler, link)], "delete", "", keyField, disabled);
};

export const copyAndDeleteButtons = (
  onEditClickHandler: any,
  editLink: any,
  onDeleteClickHandler: any,
  deleteLink: any,
  keyField = tableDefaultKeyColumn,
  disabled = false,
) => {
  return actionButtons(
    [copyAction(onEditClickHandler, editLink), deleteAction(onDeleteClickHandler, deleteLink)],
    "actions",
    "",
    keyField,
    disabled,
  );
}

export const editAndDeleteButtons = (
  onEditClickHandler: any,
  editLink: any,
  onDeleteClickHandler: any,
  deleteLink: any,
  keyField = tableDefaultKeyColumn,
  disabled = false,
) => {
  return actionButtons(
    [editAction(onEditClickHandler, editLink), deleteAction(onDeleteClickHandler, deleteLink)],
    "actions",
    "",
    keyField,
    disabled,
  );
};
