import { combineReducers } from "redux";
import SagasManager from "./SagasManager";
import * as reducers from "./reducers";
import { queryApi } from "./api";

export const rootReducer = combineReducers({
    [queryApi.reducerPath]: queryApi.reducer,
    ...reducers
});

export const rootSaga = SagasManager.getRootSaga();