export const CUSTOMER_ID = "id";
export const ID = 'taskIdentifier';
export const ORDER_ID = 'orderId';
export const CUSTOMER = 'customerName';
export const CUSTOMER_ID_LONG = 'customerId'; 
export const DELIVERY_DATE = 'deliveryDate';
export const DELIVERY_ADDRESS = 'deliveryAddress';
export const ORDER_TYPE = 'orderTypeId';
export const ORDER_TYPE_ID = 'id';
export const ITEMS = 'orderItems';
export const STAUS = 'taskStatus';
export const TOTAL = 'totalPrice';
export const GENERAL = 'general';
export const WHOLESALER = 'wholesaler';
export const WHOLESALER_ID = 'wholesalerId';
export const NOTES = 'taskNotes';
export const CREATED_BY = 'createdByName';
export const CREATED_BY_ID = 'createdById';
export const CREATED_ON = 'createdOn';
export const OPEN_BALANCE = 'openBalance';
export const CREDIT_LIMIT = 'creditLimit';
export const CREDIT_EXPOSURE = 'creditExposure';
export const NAME = 'name';
export const IS_ACTIVE = 'isActive';
export const HAS_WHOLESALERS = 'hasWholesalers';
