import { PeriodsList } from "../_interfaces/reduxState";

// General Info TAB
export const TASK_ID = "taskId";
export const EXTERNAL_TASK_ID = "taskExternalId";
export const TASK_IDENTIFIER = "taskIdentifier";
export const DESCRIPTION = "description";
export const TYPE = "type";
export const TASK_NOTES = "taskNotes";
export const CUSTOMER_ID = "customerId";
export const CUSTOMER_NAME = "customerName";
export const CUSTOMER_LONG_NAME = "customerLongName";
export const STATUS = "statusName";
export const PLANNED_FROM = "plannedFrom";
export const PLANNED_TO = "plannedTo";
export const CREATED_BY = "createdBy";
export const CREATED_ON = "createdOn";
export const CHANGED_BY = "lastChangedBy";
export const CHANGED_ON = "lastChangeDate";
export const TASK_IMAGES = "taskImagesUrl";

export const PERIODS:PeriodsList = {
    today: 'today',
    week: 'week',
}
