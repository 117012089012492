export const PATHS = {
    ROOT: 'dataconfiguration',
    CUSTOMERS_DATA: 'customersdata',
    ARTICLES_DATA: 'articlesdata',
    ARTICLES_DATA_FOREIGN: 'foreignarticlesdata',
    PROMOTION_DATA: 'promotionsdata',
    LOCATION_DATA: 'locationdata',
    TERRITORY_DATA: 'territoriesdata',
    ASSIGNMENT: 'territoriesdata',
    TASK_DATA: 'taskdata',
    IR_DATA: 'irdata',
    REJECTION_RESONS: 'rejectionreasonsdata',
    ASSETS_DATA: 'assetdata',
};

export const CUSTOMER_PATHS = {
    CUSTOMER_TYPE: 'type',
    DISTRIBUTION_TYPE: 'distribution-type',
    SEGMENTS: 'segment',
    DIVISIONS: 'division',
    PARTNER_FUNCTIONS: 'partner-functions',
    CUSTOMER_HIERARCHY: 'hierarchies',
};

export const ARTICLES_PATHS = {
    CATEGORIES: 'categories',
    UOM: 'unit-of-measure',
    BRANDS: 'brands',
    FLAVORS: 'flavors',
    PACK_SIZES: 'pack-sizes',
    PACK_TYPES: 'pack-types',
};

export const ARTICLES_FOREIGN_PATHS = {
    CATEGORIES: 'categories',
    BRANDS: 'brands',
    FLAVORS: 'flavors',
    PACK_SIZES: 'pack-sizes',
    PACK_TYPES: 'pack-types',
};

export const PROMOTION_PATHS = {
    TYPES: 'types'
};

export const LOCATION_PATHS = {
    COUNTRIES: 'countries',
    REGIONS: 'regions',
    CITIES: 'cities',
    LANGUAGES: 'languages',
};

export const TERRITORY_PATHS = {
    ASSIGNMENT: 'territoriesdata',
};

export const TASKS_PATHS = {
    TASK_CATEGORIES: 'categories',
    TASK_STATUSES: 'statuses',
    TASK_TYPES: 'types',
};

export const IR_PATHS = {
    IR_SCENES: 'scens',
    IR_SUB_SCENES: 'subscenes',
    IR_REASONS: 'reasons',
    IR_MAPPING: 'mapping',
};

export const ASSETS_DATA_PATHS = {
    MODEL_BRAND: 'brand',
    MODEL_TYPE: 'type',
    MODEL_SUB_TYPE: 'subtype',
    EQUIPMENT_STATUSES: 'equipment-statuses',
    MALFUNCTIONS: 'malfunctions',
}
