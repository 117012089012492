import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.irReasons.listLoading;
export const isItemActionLoading = (state:RootState) => state.irReasons.actionsLoading;
export const getItems = (state:RootState) => state.irReasons.entities;
export const getItemsSelect = (state:RootState) => state.irReasons.entitiesSelect;
export const getItemsCount = (state:RootState) => state.irReasons.totalCount;
export const getItemForEdit = (state:RootState) => state.irReasons.itemForEdit;
export const getError = (state:RootState) => state.irReasons.error;
export const validationErrors = (state: RootState) => state.irReasons.validationErrors;
