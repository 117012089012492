import React from "react";

const types = {
    True: {
        color: '#FFA800',
        label: 'Foreign'
    },
    False: {
        color: '#3699FF',
        label: 'Own'
    }
}

export const AssetTypeFormatter = (cellValue:'False' | 'True') => {
    const currentType:any = types[cellValue];
    return (
        <span className={`label label-lg label-inline`} style={{ background: 'none' }}>
            <span className="border-radius-md" style={{
                width: 8,
                height: 8,
                marginRight: 8,
                background: currentType.color,
            }}/> {currentType.label}
        </span>
    );
};
