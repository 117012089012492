import { Dispatch } from "redux";
import { routePlanningSlice } from "./routePlanningSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get } from "../../../../../api/requester";


const moduleUrl = '';
const { actions } = routePlanningSlice;
export const setValidationErrors = actions.setValidationErrors;

export const getItems = (params:any) => {
    return defaultActions.getItems(moduleUrl, actions, params);
}


export const clearAll = () => defaultActions.clearAll(actions);
export const clearRouteItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);