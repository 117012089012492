import { FC } from "react";
import { Button } from "react-bootstrap";
import SvgIcon from "../SvgIcon";

type IFieldClearButton = {
    value:string;
    clearFunction: (val:any) => void;
}

const FieldClearButton:FC<IFieldClearButton> = ({ value, clearFunction }) => {
    return (
        <Button
            style={{
                top: " 0.4rem",
                right: "0.6rem",
                width: "28px",
                height: "28px",
                padding: "0",
                opacity: 0.5,
                zIndex: 10,
                display: value ? 'initial' : 'none'
            }}
            className="btn-white absolute"
            onClick={(e: any) => {
                e.preventDefault();
                clearFunction('');
            }}
        >
            <SvgIcon
                iconLocation="/media/svg/icons/Navigation/Close-2.svg"
                size="3"
                bootstrapColor="light-grey"
                title="clear"
            />
        </Button>
    );
}

export default FieldClearButton