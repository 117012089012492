import { RootState } from "../../../../../redux/store";

export const planogramsLoading = (state:RootState) => state.planogram.itemsLoading;
export const loadingBuilder = (state:RootState) => state.planogram.loadingPlanogramBuilder;
export const saveLoading = (state:RootState) => state.planogram.planogramSaveLoading;
export const actionsLoading = (state:RootState) => state.planogram.actionsLoading;
export const deleteLoading = (state:RootState) => state.planogram.deleteLoading;
export const copyLoading = (state:RootState) => state.planogram.copyLoading;

export const error = (state: RootState) => state.planogram.error;
export const warning = (state: RootState) => state.planogram.warning;
export const errorAction = (state: RootState) => state.planogram.errorAction;
export const validationErrors = (state: RootState) => state.planogram.validationErrors;


export const planograms = (state: RootState) => state.planogram.items;
export const templatesCount = (state: RootState) => state.planogram.itemsCount;
export const itemForEdit = (state: RootState) => state.planogram.itemForEdit;
export const assignment = (state: RootState) => state.planogram.assignment;
export const assignmentMessage = (state: RootState) => state.planogram.assignmentMessage;
export const planogramArticles = (state:RootState) => state.planogram.products;
export const productsLoading = (state:RootState) => state.planogram.productsLoading;

export const filters = (state: RootState) => state.planogram.filters;

export const getPlanogramUpdated = (state:RootState) => state.planogram.planogramUpdated;

export const deletedSections = (state:RootState) => state.planogram.deletedSections;
export const deletedShelves = (state:RootState) => state.planogram.deletedShelves;
export const deletedProducts = (state:RootState) => state.planogram.deleteProducts;