import { getCurrentAccessToken, getCurrentUserOrganizationUnit } from "../app/user-access/organizationUnitStorage";
import { testURL } from "../test/common/helpers";
import axios from "axios";

/**
 * API requester (data layer handler)
 * @path - string - "/signup​/${token}"
 * @params - {} - query params - {param: value}
 * @formData - {} - post/put data - {key: value}
 * @options - {} - additional request data - {key: value}
 */
function request(method: string) {
  return async (path: string, params: {[key: string]: any} = {}, formData?: any, options?: any, headers?: any) => {
    const contentType = options?.contentType ? options?.contentType : "application/json";

    // 1. Construct URL
    const baseUrl = process.env.REACT_APP_BASE_URL || testURL; // url fallback is set for tests
    let url = new URL(path, baseUrl);
    if (Object.entries(params).length) {
      for (const [param, value] of Object.entries(params)) {
        url.searchParams.append(param, value);
      }
    }

    // 2. Construct Authorization
    const currentAccessTokenResponse = await getCurrentAccessToken();
    const userOrgUnit = getCurrentUserOrganizationUnit();
    let authHeader = {
      Authorization: "",
      Organizationunit: "",
    };
    if (currentAccessTokenResponse?.accessToken) {
      authHeader.Authorization = `Bearer ${currentAccessTokenResponse.accessToken}`;
    }
    if (userOrgUnit) {
      authHeader.Organizationunit = userOrgUnit;
    }
    // 3. Make Request - via Fetch API - can be changed
    try {
      const response = await axios({
        method,
        url,
        headers: {
          "Content-Type": contentType,
          Accept: "application/json",
          ...(headers || {}),
          ...authHeader,
        },
        data: contentType.includes("multipart/form-data") ? formData : JSON.stringify(formData),
        ...options,
      });
      // 4. Return response data
      return response.data;
    } catch (error: any) {
      const errorData = error.response ? error.response.data : error;
      console.error(errorData);
      // 4.1. Throw the error object
      throw errorData;
    }
  };
}
// 5. Expose Request methods
export const get = request("get");
export const post = request("post");
export const put = request("put");
export const del = request("delete");
