import { PayloadAction } from "@reduxjs/toolkit";
import { getDefaultSlice } from "../../../../../redux/_helpers";

const customReducers = {
  countryFromRegionsFetched: (state:any, action:PayloadAction) => {
    state.error = null;
    state.actionsLoading = false;
    state.listLoading = false;
    state.itemForEdit = { ...state.itemForEdit, countryId: action.payload };
  },
  regionFromCityFetched: (state:any, action:PayloadAction) => {
    state.error = null;
    state.actionsLoading = false;
    state.itemForEdit = { ...state.itemForEdit, regionId: action.payload };
  },
};

const initiaState = {};

export const citiesSlice = getDefaultSlice("cities", customReducers, initiaState);
