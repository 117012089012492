import { taskCategoriesSlice } from "./taskCategoriesSlice";
import * as defaultActions from "../../../../../redux/_helpers";
import * as constants from "./constants";

export const QPARAMS_CHANGED = "taskCategories/QParamsChanged";
const { actions } = taskCategoriesSlice;

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "TaskCategories_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "TaskCategories_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("TaskCategories_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("TaskCategories_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "TaskCategories_Post",
  actions,
  defaultActions.convertCreateItemParams(["code", "name", "nameLocal", "active", "order"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("TaskCategories_Put", actions);

export const fetchTaskCategoryTypesSwagger = defaultActions.fetchCustomItemsSwagger(
  "TaskCategories_GetTaskCategoryTypes",
  actions,
  constants.TASKCATEGORY_TYPES,
  constants.TASKCATEGORY_TYPES_LOADING,
  constants.TASKCATEGORY_TYPES_COUNT,
);

export const fetchTaskTypesSwagger = defaultActions.fetchCustomItemsSwagger(
  'TaskTypes_GetSelect',
  actions,
  constants.TASKCATEGORY_TYPES,
  constants.TASKCATEGORY_TYPES_LOADING,
  constants.TASKCATEGORY_TYPES_COUNT,
);

export const clear = () => defaultActions.clear(actions);
export const clearErrors = () => defaultActions.clearError(actions);
