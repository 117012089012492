import * as constants from "./constants";

export const isItemsLoading = state => state.taskTypes.listLoading;
export const isItemActionLoading = state => state.taskTypes.actionsLoading;
export const getItems = state => state.taskTypes.entities;
export const getItemsCount = state => state.taskTypes.totalCount;
export const getItemForEdit = state => state.taskTypes.itemForEdit;
export const getError = state => state.taskTypes.error;
export const validationErrors = state => state.taskTypes.validationErrors;

export const getTaskTypeSurveys = state => state.taskTypes[constants.TASK_TYPE_SURVEYS];
export const getTaskTypeSurveysLoading = state => state.taskTypes[constants.TASK_TYPE_SURVEYS_LOADING];
