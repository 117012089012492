import { FC } from "react";
import { Modal } from "react-bootstrap";
import SvgIcon from "../SvgIcon";

type IImageModalBody = {
    lastIndex: number;
    url:string;
    prevImage:(val:number) => void;
    nextImage:(val:number) => void;
    index: number;
    showArrows:boolean;
}

const ImageModalBody:FC<IImageModalBody> = ({
    lastIndex,
    prevImage,
    nextImage,
    index,
    url,
    showArrows
}) => {
    return (
        <Modal.Body className={`${lastIndex !== 0 ? "px-4" : ""} rounded-bottom`} style={{background: "#EDEFF7"}}>
            <section className="d-flex justify-content-between">
                {showArrows && (
                    <span className="d-flex align-items-center" onClick={() => prevImage(index)} >
                    <SvgIcon
                        iconLocation="/media/svg/icons/Navigation/Angle-left.svg"
                        size="4"
                        bootstrapColor={index === 0 ? "muted" : "primary"}
                        customClasses={`${index === 0 ? "pointer-events-none" : "pointer"} m-0 p-0`}
                        title="Pervious"
                    />
                    </span>
                )}

                <img
                    className="rounded mx-auto"
                    src={url}
                    alt="full_size_image"
                    style={{ maxHeight: "85vh", width: "auto", maxWidth: `${lastIndex === 0 ? "100%" : "90%"}` }}
                />

                {showArrows && (
                    <span className="d-flex align-items-center" onClick={() => nextImage(index)} >
                    <SvgIcon
                        iconLocation="/media/svg/icons/Navigation/Angle-right.svg"
                        size="4"
                        bootstrapColor={index === lastIndex ? "muted" : "primary"}
                        customClasses={`${index === lastIndex ? "pointer-events-none" : "pointer"} m-0 p-0`}
                        title="Next"
                    />
                    </span>
                )}
            </section>
        </Modal.Body>
    );
}

export default ImageModalBody;