/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { getAccountInfo } from "../../../../app/auth/accountInfo";
import SvgIcon from "../../../../app/components/common/SvgIcon";

export function QuickUserToggler() {
  const account = getAccountInfo();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="quick-user-tooltip">User details</Tooltip>}>
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <SvgIcon
                  iconLocation="/media/svg/icons/General/User.svg"
                  size="4"
                  customClasses="mr-2"
                  title="User Details"
                  bootstrapColor="primary"
                />
                <span className="symbol symbol-35 symbol-light-light">
                  <span className="symbol-label fw-400 w-100 p-3 text-primary">
                  {account?.fullName.split(" ")[0]}
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
