import { Dispatch } from "redux";
import { v4 as uuidv4 } from "uuid";

import { templatesSlice } from "./templatesSlice";
import * as defaultActions from "../../../../../redux/_common";
import { ICreateTemplate, ICreatedShelf, IPlanogramTemplate, ISection, IShelf, IUpdateTemplate, IUpdatedShelf } from "../../_interfaces/builder";
import { get, post, put } from "../../../../../api/requester";
import { getErrorMessage } from "../../../../../redux/_helpers";
import { MIN_DIMENSION, SCALE_FACTOR, SECTION_DEFAULT_COLOR, SHELF_DEFAULT_COLOR } from "../../constants";
import { IAssetTemplate } from "../../_interfaces/state";
import { scaleValueForBE } from "../planogram/actionUtils";

const { actions } = templatesSlice;

export const moduleUrl = "/api/planogram/templates";

export const setValidationErrors = actions.setValidationErrors;


export const clearAll = () => defaultActions.clearAll(actions);
export const clearItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const catchError = (error:{errors:string, callType:string, errorProp:string}) => actions.catchError(error);
export const catchWarning = (error:string) => actions.setWarning(error);
export const clearWarning = () => actions.clearWarning();

export const removeLoader = () => async (dispatch:Dispatch)  => dispatch(actions.setLoadingTemplateBuilder(false));
export const setTemplateUpdated = (value:boolean) => actions.setTemplateUpdated(value);

// MAIN LIST ACTIONS
export const getTemplates = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);
export const deleteTemplate = (id: string) => defaultActions.deleteItem(`${moduleUrl}/${id}`, actions);
export const saveTemplate = (template:IPlanogramTemplate, id: string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setTemplateSaveLoading(true));
    
    const templateForSave:ICreateTemplate = refineDataForCreation(template);
    const response = await post(moduleUrl, {}, templateForSave);
    dispatch(actions.setTemplateSaveLoading(false));
    return response
  } catch (errors:any) {
    dispatch(actions.catchError({
        errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
        callType: "actionsLoading", errorProp: "error"
    }));
    dispatch(actions.setTemplateSaveLoading(false));
  }
}


export const updateTemplate = (template: IPlanogramTemplate, deleteSections: [], deletedShelves: []) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setTemplateSaveLoading(true));
    
    const tempalteForUpdate:IUpdateTemplate = refineDataForUpdate(template, deleteSections, deletedShelves);
    await put(moduleUrl, {}, tempalteForUpdate);

    dispatch(actions.setLoadingTemplateBuilder(true));
    const newTemplate = await get(`${moduleUrl}/${template.id}`);
    dispatch(actions.setItem(refineTemplateData(newTemplate)));
    dispatch(actions.resetDeleteSections());
    dispatch(actions.setLoadingTemplateBuilder(false));

    dispatch(actions.setTemplateSaveLoading(false));
  } catch (errors:any) {
    dispatch(actions.setTemplateSaveLoading(false));
    dispatch(actions.catchError({
        errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
        callType: "actionsLoading", errorProp: "error"
    }));
  }
}
export const copyTemplate = (id: string) => async (dispatch:Dispatch) => {
  try {
    const response = await post(`${moduleUrl}/Copy`, {}, {id});
    return response
  } catch (errors:any) {
    dispatch(actions.catchError({
        errors: getErrorMessage(errors, "Oops.. something went wrong!"),//constructErrors(errors),
        callType: "actionsLoading", errorProp: "error"
    }));
    // const message = getResponseErrorMessage(errors, "Error occurred!");
    // dispatch(actions.setError({ status: errors?.status, message }));
  }
}

//CREATE MODAL ACTIONS
export const fetchEquipmentAssetTempaltes = () => async (dispatch:Dispatch) => {
  try {
    const result = await get('/api/assetmanagement/assetmodels/listForPlanogramTemplate');
    dispatch(actions.setAssetTemplates(result));
  } catch (errors:any) {
    const message = getErrorMessage(errors, "Error occurred!");
    dispatch(actions.catchError({ message, callType: "actionsLoading", errorProp: "error" }));
  }
}

export const setSelectedAssetTemplate = (template:IAssetTemplate | null) => actions.setSelectedAssetTemplate(template)

export const getTemplate = (id:string, returnTemplate:boolean = false) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setLoadingTemplateBuilder(true));
    dispatch(actions.setItem(null));
    const template = await get(`${moduleUrl}/${id}`);
    if (returnTemplate) {
      dispatch(actions.setLoadingTemplateBuilder(false));
      return template
    } else {
      dispatch(actions.setItem(refineTemplateData(template)));
    }
    dispatch(actions.setTemplateUpdated(false))
    dispatch(actions.setLoadingTemplateBuilder(false));
  } catch (errors:any) {
    dispatch(actions.setLoadingTemplateBuilder(false));
    dispatch(actions.catchError({ message: 'Something went wrong!', callType: "actionsLoading", errorProp: "error" }));
  }
}

// BUILDER ACTIONS
export const setItem = (template:IPlanogramTemplate) => actions.setItem(template);
export const setSections = (sections:ISection[]) => actions.setSections(sections);
export const setTemplateTitle = (title:string) => actions.setTemplateTitle(title);
export const setTemplateActive = (active:boolean) => actions.setTemplateActive(active);
export const setDeletedSections = (id:string) => actions.setDeletedSections({ id });
export const setDeletedShelves = (id:string, sectionId:string) => actions.setDeletedShelves({ id, sectionId });
export const resetDeleteSections = () => actions.resetDeleteSections();



// Utility functions
const refineTemplateData = (template:any):IPlanogramTemplate => {
  const result = {
    ...template,
    dimensions: {
      width: Math.max(Number(template.dimensions.width), MIN_DIMENSION) * SCALE_FACTOR,
      height: Math.max(Number(template.dimensions.height), MIN_DIMENSION) * SCALE_FACTOR,
      depth: Math.max(Number(template.dimensions.depth), MIN_DIMENSION) * SCALE_FACTOR
    },
    sections: template.sections.map((section:any, order:number) => {
      return {
        ...section,
        original: true,
        thickness: section.thickness * SCALE_FACTOR,
        color: section.color || SECTION_DEFAULT_COLOR,
        order: order+1,
        innerDimensions: {
          width: Math.max(Number(section.dimensions.width), MIN_DIMENSION) * SCALE_FACTOR,
          height: Math.max(Number(section.dimensions.height), MIN_DIMENSION) * SCALE_FACTOR,
          depth: Math.max(Number(section.dimensions.depth), template.dimensions.depth) * SCALE_FACTOR
        },
        dimensions: {
          width: (Math.max(Number(section.dimensions.width), MIN_DIMENSION) + section.thickness * 2) * SCALE_FACTOR,
          height: (Math.max(Number(section.dimensions.height), MIN_DIMENSION) + section.thickness * 2) * SCALE_FACTOR,
          depth: (Math.max(Number(section.dimensions.depth), template.dimensions.depth) + section.thickness * 2) * SCALE_FACTOR
        },
        shelves: section.shelves.map((shelf:any, shelfOrder:number) => ({
          ...shelf,
          sectionId: shelf.templateSectionId,
          id: shelf.id || uuidv4(),
          order: shelfOrder+1,
          original: true,
          products: [],
          thickness: shelf.thickness * SCALE_FACTOR,
          color: shelf.color || SHELF_DEFAULT_COLOR,
          dimensions: {
            width: Math.max(Number(shelf.dimensions.width), shelf.dimensions.width) * SCALE_FACTOR,
            height: Math.max(Number(shelf.dimensions.height) + shelf.thickness, MIN_DIMENSION + section.thickness) * SCALE_FACTOR,
            depth: Math.max(Number(shelf.dimensions.depth), shelf.dimensions.depth) * SCALE_FACTOR,
          }
        })),
      }
    }),
  }

  return result
}

const refineDataForCreation = (template:IPlanogramTemplate):ICreateTemplate => {
  const result = {
    title: template.title,
    dimensions: {
      width: scaleValueForBE(template.dimensions.width),
      depth: scaleValueForBE(template.dimensions.depth),
      height: scaleValueForBE(template.dimensions.height),
    },
    isActive: template.isActive,
    sections: template.sections.map((section, index) => ({
      order: index + 1,
      color: section.color,
      thickness: section.thickness / SCALE_FACTOR,
      dimensions: {
        width: scaleValueForBE(section.innerDimensions.width),
        depth: scaleValueForBE(section.innerDimensions.depth),
        height: scaleValueForBE(section.innerDimensions.height),
      },
      shelves: section.shelves.map((shelf, i) => ({
        order: i + 1,
        thickness: shelf.thickness / SCALE_FACTOR,
        color: shelf.color || SHELF_DEFAULT_COLOR,
        dimensions: {
          width: scaleValueForBE(shelf.dimensions.width),
          depth: scaleValueForBE(shelf.dimensions.depth),
          height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
        }
      }))
    }))
  };

  return result
}

const refineDataForUpdate = (
  template:IPlanogramTemplate,
  deleteSections: {id:string}[],
  deletedShelves:{id:string, sectionId:string}[]
):IUpdateTemplate => {
  const createSections: ISection[] = template.sections.filter(({original}) => !original);
  const updateSections: ISection[] =template.sections.filter(({ updated }) => updated);

  const convertUpdatedShelf = (shelf:IShelf, sectionId:string):IUpdatedShelf => {
    return {
      id: shelf.id,
      templateSectionId: sectionId,
      order: shelf.order,
      thickness: shelf.thickness / SCALE_FACTOR,
      color: shelf.color || SHELF_DEFAULT_COLOR,
      dimensions: {
        width: scaleValueForBE(shelf.dimensions.width),
        depth: scaleValueForBE(shelf.dimensions.depth),
        height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
      },
    }
  }

  return {
    id: template.id,
    title: template.title,
    isActive: template.isActive,
    dimensions: {
      width: scaleValueForBE(template.dimensions.width),
      depth: scaleValueForBE(template.dimensions.depth),
      height: scaleValueForBE(template.dimensions.height),
    },
    createSections: createSections.map((section) => ({
      order: section.order,
      thickness: section.thickness / SCALE_FACTOR,
      color: section.color || SECTION_DEFAULT_COLOR,
      dimensions: {
        width: scaleValueForBE(section.innerDimensions.width),
        depth: scaleValueForBE(section.innerDimensions.depth),
        height: scaleValueForBE(section.innerDimensions.height),
      },
      shelves: section.shelves.map((shelf) => ({
        order: shelf.order,
        thickness: shelf.thickness / SCALE_FACTOR,
        color: shelf.color || SHELF_DEFAULT_COLOR,
        dimensions: {
          width: scaleValueForBE(shelf.dimensions.width),
          depth: scaleValueForBE(shelf.dimensions.depth),
          height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
        }
      }))
    })),
    updateSections: updateSections.map((section) => ({
      id: section.id,
      templateId: template.id,
      order: section.order,
      color: section.color || SECTION_DEFAULT_COLOR,
      thickness: section.thickness / SCALE_FACTOR,
      dimensions: {
        width: scaleValueForBE(section.innerDimensions.width),
        depth: scaleValueForBE(section.innerDimensions.depth),
        height: scaleValueForBE(section.innerDimensions.height),
      },
      updateShelves: section.shelves.reduce((a:IUpdatedShelf[], shelf:IShelf) => ([
        ...a,
        ...(shelf.updated ? [convertUpdatedShelf(shelf, section.id)] : [])
      ]), []),
      createShelves: section.shelves.reduce((a:ICreatedShelf[], shelf) => ([
        ...a,
        ...(!shelf.original ? [{
          order: shelf.order,
          color: shelf.color || SHELF_DEFAULT_COLOR,
          thickness: shelf.thickness / SCALE_FACTOR,
          dimensions: {
            width: scaleValueForBE(shelf.dimensions.width),
            depth: scaleValueForBE(shelf.dimensions.depth),
            height: scaleValueForBE(shelf.dimensions.height - shelf.thickness),
        } }] : [])
      ]), []),
      deleteShelves: deletedShelves.filter(({ sectionId }) => sectionId === section.id),
    })),
    deleteSections,
  };
}