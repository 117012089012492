import { useState, useCallback } from "react";
import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import { IQueryParams } from "../../../../app/common/interfaces/API";

type HookP = [ value:IQueryParams, setter:(newValue:IQueryParams) => void ]

export const useQueryParamsState = (initialParams:IQueryParams):HookP => {
  const [queryParams, setQueryParamsBase] = useState(initialParams);

  const setQueryParams = useCallback((nextQueryParams:IQueryParams) => {
    setQueryParamsBase((prevQueryParams:IQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  return [queryParams, setQueryParams];
};
