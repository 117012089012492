export const PATHS = {
    ROOT: 'route-planning',
    ROUTES_LIST: 'routes-list',
    ROUTE_PREVIEW: 'preview',
}

export const DATE = 'date';
export const ROLE = 'role';
export const ASSIGNED_TO = 'assigned_to';
export const DURATION = 'duration';
export const DISTANCE = 'distance';
export const STOPS = 'stops';
export const TASKS = 'tasks';

export const TABLE_COLUMNS = [
    {
        dataField: DATE,
        text: 'Date',
        sort: true,
        style: { width: "5%" },
    },
    {
        dataField: ROLE,
        text: 'Role',
        sort: true,
        style: { width: "5%" },
    },
    {
        dataField: ASSIGNED_TO,
        text: 'Assigned to',
        sort: true,
        style: { width: "35%" },
    },
    {
        dataField: DURATION,
        text: 'Duration',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: DISTANCE,
        text: 'Distance',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: STOPS,
        text: 'Stops',
        sort: true,
        style: { width: "8%" },
    },
    {
        dataField: TASKS,
        text: 'Tasks',
        sort: true,
        style: { width: "8%" },
    },
];