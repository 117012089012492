import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { tableDefaultKeyColumn } from "../helpers";

export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const monthsLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const editAction = onClickHandler => ({
  tooltip: "Edit",
  testId: "edit-item",
  iconClass: "svg-icon-primary",
  iconUrl: "/media/svg/icons/Design/Edit.svg",
  onClick: onClickHandler,
});

const readonlyAction = onClickHandler => ({
  tooltip: "Readonly",
  testId: "read-item",
  iconClass: "svg-icon-primary",
  iconUrl: "/media/svg/icons/General/Visible.svg",
  onClick: onClickHandler,
});

const deleteAction = onClickHandler => ({
  tooltip: "Archive",
  testId: "delete-item",
  iconClass: "svg-icon-warning",
  iconUrl: "/media/svg/icons/Communication/Archive.svg",
  onClick: onClickHandler,
});

const removeAction = onClickHandler => ({
  tooltip: "Remove",
  testId: "remove-item",
  hover: "btn-hover-danger svg-red-element",
  iconClass: "svg-icon-danger",
  iconUrl: "/media/svg/icons/Code/Minus2.svg",
  onClick: onClickHandler,
});

export const actionColumn = (
  actions,
  dataField = "action",
  title = "Actions",
  keyField = tableDefaultKeyColumn,
  disabled = false,
) => {
  return {
    dataField: dataField,
    text: title,
    formatter: ActionsColumnFormatter,
    formatExtraData: {
      keyField: keyField,
      actions: actions,
      disabled: disabled,
    },
    classes: "text-center",
    headerClasses: "text-center",
    style: { width: "5%" },
  };
};

export const editColumn = (onClickHandler, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionColumn([editAction(onClickHandler)], "edit", "", keyField, disabled);
};

export const readonlyColumn = (onClickHandler, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionColumn([readonlyAction(onClickHandler)], "readonly", "", keyField, disabled);
};

// Bucket icon - for BE delete
export const deleteColumn = (onClickHandler, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionColumn([deleteAction(onClickHandler)], "archive", "", keyField, disabled);
};

// Sigh icon - for FE soft delete
export const removeColumn = (onClickHandler, keyField = tableDefaultKeyColumn, disabled = false) => {
  return actionColumn([removeAction(onClickHandler)], "remove", "", keyField, disabled);
};

export const editDeleteColumn = (
  onEditClickHandler,
  onDeleteClickHandler,
  keyField = tableDefaultKeyColumn,
  disabled = false,
) => {
  return actionColumn(
    [editAction(onEditClickHandler), deleteAction(onDeleteClickHandler)],
    "actions",
    "",
    keyField,
    disabled,
  );
};
