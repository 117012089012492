import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getCurrentAccessToken, getCurrentUserOrganizationUnit } from '../../app/user-access/organizationUnitStorage';
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const queryApi = createApi({   
    reducerPath: 'queryApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: async (headers) => {
            const currentAccessTokenResponse = await getCurrentAccessToken();
            if(currentAccessTokenResponse?.accessToken) headers.set('Authorization', `Bearer ${currentAccessTokenResponse.accessToken}`)
            const userOrgUnit = getCurrentUserOrganizationUnit();
            if(userOrgUnit) headers.set('Organizationunit', userOrgUnit)

            return headers
        }
    }),
    tagTypes: [
        "PlanogramsList",
        "PlanogramTemplates",
        "TaskAttachmentsList",
        "CustomerAttachmentsList",
        "RecentActivitiesList",
        "StockAvailabilityEvents",
        "CustomerReportsList"
    ],
    endpoints: () => ({})
})
