
export const GENERAL = 'general';

export const ID = "id";
export const TITLE = 'title';
export const LINK = 'url';
export const FILE = 'file';
export const FILE_LINK = 'fileLink';
export const ASSIGNED_TO = 'assetModelList';
export const SIZE = 'size';
export const NAME = 'name';
export const ASSET_DOCUMENT_ID = 'assetModelAssetModelDocumentId';

export const DELETE_ASSIGNMENTS = 'deleteAssetModelAssetModelDocuments';