import React from "react";
import { getTouched, getError } from "./_helper"

export function Error({ touched, errors, name }) {
  if (touched && errors && name) {
    const touchedField = getTouched(touched, name);
    let errorField = getError(errors, name);
    if (touchedField && errorField) {
      return <div className="invalid-feedback d-block">{errorField.toString()}</div>;
    }
  }
  return null;
}
