import { customerHierarchySlice } from "./customerHierarchySlice";
import * as defaultActions from "../../../../../redux/_helpers";

const { actions } = customerHierarchySlice;

export const QPARAMS_CHANGED = "customerHierarchy/QParamsChanged";

export const setValidationErrors = actions.setValidationErrors;
export const clearItem = () => defaultActions.clearItem(actions);

export const fetchItemsWithDelaySwagger = defaultActions.fetchItemsWithDelaySwagger(
  "CustomerHierarchies_Get",
  actions,
  QPARAMS_CHANGED,
);

export const fetchItemsSwagger = defaultActions.fetchItemsSwagger("CustomerHierarchies_Get", actions);

export const fetchItemsSwaggerSelect = defaultActions.fetchItemsSwagger(
  "CustomerHierarchies_GetSelect",
  actions,
  "entitiesSelect",
  "selectError",
);

export const fetchItemSwagger = defaultActions.fetchItemSwagger("CustomerHierarchies_GetById", actions);

export const deleteItemSwagger = defaultActions.deleteItemSwagger("CustomerHierarchies_Delete", actions);

export const createItemSwagger = defaultActions.createItemSwagger(
  "CustomerHierarchies_Post",
  actions,
  defaultActions.convertCreateItemParams(["customerId", "hierNode", "hierLevel", "description"]),
);

export const updateItemSwagger = defaultActions.updateItemSwagger("CustomerHierarchies_Put", actions);

export const clear = () => defaultActions.clear(actions);

export const clearError = () => defaultActions.clearError(actions);
