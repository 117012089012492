import { defaultReducers, defaultInitialState } from "../../../../../redux/_common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStateOrg } from "../../_interfaces/reduxState";

const initialState: IStateOrg = {
  ...defaultInitialState,
  orgUnitTerritories: [],
  orgUnitTerritoriesLoading: false,
};

const reducers = {
  ...defaultReducers,
  dropDownTerritoriesLoading: (state: IStateOrg) => {
    state.orgUnitTerritories = [];
    state.orgUnitTerritoriesLoading = true;
  },
  getDropDownTerritories: (state: IStateOrg, action: PayloadAction<any>) => {
    state.orgUnitTerritories = action.payload;
    state.orgUnitTerritoriesLoading = false;
  },
  clearOrgUnitTerritories: (state: IStateOrg) => {
    state.orgUnitTerritories = [];
    state.orgUnitTerritoriesLoading = false;
  }
};

export const orgSlice = createSlice({
  name: "organizationUnits",
  initialState,
  reducers,
});
