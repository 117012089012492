import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IState,
  ITask,
  IScene,
  ISceneStatus,
  IAvailableProducts,
  IConfirmedProducts,
  ICorrectedProducts,
  IAuditStatuses,
  IError,
  IScroll,
  IDetails,
  ITaskFilters,
  IFeed,
  IProducts,
  ISceneHeader,
} from "../../_interfaces/reduxState";

const initialState: IState = {
  filters: {},
  tasks: {
    list: [],
    totalTasks: 0,
    totalScenes: 0,
    liveTaskChanges: {},
    filters: {
      byTask: {
        startDate: null,
        endDate: null,
        search: "",
        id: "",
        ComplexSearch: "",
      },
      byScene: {
        sceneTypes: [],
        sceneStatus: [],
        bdActions: [],
      }
    },
    infinityScroll: {
      currentPage: 1,
      tasksPerPage: 15,
    },
    selectedScene: {
      header: {
        id: "",
        customerName: "",
        customerNumber: "",
        createdOn: "",
        customerAddress: "",
        territory: "",
        userName: "",
        details: [],
        userIdentifier: "",
        name: "",
        surname: 'string',
        identifier: "",
      },
      articles: [],
      articlesLoading: false,
    },
    taskDetails: {
      header: {
        id: "",
        identifier: "",
        customerName: "",
        customerNumber: "",
        createdOn: "",
        customerAddress: "",
        territory: "",
        userName: "",
        details: [],
        userIdentifier: "",
        name: '',
        surname: 'string',
      },
      sceneLocations: [],
      taskSummary: {
        purity: 0,
        occupancy: 0,
        unknown: 0,
        own: 0,
        foreign: 0,
        empty: 0,
        accuracy: 0,
        probability: 0,
      },
      articles: [],
      monitoringDetails: [],
    },
    sceneStatus: { status: "", viewOnly: false },
    confirmedProducts: {},
    correctedProducts: {},
    availableProducts: [],
    unlockSceneValidation: false,
  },
  auditStatusData: [],
  loading: false,
  error: {status: "", message: ""},
};

interface ITasks {
  totalSessions: number;
  totalScenes: number;
  items: ITask[];
}

export const rtrTasksSlice = createSlice({
  name: "rtrTasks",
  initialState,
  reducers: {
    setFilter: (state: IState, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    resetTaskFilters : (state:IState, action: PayloadAction) => {
      state.tasks.filters = initialState.tasks.filters;
    },
    getTasks: (state: IState, action: PayloadAction<ITasks>) => {
      state.tasks = {
        ...state.tasks,
        list: [...state.tasks.list, ...action.payload.items],
        totalTasks: action.payload.totalSessions,
        totalScenes: action.payload.totalScenes,
      };
    },
    resetTasks: (state: IState) => {
      state.tasks = {
        ...state.tasks,
        list: [],
      };
    },
    setSceneProductsLoading: (state:IState, action:PayloadAction<boolean>) => {
      state.tasks = {
        ...state.tasks,
        selectedScene: {
          ...state.tasks.selectedScene,
          articlesLoading: action.payload,
        },
      };
    },
    getSceneProducts: (state:IState, action:PayloadAction<IProducts[]>) => {
      state.tasks = {
        ...state.tasks,
        selectedScene: {
          ...state.tasks.selectedScene,
          articles: action.payload,
          articlesLoading: false,
        },
        confirmedProducts: {},
        correctedProducts: {},
        availableProducts: [],
        unlockSceneValidation: false,
      };
    },
    getSceneHeader: (state:IState, action:PayloadAction<ISceneHeader>) => {
      state.tasks = {
        ...state.tasks,
        selectedScene: {
          ...state.tasks.selectedScene,
          header: action.payload,
        },
        confirmedProducts: {},
        correctedProducts: {},
        availableProducts: [],
        unlockSceneValidation: false,
      };
    },
    getTaskDetails: (state: IState, action: PayloadAction<IDetails>) => {
      state.tasks = {
        ...state.tasks,
        taskDetails: action.payload,
      };
    },
    setLiveTaskChanges: (state: IState, action: PayloadAction<IFeed>) => {
      state.tasks = {
        ...state.tasks,
        liveTaskChanges: {
          ...state.tasks.liveTaskChanges,
          [action.payload.id]: action.payload,
        }
      };
    },
    setTaskFilters: (state: IState, action: PayloadAction<ITaskFilters>) => {
      state.tasks = {
        ...state.tasks,
        filters: action.payload,
      }
    },
    setInfinityScroll: (state: IState, action: PayloadAction<IScroll>) => {
      state.tasks = {
        ...state.tasks,
        infinityScroll: action.payload,
      }
    },
    setSceneStatus: (state: IState, action: PayloadAction<ISceneStatus>) => {
      state.tasks = {
        ...state.tasks,
        sceneStatus: action.payload,
      };
    },
    setUnlockSceneValidation: (state: IState, action: PayloadAction<boolean>) => {
      state.tasks = {
        ...state.tasks,
        unlockSceneValidation: action.payload,
      };
    },
    getAvailableProducts: (state: IState, action: PayloadAction<IAvailableProducts[]>) => {
      state.tasks = {
        ...state.tasks,
        availableProducts: action.payload,
      };
    },
    collectConfirmedProduct: (state: IState, action: PayloadAction<IConfirmedProducts>) => {
      state.tasks.confirmedProducts = {
        ...state.tasks.confirmedProducts,
        ...action.payload,
      };
    },
    removeConfirmedProduct: (state: IState, action: PayloadAction<IConfirmedProducts>) => {
      state.tasks.confirmedProducts = {
        ...action.payload,
      };
    },
    collectCorrectedProduct: (state: IState, action: PayloadAction<ICorrectedProducts>) => {
      state.tasks.correctedProducts = {
        ...state.tasks.correctedProducts,
        ...action.payload,
      };
    },
    removeCorrectedProduct: (state: IState, action: PayloadAction<ICorrectedProducts>) => {
      state.tasks.correctedProducts = {
        ...action.payload,
      };
    },
    getStatuses: (state: IState, action: PayloadAction<IAuditStatuses[]>) => {
      state.auditStatusData = action.payload;
    },
    setLoading: (state: IState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: IState, action: PayloadAction<IError>) => {
      state.error = action.payload;
    },
  },
});
