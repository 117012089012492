import { eventsSlice } from "./eventsSlice";
import * as defaultActions from "../../../../../redux/_common";
// import { IEditItem } from "./_interfaces";

const { actions } = eventsSlice;
export const moduleUrl = "/api/assetmanagement/equipmentevent";

export const setValidationErrors = actions.setValidationErrors;

export const clearAll = () => defaultActions.clearAll(actions);
export const clearDocument = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);

export const getEvents = (params: {}) => defaultActions.getItems(moduleUrl, actions, params);