import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const SvgIcon = ({iconLocation="/media/svg/icons/Communication/Flag.svg", size="3", bootstrapColor="success", customClasses="", customStyles={}, title=" ", onClick=() => {}, data_testid=''}) => {
  return (
    <span data-testid={data_testid} style={{...customStyles}} className={`svg-icon svg-icon-size-${size} svg-icon-${bootstrapColor} ${customClasses} ${onClick ? "pointer" : ""}`} onClick={onClick}>
      <SVG src={toAbsoluteUrl(iconLocation)} title={title} />
    </span>
  );
};

export default SvgIcon;
