import { RootState } from "../../../../../redux/store";

export const isItemsLoading = (state:RootState) => state.articleFlavors.listLoading;
export const isItemActionLoading = (state:RootState) => state.articleFlavors.actionsLoading;
export const getItems = (state:RootState) => state.articleFlavors.entities;
export const getItemsSelect = (state:RootState) => state.articleFlavors.entitiesSelect;
export const getItemsCount = (state:RootState) => state.articleFlavors.totalCount;
export const getItemForEdit = (state:RootState) => state.articleFlavors.itemForEdit;
export const getError = (state:RootState) => state.articleFlavors.error;
export const validationErrors = (state: RootState) => state.articleFlavors.validationErrors;
