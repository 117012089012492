import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccuracy, IAuditedScenes, IDailyIncome, ILowAccuracy, IOnboarding, IOrderedArticles, IRecentActivities, IState, ITaskCategory, ITaskProgressSection, Period } from "../_interfaces/reduxState";
import { PERIODS } from "../pages/constants";

const initialState: IState = {
  taskCategories: [],
  tasksInCategory: {
    list: [],
    isLoading: false,
    count: 0
  },

  selectedPeriod: PERIODS.today,

  taskProgressReport: null,
  taskProgressReportLoading: false,

  dailyIncome:null,
  dailyIncomeReportLoading: false,

  orderedArticles: null,
  orderedArticlesLoading: false,

  accuracyReport: null,
  accuracyReportLoading: false,
  
  auditedScenes: null,
  auditedScenesLoading: false,
  
  onboardedProducts: null,
  onboardingProductsLoading: false,
  
  recentActivities: null,
  recentActivitiesLoading: false,

  lowAccuracy: [],
  lowAccuracyLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTaskCategories: (state: IState, action: PayloadAction<ITaskCategory[]>) => {
      state.taskCategories = action.payload;
    },
    setSelectedPeriod: (state:IState, action: PayloadAction<Period>) => {
      state.selectedPeriod = action.payload;
    },
    setTaskProgressReport: (state:IState, action: PayloadAction<ITaskProgressSection>) => {
      state.taskProgressReport = action.payload;
    },
    setTaskProgressReportLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.taskProgressReportLoading = action.payload;
    },
    setDailyIncomeReport: (state:IState, action: PayloadAction<IDailyIncome>) => {
      state.dailyIncome = action.payload;
    },
    setDailyIncomeReportLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.dailyIncomeReportLoading = action.payload;
    },
    setOrderedArticlesReport: (state:IState, action: PayloadAction<IOrderedArticles>) => {
      state.orderedArticles = action.payload;
    },
    setOrderedArticlesReportLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.orderedArticlesLoading = action.payload;
    },
    setAccuracyReport: (state:IState, action: PayloadAction<IAccuracy>) => {
      state.accuracyReport = action.payload;
    },
    setAccuracyReportLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.accuracyReportLoading = action.payload;
    },
    setAuditedScenesReport: (state:IState, action: PayloadAction<IAuditedScenes>) => {
      state.auditedScenes = action.payload;
    },
    setAuditedScenesLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.auditedScenesLoading = action.payload;
    },
    setOnboardingProducts: (state:IState, action: PayloadAction<IOnboarding>) => {
      state.onboardedProducts = action.payload;
    },
    setOnboardingProductsLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.onboardingProductsLoading = action.payload;
    },
    setRecentActivities: (state:IState, action: PayloadAction<IRecentActivities>) => {
      state.recentActivities = action.payload;
    },
    setRecentActivitiesLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.recentActivitiesLoading = action.payload;
    },
    setLowAccuracy: (state:IState, action: PayloadAction<ILowAccuracy[]>) => {
      state.lowAccuracy = action.payload;
    },
    setLowAccuracyLoading: (state:IState, action: PayloadAction<boolean>) => {
      state.lowAccuracyLoading = action.payload;
    },
    setTaskInCategoryLoading: (state: IState, action: PayloadAction<boolean>) => {
      state.tasksInCategory.isLoading = action.payload
    },
    setTaskInCategory: (state: IState, action: PayloadAction<{items: any[], totalCount: number}>) => {
      state.tasksInCategory.isLoading = false
      state.tasksInCategory.list = action.payload.items
      state.tasksInCategory.count = action.payload.totalCount
    },
  },
});
