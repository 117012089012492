import * as constants from "./constants";

export const getTabMenu = state => state.userAccess[constants.TAB_NAV];

export const isMenuLoading = state => state.userAccess[constants.MENU_ITEMS_LOADING];
export const getMenu = state => state.userAccess[constants.MENU_ITEMS];
export const isOrganizationUnitsLoading = state => state.userAccess[constants.ORG_UNITS_LOADING];
export const getOrganizationUnits = state => state.userAccess[constants.ORG_UNITS];
export const getError = state => state.userAccess.error;
export const orgUnits = state => state.userAccess.organizationUnits;
export const userAccess = state => state.userAccess.access;
