import { RootState } from "../../../../../redux/store";

export const articlesLoading = (state: RootState) => state.articles.itemsLoading;
export const articlesActionLoading = (state: RootState) => state.articles.actionsLoading;
export const dropDownLoading = (state: RootState) => state.articles.dropDownLoading;

export const articles = (state: RootState) => state.articles.items;
export const articlesCount = (state: RootState) => state.articles.itemsCount;
export const articleForEdit = (state: RootState) => state.articles.itemForEdit;
export const getItemsSelect = (state: RootState) => state.articles.dropDownItems;

export const error = (state: RootState) => state.articles.error;
export const errorAction = (state: RootState) => state.articles.errorAction;
export const dropDownError = (state: RootState) => state.articles.dropDownError;

export const getBaseArticlesSelect = (state: RootState) => state.articles.baseArticles;
export const getBaseArticlesLoading = (state: RootState) => state.articles.baseArticlesLoading;

export const validationErrors = (state: RootState) => state.articles.validationErrors;

export const baseArticlesFilters = (state: RootState) => state.articles.filters;
