/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export const DeleteColumnFormatter = (cellContent, row, rowIndex, { deleteEventHandler, keyField, disabled }) => {
  return (
    <OverlayTrigger placement="left" overlay={<Tooltip id="delete-tooltip">Delete item</Tooltip>}>
      <a
        className={`btn btn-icon btn-light btn-hover-danger btn-sm ${disabled ? "disabled" : ""}`}
        onClick={() => {
          if (!disabled) deleteEventHandler(row[keyField]);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
    </OverlayTrigger>
  );
};
